import React, { useRef, useEffect } from "react";
import Modal, { Styles } from "react-modal";
import "./DropdownMenuModal.scss";

interface DropdownMenuModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  data: any[];
  renderItem: (index: number, item: any) => JSX.Element;
  targetRef: React.RefObject<HTMLElement>; // 모달이 노출될 컴포넌트의 Ref
}

const DropdownMenuModal: React.FC<DropdownMenuModalProps> = ({
  isOpen,
  onRequestClose,
  data,
  renderItem,
  targetRef,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onRequestClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onRequestClose, targetRef]);

  const getModalPosition = () => {
    if (targetRef.current) {
      const rect = targetRef.current.getBoundingClientRect();
      const rootRect = document.getElementById("root")?.getBoundingClientRect();
      if (!rootRect) return { top: "0px", left: "0px" };
      const modalWidth = 198;

      return {
        width: `${modalWidth}px`,
        top: `${rect.top + rect.height}px`,
        left: `${Math.min(
          rect.left + rect.width / 2 - modalWidth / 2 - rootRect.left,
          rect.right - modalWidth - rootRect.left
        )}px`,
      };
    }
    return { top: "0px", left: "0px" };
  };

  const modalStyle = {
    content: {
      outline: "none",
      position: "absolute",
      ...getModalPosition(),
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal-content"
      overlayClassName="modal-overlay"
      style={modalStyle as Styles}
    >
      <div ref={modalRef} className="dropdown-container">
        {data.map((item: any, index: number) => (
          <div key={index} className="dropdown-item">
            {renderItem(index, item)}
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default DropdownMenuModal;
