import {
  CommentListItem,
  CommentMentionInfo,
  CommentTagUserInfo,
  CommentUserListItem,
} from "../api/comment/model";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import {
  getCommentList,
  createComment,
  updateComment,
  deleteComment,
  likeComment,
  cancelLikeComment,
  bestPickComment,
} from "../api/comment/api";
import { CommentStatusCode, CommentTypeCode } from "../constants/Comment";
import AuthStore from "./AuthStore";
import { findNickname } from "../api/member/api";
import {
  createTagInfoStr,
  extractMentionInfoFromContents,
  getTagUserInfo,
  replaceTagUserInfoToTagNickname,
} from "../utils/comment";
import { AllowTypeCode } from "../constants/CommonCode.enum";
import { CommonReport } from "./ReportStore";
import BadgeStore from "./BadgeStore";
import { BadgeCode } from "../constants/Badge.enum";
import { MISSION_TYPE } from "../constants/mission";
import {
  deleteMissionParticipant,
  getMisionCommentList,
  getMisionCommentCursorList,
} from "../api/mission/api";
import { MissionCommentListRequestResult } from "../api/mission/model";
import MissionStore from "./MissionStore";
import { bottomOffset } from "../utils/common";
import { t } from "i18next";
import { NewProduct } from "../api/product/model";
import { FeedWriteProduct } from "../api/post/model";
import { showBottomToast } from "../utils/Toast";
import { ProductForWrite } from "./ProductSearchAddStore";

export interface CommentInEdit {
  contents?: string;
  targetMemberUuid?: string;
  parentCommentId?: number;
  commentId?: number;
  originalMentionList?: CommentMentionInfo[];
}
export interface CommentTarget {
  authorId: string;
  commentTypeCode: CommentTypeCode;
  commentTargetId: number;
  postIndex?: number;
  listIndex?: number;
  orderByLike?: string;
}
class CommentStore {
  @observable _commentTarget: CommentTarget | null = null;

  @observable _commentCount = 0;
  @observable _commentList: CommentListItem[] | null = null;

  @observable _participantCount = 0;
  @observable _myCommentList: CommentListItem[] | null = null;
  @observable _pageSize = 10;
  @observable _pageIndex = 0;
  @observable _canLoadMore = true;

  @observable _authorNicknameList: CommentUserListItem[] | null = null;

  @observable _showReportPopup = false;
  @observable _showReportConfirmPopup = false;
  @observable _deleteTargetCommentInfo: CommentInEdit | null = null;

  @observable _afterCreateHandler: (() => void) | null = null;
  @observable _afterDeleteHandler: (() => void) | null = null;
  @observable _afterReportHandler: (() => void) | null = null;

  @observable _scrollHandler: ((index: number) => void) | null = null;
  @observable _commentInEdit: CommentInEdit | null = null;

  @observable _foundNickname: CommentUserListItem[] = [];
  @observable _tagUserInfo: CommentTagUserInfo[] = [];
  @observable _textInputIndex = 0;
  @observable _searchTextLength = 0;
  @observable _afterUpdateHander: (() => void) | null = null;

  @observable _loading = false;

  @observable tempProductList: ProductForWrite[] = [];

  constructor() {
    makeObservable(this);
  }

  @computed get commentCount() {
    return this._commentCount;
  }

  @computed get commentList() {
    return this._commentList;
  }

  @computed get pageSize() {
    return this._pageSize;
  }

  @computed get pageIndex() {
    return this._pageIndex;
  }

  @computed get canLoadMore() {
    return this._canLoadMore;
  }

  @computed get participantCount() {
    return this._participantCount;
  }

  @computed get myCommentList() {
    return this._myCommentList;
  }

  @computed get commentTarget() {
    return this._commentTarget;
  }

  @computed get authorNicknameList() {
    return this._authorNicknameList;
  }
  @computed get commentInEdit() {
    return this._commentInEdit;
  }

  @computed get deleteTargetCommentInfo() {
    return this._deleteTargetCommentInfo;
  }

  @computed get showReportPopup() {
    return this._showReportPopup;
  }

  @computed get showReportConfirmPopup() {
    return this._showReportConfirmPopup;
  }
  @computed get foundNickname() {
    return this._foundNickname;
  }
  @computed get tagUserInfo() {
    return this._tagUserInfo;
  }
  @computed get textInputIndex() {
    return this._textInputIndex;
  }
  @computed get searchTextLength() {
    return this._searchTextLength;
  }

  @computed get loading() {
    return this._loading;
  }

  @action setCommentTargetAndRetrieveCommentList = (target: CommentTarget) => {
    this._commentTarget = target;
    void this.retrieveCommentList();
  };

  @action setCommentTargetMissionAndRetrieveCommentList = (
    target: CommentTarget,
    pageSize: number,
    pageIndex: number
  ) => {
    this._commentTarget = target;
    this.setPageSize(pageSize);
    this.setPageIndex(pageIndex);
    void this.retrieveMissionCommentList();
  };

  @action setCommentTargetMissionAndRetrieveCommentCursorList = (
    target: CommentTarget,
    pageSize: number
  ) => {
    this._commentTarget = target;
    this.setPageSize(pageSize);
    void this.retrieveMissionCommentCursorList();
  };

  @action setCommentList = (list: CommentListItem[] | null) => {
    this._commentList = list;
  };

  @action setPageSize = (pageSize: number) => {
    this._pageSize = pageSize;
  };
  @action setPageIndex = (pageIndex: number) => {
    this._pageIndex = pageIndex;
  };

  @action setMyCommentList = (list: CommentListItem[] | null) => {
    this._myCommentList = list;
  };

  @action setParticipantCount = (count: number) => {
    this._participantCount = count;
  };

  @action setCommentCount = (count: number) => {
    this._commentCount = count;
  };

  @action setAuthorNicknameList = (list: CommentUserListItem[] | null) => {
    this._authorNicknameList = list;
  };

  @action setShowReportPopup = (show: boolean) => {
    this._showReportPopup = show;
  };

  @action setShowReportConfirmPopup = (show: boolean) => {
    this._showReportConfirmPopup = show;
  };

  @action setDeleteTargetCommentInfo = (comment: CommentInEdit | null) => {
    this._deleteTargetCommentInfo = comment;
  };

  @action setAfterCreateHandler = (handler: (() => void) | null) => {
    this._afterCreateHandler = handler;
  };

  @action setAfterDeleteHandler = (handler: (() => void) | null) => {
    this._afterDeleteHandler = handler;
  };

  @action setScrollHandler = (handler: ((index: number) => void) | null) => {
    this._scrollHandler = handler;
  };

  @action setCommentInEdit = (comment: CommentInEdit) => {
    this._commentInEdit = comment;
  };

  @action setFoundNickname = (nicknameList: CommentUserListItem[]) => {
    this._foundNickname = nicknameList;
  };

  @action setAfterUpdateHandler = (handler: (() => void) | null) => {
    this._afterUpdateHander = handler;
  };

  @action setLoading = (isLoading: boolean) => {
    this._loading = isLoading;
  };

  @action setCanLoadMore = (canLoadMore: boolean) => {
    this._canLoadMore = canLoadMore;
  };

  @action clearTagUserInfo = () => {
    this._tagUserInfo = [];
  };

  @action clearFoundNickname = () => {
    this._foundNickname = [];
  };

  @action deleteTagUserInfo = (nickname: string) => {
    this._tagUserInfo = this._tagUserInfo.filter(
      (userInfo) => userInfo.nickname !== nickname
    );
  };

  @action setTagUserInfo = (
    nickname: string,
    userInfo: string,
    isUpdate = false
  ) => {
    if (!this.tagUserInfo.some((userInfo) => userInfo.nickname === nickname)) {
      this.tagUserInfo.push({
        nickname: nickname,
        userInfo: userInfo,
      });
      this.tagUserInfo.sort((a: CommentTagUserInfo, b: CommentTagUserInfo) => {
        return (b.nickname || "").length - (a.nickname || "").length;
      });
    }
  };

  @action setCommentUpdateType = (comment: CommentInEdit) => {
    if (comment.contents) {
      const userInfos = getTagUserInfo(comment.contents);
      for (let idx = 0; idx < userInfos.length - 1; idx++) {
        const nickname = userInfos[++idx];
        const uuid = userInfos[++idx];

        const userInfo = createTagInfoStr(nickname, uuid);
        this.setTagUserInfo(nickname, userInfo, true);
      }
    }
  };

  @action setTextInputIndex = (index: number) => {
    this._textInputIndex = index;
  };
  @action setSearchTextLength = (index: number) => {
    this._searchTextLength = index;
  };

  @action retrieveMyCommentList = async (): Promise<
    MissionCommentListRequestResult | undefined
  > => {
    if (
      this.commentTarget &&
      this.commentTarget.commentTypeCode === CommentTypeCode.MISSION
    ) {
      return await getMisionCommentList(
        this.commentTarget.commentTargetId,
        1,
        0
      );
    }
  };

  @action retrieveMyCommentCursorList = async (): Promise<
    MissionCommentListRequestResult | undefined
  > => {
    if (
      this.commentTarget &&
      this.commentTarget.commentTypeCode === CommentTypeCode.MISSION
    ) {
      return await getMisionCommentCursorList(
        this.commentTarget.commentTargetId,
        1,
        0
      );
    }
  };

  @action retrieveCommentList = async () => {
    this.setLoading(true);
    if (this.commentTarget) {
      const res = await getCommentList(
        this.commentTarget.commentTypeCode,
        this.commentTarget.commentTargetId,
        this.commentTarget.orderByLike
      );
      this.setCommentList(res?.commentList || []);
      this.setAuthorNicknameList(res?.authorNicknameList || []);
      this.setCommentCount(res ? res.commentCount : 0);
    }
    this.setLoading(false);
  };

  @action retrieveMissionCommentList = async () => {
    this.setLoading(true);
    if (
      this.commentTarget &&
      this.commentTarget.commentTypeCode === CommentTypeCode.MISSION
    ) {
      const res: MissionCommentListRequestResult = await getMisionCommentList(
        this.commentTarget.commentTargetId,
        this.pageSize,
        this.pageIndex
      );
      if (res) {
        if (MissionStore.missionDetail.participationContentDisplayYn === "Y") {
          this.setCommentList(res.commentList || []);
        } else {
          this.setCommentList([]);
          this.setCanLoadMore(false);
        }
        this.setMyCommentList(res.myCommentList || []);
        this.setParticipantCount(
          res.totalParticipantCount ? res.totalParticipantCount : 0
        );
        (res.totalParticipantCount === 0 || res.commentList?.length === 0) &&
          this.setCanLoadMore(false);
      }
    }
    this.setLoading(false);
  };

  @action retrieveMissionCommentCursorList = async () => {
    this.setLoading(true);
    if (
      this.commentTarget &&
      this.commentTarget.commentTypeCode === CommentTypeCode.MISSION
    ) {
      const res: MissionCommentListRequestResult =
        await getMisionCommentCursorList(
          this.commentTarget.commentTargetId,
          this.pageSize
        );
      if (res) {
        if (MissionStore.missionDetail.participationContentDisplayYn === "Y") {
          this.setCommentList(res.commentList || []);
        } else {
          this.setCommentList([]);
          this.setCanLoadMore(false);
        }
        this.setMyCommentList(res.myCommentList || []);
        this.setParticipantCount(
          res.totalParticipantCount ? res.totalParticipantCount : 0
        );
        (res.totalParticipantCount === 0 || res.commentList?.length === 0) &&
          this.setCanLoadMore(false);
      }
    }
    this.setLoading(false);
  };

  @action retrieveMoreMissionComments = async () => {
    if (!this.loading) {
      this.setLoading(true);
      if (
        this.commentTarget &&
        this.commentTarget.commentTypeCode === CommentTypeCode.MISSION
      ) {
        const res = await getMisionCommentList(
          this.commentTarget.commentTargetId,
          this.pageSize,
          this.pageIndex + 1
        );
        const newComments: CommentListItem[] = res?.commentList
          ? res.commentList
          : [];
        const commentList: CommentListItem[] = this._commentList
          ? this._commentList
          : [];

        if (newComments.length > 0) {
          this.setCommentList([...commentList, ...newComments]);
          this._pageIndex = this.pageIndex + 1;
        } else {
          this.setCanLoadMore(false);
        }
      }
      this.setLoading(false);
    }
  };

  //
  @action retrieveMoreMissionCommentsCursor = async () => {
    if (!this.loading) {
      this.setLoading(true);
      if (
        this.commentTarget &&
        this.commentTarget.commentTypeCode === CommentTypeCode.MISSION
      ) {
        const cursor =
          this._commentList?.length && this._commentList?.length > 0
            ? this._commentList[this._commentList?.length - 1].commentId
            : undefined;
        const res = await getMisionCommentCursorList(
          this.commentTarget.commentTargetId,
          this.pageSize,
          cursor
        );
        const newComments: CommentListItem[] = res?.commentList
          ? res.commentList
          : [];
        const commentList: CommentListItem[] = this._commentList
          ? this._commentList
          : [];

        if (newComments.length > 0) {
          this.setCommentList([...commentList, ...newComments]);
          this._pageIndex = this.pageIndex + 1;
        } else {
          this.setCanLoadMore(false);
        }
      }
      this.setLoading(false);
    }
  };

  @action deleteComment = async (comment: CommentInEdit) => {
    if (comment.commentId) {
      const res = await deleteComment(comment.commentId);
      if (res) {
        this.setDeleteTargetCommentInfo(null);

        this._afterDeleteHandler && this._afterDeleteHandler();
        this.setAfterDeleteHandler(null);

        this._afterUpdateHander && this._afterUpdateHander();
        this.setAfterUpdateHandler(null);

        if (this.commentTarget && this._commentList) {
          if (comment.parentCommentId) {
            const parentCommentIndex = this._commentList?.findIndex(
              (item) => item.commentId === comment.parentCommentId
            );
            const commentIndex = this._commentList[
              parentCommentIndex
            ].childCommentMemberList.findIndex(
              (item) => item.commentId === comment.commentId
            );
            if (
              parentCommentIndex !== undefined &&
              commentIndex !== undefined &&
              parentCommentIndex !== -1 &&
              commentIndex !== -1
            ) {
              this._commentList[
                parentCommentIndex
              ].childCommentMemberList.splice(commentIndex, 1);
              this._commentList = [...this._commentList];
              if (
                this._commentList[parentCommentIndex].childCommentMemberList &&
                this._commentList[parentCommentIndex].childCommentMemberList
                  .length === 0 &&
                this._commentList[parentCommentIndex].commentStatusCode ===
                  CommentStatusCode.USER_DELETE
              ) {
                this._commentList.splice(parentCommentIndex, 1);
              }
              this._commentCount -= 1;
            }
          } else {
            const commentIndex = this._commentList?.findIndex(
              (item) => item.commentId === comment.commentId
            );
            if (commentIndex !== undefined && commentIndex !== -1) {
              if (
                this._commentList[commentIndex].childCommentMemberList &&
                this._commentList[commentIndex].childCommentMemberList
                  .length === 0
              ) {
                this._commentList.splice(commentIndex, 1);
              } else {
                this._commentList[commentIndex].commentStatusCode =
                  CommentStatusCode.USER_DELETE;
                this._commentList = [...this._commentList];
              }

              this._commentCount -= 1;
            }
          }
        }

        if (this.commentTarget && this.myCommentList) {
          const commentIndex = this.myCommentList?.findIndex(
            (item) => item.commentId === comment.commentId
          );
          if (commentIndex !== undefined && commentIndex !== -1) {
            const missionParticipateRequest = {
              missionTypeCode: MISSION_TYPE.COMMENT,
              missionTargetId: comment.commentId,
            };
            await deleteMissionParticipant(missionParticipateRequest);
            const temp = [...this.myCommentList];
            temp.splice(commentIndex, 1);
            this.setCommentList(temp);
            if (this.myCommentList.length === 0) {
              this.setParticipantCount(this.participantCount - 1);
            }
          }
        }
      }
    }
  };

  @action syncCommentStatusAfterReporting = (comment: CommonReport) => {
    if (this.commentTarget && this._commentList) {
      if (comment.parentCommentId) {
        const parentCommentIndex = this._commentList?.findIndex(
          (item) => item.commentId === comment.parentCommentId
        );
        const commentIndex = this._commentList[
          parentCommentIndex
        ].childCommentMemberList.findIndex(
          (item) => String(item.commentId) === comment.reportTargetId
        );
        if (
          parentCommentIndex !== undefined &&
          commentIndex !== undefined &&
          parentCommentIndex !== -1 &&
          commentIndex !== -1
        ) {
          const prevComment =
            this._commentList[parentCommentIndex].childCommentMemberList[
              commentIndex
            ];
          this._commentList[parentCommentIndex].childCommentMemberList[
            commentIndex
          ] = {
            ...prevComment,
            memberReportYn: AllowTypeCode.Y,
          };
        }
      } else {
        const commentIndex = this._commentList?.findIndex(
          (item) => String(item.commentId) === comment.reportTargetId
        );

        if (
          commentIndex !== undefined &&
          commentIndex !== -1 &&
          this._commentList[commentIndex].childCommentMemberList.length === 0
        ) {
          const prevComment = this._commentList[commentIndex];
          this._commentList[commentIndex] = {
            ...prevComment,
            memberReportYn: AllowTypeCode.Y,
          };
        }
      }
    }
  };

  getProducts = (): FeedWriteProduct[] => {
    const products: FeedWriteProduct[] = [];
    this.tempProductList.forEach((item) => {
      item.product.openApiFlag && item.product.nproductId === undefined
        ? item.product.myProductId && item.product.myProductId !== null
          ? products?.push({
              id: item.product.myProductId,
              thumbnailYn: item.isRepresent ? "Y" : "N",
              tempProductYn:
                item.product?.openApiFlag || item.product?.tempProductId
                  ? "Y"
                  : "N",
              product_from: item.product.from ?? "",
            })
          : products?.push({
              id: -1,
              openApiFlag: true,
              productId: item.product?.productId,
              productName: item.product?.productName,
              brandNameKor: item.product?.brandName,
              productModelName: item.product?.productModelName,
              lprice: item.product?.lprice,
              hprice: item.product?.hprice,
              thumbnailFilePath: item.product?.thumbnailFilePath,
              category1: item.product?.category1,
              category2: item.product?.category2,
              category3: item.product?.category3,
              productUrl: item.product?.productUrl,
            })
        : products?.push({
            id: item.product?.productId || item.product?.tempProductId || -1,
            thumbnailYn: item.isRepresent ? "Y" : "N",
            tempProductYn:
              item.product?.openApiFlag || item.product?.tempProductId
                ? "Y"
                : "N",
            product_from: item.product.from ?? "",
          });
    });
    return products;
  };

  @action createComment = async () => {
    const comment: CommentInEdit | null = this.commentInEdit;
    if (comment === null) {
      return;
    }

    const { contents, mentionList } = extractMentionInfoFromContents(
      [],
      this.tagUserInfo,
      comment.contents || ""
    );
    const pushContents = replaceTagUserInfoToTagNickname(
      comment.contents || ""
    );
    const products = this.getProducts();

    const res = await createComment({
      commentTypeCode: this.commentTarget?.commentTypeCode.toString() || "",
      commentTargetId: this.commentTarget?.commentTargetId || -1,
      parentCommentId: comment?.parentCommentId,
      contents: contents,
      pushContents: pushContents,
      mentionList: mentionList,
      products: products,
    });

    const sessionUser = AuthStore.sessionUser;
    if (
      this.commentTarget?.commentTypeCode.toString() === CommentTypeCode.QNA
    ) {
      void BadgeStore.obtainBadge(BadgeCode.REPLY);
      if (res && res.voltAmount && res.voltAmount > 0) {
        showBottomToast(
          t("screen.comment.message.qnaVoltSuccess", { volt: res.voltAmount }),
          bottomOffset()
        );
      }
    }
    runInAction(() => {
      if (res && this.commentTarget && this._commentList && sessionUser) {
        const newComment = {
          childCommentBestPickCount: 0,
          childCommentMemberList: [],
          commentId: res.commentId,
          commentTypeCode: this.commentTarget.commentTypeCode,
          commentTargetId: this.commentTarget.commentTargetId,
          parentCommentId: res.parentCommentId,
          memberUuid: sessionUser.memberUuid || "",
          nickname: sessionUser.nickname || "",
          profileUrl: sessionUser.profileUrl || "",
          useYn: "Y",
          contents: res.contents,
          createdDatetime: new Date().toString(),
          updatedDatetime: new Date().toString(),
          likeCount: 0,
          replyCount: 0,
          bestPickYn: "N",
          commentStatusCode: CommentStatusCode.REGISTERED,
          likeYn: "N",
          mentionList: res.mentionList,
          productList: res.productList,
        };
        if (comment.parentCommentId) {
          const parentCommentIndex = this._commentList?.findIndex(
            (item) => item.commentId === comment.parentCommentId
          );
          if (
            parentCommentIndex !== undefined &&
            parentCommentIndex !== -1 &&
            this.commentList
          ) {
            this._commentList[parentCommentIndex].childCommentMemberList = [
              ...this.commentList[parentCommentIndex].childCommentMemberList,
              newComment,
            ];
          }
        } else {
          this._scrollHandler && this._scrollHandler(0);
          if (
            this._commentList.length > 0 &&
            this._commentList[0].bestPickYn === "Y"
          ) {
            this._commentList = [
              this._commentList[0],
              newComment,
              ...this._commentList.slice(1),
            ];
          } else {
            this._commentList = [newComment, ...this._commentList];
          }
        }
        this._commentCount += 1;

        this.setCommentInEdit({
          contents: "",
        });
        this.tempProductList = [];

        this._afterCreateHandler && this._afterCreateHandler();
        this.setAfterCreateHandler(null);
        this._afterUpdateHander && this._afterUpdateHander();
        this.setAfterUpdateHandler(null);

        return res.commentId;
      }
    });
  };

  @action createMissionComment = async () => {
    const comment: CommentInEdit | null = this.commentInEdit;
    if (comment === null) {
      return;
    }

    const { contents, mentionList } = extractMentionInfoFromContents(
      [],
      this.tagUserInfo,
      comment.contents || ""
    );
    const pushContents = replaceTagUserInfoToTagNickname(
      comment.contents || ""
    );

    const res = await createComment({
      commentTypeCode: this.commentTarget?.commentTypeCode.toString() || "",
      commentTargetId: this.commentTarget?.commentTargetId || -1,
      parentCommentId: comment?.parentCommentId,
      contents: contents,
      pushContents: pushContents,
      mentionList: mentionList,
    });

    const sessionUser = AuthStore.sessionUser;

    if (res && this.commentTarget && this._myCommentList && sessionUser) {
      const newComment = {
        childCommentBestPickCount: 0,
        childCommentMemberList: [],
        commentId: res.commentId,
        commentTypeCode: this.commentTarget.commentTypeCode,
        commentTargetId: this.commentTarget.commentTargetId,
        parentCommentId: res.parentCommentId,
        memberUuid: sessionUser.memberUuid || "",
        nickname: sessionUser.nickname || "",
        profileUrl: sessionUser.profileUrl || "",
        useYn: "Y",
        contents: res.contents,
        createdDatetime: new Date().toString(),
        updatedDatetime: new Date().toString(),
        likeCount: 0,
        replyCount: 0,
        bestPickYn: "N",
        commentStatusCode: CommentStatusCode.REGISTERED,
        likeYn: "N",
      };

      this._scrollHandler && this._scrollHandler(0);

      this.setCommentInEdit({
        contents: "",
      });

      this._afterCreateHandler && this._afterCreateHandler();
      this.setAfterCreateHandler(null);
      this._afterUpdateHander && this._afterUpdateHander();
      this.setAfterUpdateHandler(null);

      return res.commentId;
    }
  };

  @action updateComment = async () => {
    const comment: CommentInEdit | null = this.commentInEdit;
    if (comment === null) {
      return;
    }

    const { contents, mentionList } = extractMentionInfoFromContents(
      comment.originalMentionList || [],
      this.tagUserInfo,
      comment.contents || ""
    );
    const pushContents = replaceTagUserInfoToTagNickname(
      comment.contents || ""
    );
    const products = this.getProducts();

    const res = await updateComment({
      commentTypeCode: this.commentTarget?.commentTypeCode.toString() || "",
      commentTargetId: this.commentTarget?.commentTargetId || -1,
      parentCommentId: comment.parentCommentId,
      contents: contents,
      pushContents: pushContents,
      commentId: comment.commentId,
      mentionList: mentionList,
      products: products,
    });

    runInAction(() => {
      if (res && this.commentTarget && this._commentList) {
        if (comment.parentCommentId) {
          const parentCommentIndex = this._commentList?.findIndex(
            (item) => item.commentId === comment.parentCommentId
          );
          const commentIndex = this._commentList[
            parentCommentIndex
          ].childCommentMemberList.findIndex(
            (item) => item.commentId === comment.commentId
          );
          if (
            parentCommentIndex !== undefined &&
            commentIndex !== undefined &&
            parentCommentIndex !== -1 &&
            commentIndex !== -1
          ) {
            const prevComment =
              this._commentList[parentCommentIndex].childCommentMemberList[
                commentIndex
              ];
            this._commentList[parentCommentIndex].childCommentMemberList[
              commentIndex
            ] = {
              ...prevComment,
              mentionList: res.mentionList,
              contents: res.contents,
              productList: res.productList,
            };
          }
        } else {
          const commentIndex = this._commentList?.findIndex(
            (item) => item.commentId === comment.commentId
          );
          if (commentIndex !== undefined && commentIndex !== -1) {
            const prevComment = this._commentList[commentIndex];
            this._commentList[commentIndex] = {
              ...prevComment,
              mentionList: res.mentionList,
              contents: res.contents,
              productList: res.productList,
            };
          }
        }
        this.setCommentInEdit({
          contents: "",
        });
        this.tempProductList = [];

        this._afterUpdateHander && this._afterUpdateHander();
        this.setAfterUpdateHandler(null);
      }
    });
  };

  @action likeComment = async (commentId: number) => {
    return await likeComment(commentId);
  };

  @action setChildLike = (
    parentCommentId: number | undefined,
    commentId: number,
    like: boolean
  ) => {
    if (this._commentList && parentCommentId) {
      const parentCommentIndex = this._commentList.findIndex(
        (item) => item.commentId === parentCommentId
      );
      const commentIndex = this._commentList[
        parentCommentIndex
      ].childCommentMemberList.findIndex(
        (item) => item.commentId === commentId
      );
      if (
        parentCommentIndex !== undefined &&
        commentIndex !== undefined &&
        parentCommentIndex !== -1 &&
        commentIndex !== -1
      ) {
        const prevComment =
          this._commentList[parentCommentIndex].childCommentMemberList[
            commentIndex
          ];
        this._commentList[parentCommentIndex].childCommentMemberList[
          commentIndex
        ] = {
          ...prevComment,
          likeYn: like ? "Y" : "N",
          likeCount: like
            ? prevComment.likeCount + 1
            : prevComment.likeCount - 1,
        };
      }
    }
  };

  @action cancelLikeComment = async (commentId: number) => {
    return await cancelLikeComment(commentId);
  };

  @action setBestPick = async (commentId: number) => {
    if (this.commentTarget) {
      const res = await bestPickComment(
        commentId,
        this.commentTarget.commentTypeCode,
        this.commentTarget.commentTargetId
      );
      res && this.retrieveCommentList().catch((e) => undefined);
    }
  };

  @action addAuthorNicknameList = (user: CommentUserListItem) => {
    if (this._authorNicknameList) {
      if (
        this._authorNicknameList.filter((author) => {
          return author.nickname === user.nickname;
        }).length === 0
      ) {
        this._authorNicknameList.push(user);
      }
      this._authorNicknameList.sort(
        (a: CommentUserListItem, b: CommentUserListItem) => {
          return (b.nickname || "").length - (a.nickname || "").length;
        }
      );
    } else {
      this._authorNicknameList = [];
      this._authorNicknameList = this._authorNicknameList.concat(user);
    }
  };

  @action findNickname = async (nickname: string, isLastUpdate = false) => {
    const response = await findNickname(nickname);
    if (response && !isLastUpdate) {
      this.setFoundNickname(response);
    }
    return response;
  };

  @action addProduct = (item: NewProduct) => {
    const duplication = this.tempProductList.findIndex(
      (info) =>
        item.productId ===
        (info.product.productId || info.product.tempProductId)
    );
    if (duplication < 0) {
      this.tempProductList.push({ product: item, isRepresent: false });
    } else {
      showBottomToast(
        t("screen.feedProduct.message.alreadyAdd"),
        bottomOffset()
      );
    }
  };

  @action deleteProduct = (index: number) => {
    this.tempProductList.splice(index, 1);
  };

  @action clear = () => {
    this._commentTarget = null;
    this._commentCount = 0;
    this._commentList = null;
    this._authorNicknameList = null;
    this._showReportPopup = false;
    this._showReportConfirmPopup = false;
    this._deleteTargetCommentInfo = null;
    this._afterCreateHandler = null;
    this._afterDeleteHandler = null;
    this._scrollHandler = null;
    this._commentInEdit = null;
    this._foundNickname = [];
    this._tagUserInfo = [];
    this._textInputIndex = 0;
    this._searchTextLength = 0;
    this._afterUpdateHander = null;

    this._myCommentList = null;
    this._pageSize = 10;
    this._pageIndex = 0;
    this._canLoadMore = true;
    this.tempProductList = [];
  };
}

export default new CommentStore();
