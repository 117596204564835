import React, {
  CSSProperties,
  ComponentType,
  JSXElementConstructor,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Styles } from "../../assets/types/Style";
import useDebounce from "../../hooks/UseDebounce";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import clsx from "clsx";

interface CustomCarouselProps<T>
  extends Exclude<
    any,
    | "horizontal"
    | "snapToInterval"
    | "snapToAlignment"
    | "decelerationRate"
    | "onViewableItemsChanged"
    | "viewabilityConfig"
    | "showsHorizontalScrollIndicator"
    | "bounces"
    | "ref"
    | "windowSize"
    | "initialNumToRender"
    | "viewabilityConfig"
    | "disableIntervalMomentum"
  > {
  onSnapToItem?: (index: number) => void;
  showDotIndicator?: boolean;
  dotStyle?: Styles;
  inactiveDotStyle?: Styles;
  dotContainerStyle?: CSSProperties;
  showTextIndicator?: boolean;
  textIndexStyle?: Styles;
  textIndexContainerStyle?: Styles;
  showThumbnailCarousel?: boolean;
  renderThumbnailCarouselItem?: any;
  ThumbnailFooterComponent?:
    | ReactElement<any, string | JSXElementConstructor<any>>
    | ComponentType<any>
    | null
    | undefined;
  thumbnailCarouselWrapperStyle?: Styles;
  thumbnailSelectedStyle?: Styles;
  itemWidth?: number;
  thumbnailLeftOffsetMargin?: number;
  visible?: boolean;
  height?: number;
  handleBannerPress?: any;
  otherSettings?: Settings;
}

// const screenWidth = Dimensions.get("screen").width;
const screenWidth = 100;

const CustomCarousel = ({
  onSnapToItem,
  showDotIndicator,
  dotStyle,
  inactiveDotStyle,
  dotContainerStyle,
  showTextIndicator,
  textIndexStyle,
  textIndexContainerStyle,
  showThumbnailCarousel,
  renderThumbnailCarouselItem,
  ThumbnailFooterComponent,
  thumbnailCarouselWrapperStyle,
  thumbnailSelectedStyle,
  itemWidth,
  thumbnailLeftOffsetMargin,
  visible,
  height,
  handleBannerPress,
  otherSettings,
  ...props
}: CustomCarouselProps<any>) => {
  const [index, setIndex] = useState<number>(0);

  const thumbnailIndex = useDebounce(index, (index) => index, 50);
  const snapToInterval = itemWidth || 0 || screenWidth;
  const originalRef = useRef(document.createElement("div"));
  const thumbnailRef = useRef(document.createElement("div"));

  const { renderItem } = props;

  /* eslint-disable */
  const handleViewableItemsChanged = useCallback(
    (info: { viewableItems: any[]; changed: any[] }) => {
      if (info.viewableItems && info.viewableItems.length > 0) {
        const index = info.viewableItems[0].index || 0;
        setIndex(index);
        onSnapToItem && onSnapToItem(index);
      }
    },
    []
  );

  const handleThumbnailPress = useCallback((index: number) => {
    setIndex(index);
    // originalRef.current?.scrollToIndex({ index });
  }, []);

  /* eslint-enable */

  const viewabilityConfig = useMemo(() => {
    return {
      waitForInteraction: true,
      viewAreaCoveragePercentThreshold: 50,
    };
  }, []);

  const renderThumbnail: (
    activeIndex: number,
    item: any
  ) => ReactElement<any, string | JSXElementConstructor<any>> | null =
    useCallback(
      (activeIndex: number, item: any) => {
        if (renderThumbnailCarouselItem) {
          return (
            <div onClick={() => handleThumbnailPress(item.index)}>
              {renderThumbnailCarouselItem(item)}
              {activeIndex == item.index && (
                <div style={thumbnailSelectedStyle} />
              )}
            </div>
          );
        } else {
          return null;
        }
      },
      [
        renderThumbnailCarouselItem,
        thumbnailSelectedStyle,
        handleThumbnailPress,
      ]
    );

  const zerosWithDataSize = useMemo(() => {
    return new Array(props.data?.length || 0).fill(0);
  }, [props.data?.length]);

  const getOriginItemLayout = useCallback(
    (data: any, index: number) => {
      return {
        index,
        length: snapToInterval,
        offset: snapToInterval * index,
      };
    },
    [snapToInterval]
  );

  useEffect(() => {
    // thumbnailRef.current?.scrollToIndex({
    //   index: thumbnailIndex || 0,
    //   viewOffset: thumbnailLeftOffsetMargin || 0,
    // });
  }, [thumbnailIndex, thumbnailLeftOffsetMargin]);

  const handleAfterChange = (currentSlide: number) => {
    setIndex(currentSlide);
  };

  const settings = {
    // dots: true,
    infinite: false,
    afterChange: handleAfterChange,
    ...otherSettings,
  };

  return (
    <div id="CustomCarousel">
      {showTextIndicator && (props.data || []).length > 1 && (
        <div className="text_idx_container" style={textIndexContainerStyle}>
          <div className="text_idx" style={textIndexStyle}>{`${index + 1}/${
            props.data?.length || 0
          }`}</div>
        </div>
      )}

      {/* <FlatList
        {...props}
        ref={originalRef}
        // windowSize={2}
        initialNumToRender={3}
        // horizontal
        // showsHorizontalScrollIndicator={false}
        // snapToInterval={snapToInterval}
        snapToAlignment="start"
        // decelerationRate={Platform.OS == "ios" ? 0.1 : 0.8}
        decelerationRate={0.8}
        // bounces={false}
        // onViewableItemsChanged={handleViewableItemsChanged}
        // viewabilityConfig={viewabilityConfig}
        // renderItem={renderItem}
        // disableIntervalMomentum={true}
        // getItemLayout={getOriginItemLayout}
      >
        {props.children}
      </FlatList> */}

      <Slider {...settings}>
        {props.data &&
          props.data.map((item: any, index: number) => {
            return renderItem(item, index);
          })}
      </Slider>

      {showDotIndicator && (props.data || []).length > 1 && (
        <div className="dot_indicator" style={dotContainerStyle}>
          {zerosWithDataSize.map((_item, idx) => {
            const isFocused = idx === index;
            return (
              <div
                className={clsx("dot", isFocused && "dot_active")}
                key={`carousel-dot-${idx}`}
              />
            );
          })}
        </div>
      )}
      {/* {showThumbnailCarousel && renderThumbnail && (
        <FlatList
          ref={thumbnailRef}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={thumbnailCarouselWrapperStyle}
          data={props.data}
          renderItem={(item: any) => renderThumbnail(thumbnailIndex || 0, item)}
          windowSize={2}
          initialNumToRender={5}
          horizontal
          onEndReached={props.onEndReached}
          onEndReachedThreshold={props.onEndReachedThreshold}
          ListFooterComponent={ThumbnailFooterComponent}
          viewabilityConfig={viewabilityConfig}
        />
      )} */}
    </div>
  );
};

export default React.memo(CustomCarousel);
CustomCarousel.displayName = "CustomCarousel";
