import React from "react";
import { useTranslation } from "react-i18next";
import { Styles } from "../../../assets/types/Style";
import MissionStore from "../../../store/MissionStore";
import { getDateMMDDddd } from "../../../utils/datetime";
import { styles } from "../MissionDetailScreen";
import "./DetailInfoComponent.scss";

const DetailInfoComponent = () => {
  // const { width, height } = useWindowDimensions();
  const { missionDetail } = MissionStore;
  const { t } = useTranslation();

  // const renderEachInfo = (title: string, info: string | number, isTargetCount = false) => {
  //   return (
  //     <View style={styles.list_contents}>
  //       <View style={styles.dot_wrap}>
  //         <View style={styles.dot} />
  //       </View>
  //       <View style={styles.contents_txt_wrap}>
  //         <Text style={styles.contents}>{title}</Text>
  //         <View style={styles.txt_bar} />
  //         <Text style={styles.contents}>{info}</Text>
  //         {isTargetCount && <Text style={styles.contents}>{t("screen.mission.label.count")}</Text>}
  //       </View>
  //     </View>
  //   );
  // };

  const renderEachInfo = (
    title: string,
    info: string | number,
    isTargetCount = false
  ) => {
    return (
      <div style={styles.list_contents} className="list_contents">
        <div style={styles.dot_wrap} className="dot-wrap">
          <div style={styles.dot} className="dot" />
        </div>
        <div style={styles.contents_txt_wrap} className="contents-txt-wrap">
          <p style={styles.contents} className="contents">
            {title}
          </p>
          <div style={styles.txt_bar} className="txt-bar" />
          <p style={styles.contents} className="contents-wrap">
            {info}
          </p>
          {isTargetCount && (
            <p style={styles.contents} className="contents">
              {t("screen.mission.label.count")}
            </p>
          )}
        </div>
      </div>
    );
  };

  //   return (
  //     <View style={styles.contents_item}>
  //       <Text style={styles.label}>{t("screen.mission.label.detailInfo")}</Text>
  //       {missionDetail.targetMemberNumber &&
  //         renderEachInfo(t("screen.mission.label.targetCount"), missionDetail.targetMemberNumber, true)}
  //       {missionDetail.prizes && missionDetail.prizes?.length > 0 && missionDetail.prizes[0].prizeName !== "" && (
  //         <View style={styles.list_contents}>
  //           <View style={styles.dot_wrap}>
  //             <View style={styles.dot} />
  //           </View>
  //           <View style={styles.contents_txt_wrap_top}>
  //             <Text style={styles.contents}>{t("screen.mission.label.missionPrize")}</Text>
  //             <View style={[styles.txt_bar, styles.txt_bar_top]} />
  //             {missionDetail.missionTypeCode != "RAFFLE" && (
  //               <View>
  //                 {missionDetail.prizes.map((prize, index) => (
  //                   <Text key={index} style={styles.contents}>
  //                     {prize.prizeName}
  //                   </Text>
  //                 ))}
  //               </View>
  //             )}
  //             {missionDetail.missionTypeCode == "RAFFLE" && (
  //               <View>
  //                 <Text style={(styles.contents, { width: width / 1.5 })}>
  //                   {missionDetail.missionProduct?.productName}
  //                 </Text>

  //                 <TouchableOpacity
  //                   key={1}
  //                   style={styles.navigate_btn}
  //                   onPress={() => {
  //                     //callActHistoryByHistoryType(ActHistoryTypeCode.PRODUCT_READ, ActSectionCode.POST);
  //                     push("ProductDetailScreen", { productId: missionDetail.missionProduct?.id });
  //                   }}
  //                 >
  //                   <Text style={styles.modify_btn_text}>{t("screen.mission.label.prizeDetail")}</Text>
  //                 </TouchableOpacity>
  //               </View>
  //             )}
  //           </View>
  //         </View>
  //       )}
  //       {missionDetail.voltPolicyCode &&
  //         missionDetail.voltAmount &&
  //         (missionDetail.voltPolicyCode == VoltPolicyCode.MISSION_ACCUM ? (
  //           renderEachInfo(
  //             t("screen.mission.label.participationBenefit"),
  //             t("screen.mission.label.voltAccum", { voltAmount: Math.abs(missionDetail.voltAmount || 0) })
  //           )
  //         ) : missionDetail.voltPolicyCode == VoltPolicyCode.MISSION_USE ? (
  //           renderEachInfo(
  //             t("screen.mission.label.participationCondition"),
  //             t("screen.mission.label.voltUse", { voltAmount: Math.abs(missionDetail.voltAmount || 0) })
  //           )
  //         ) : (
  //           <></>
  //         ))}
  //       {missionDetail.missionStartDate &&
  //         missionDetail.missionEndDate &&
  //         renderEachInfo(
  //           t("screen.mission.label.missionDuration"),
  //           getDateMMDDddd(missionDetail.missionStartDate)
  //             .concat(" ~ ")
  //             .concat(getDateMMDDddd(missionDetail.missionEndDate))
  //         )}
  //       {missionDetail.lotteryDate &&
  //         renderEachInfo(t("screen.mission.label.missionResult"), getDateMMDDddd(missionDetail.lotteryDate))}
  //       {missionDetail.deliveryStartDate &&
  //         renderEachInfo(t("screen.mission.label.deliveryStart"), getDateMMDDddd(missionDetail.deliveryStartDate))}
  //     </View>
  //   );
  // };

  return (
    <div style={styles.contents_item} className="contents_item">
      <p style={styles.label} className="label">
        {t("screen.mission.label.detailInfo")}
      </p>
      {missionDetail.targetMemberNumber &&
        renderEachInfo(
          t("screen.mission.label.targetCount"),
          missionDetail.targetMemberNumber,
          true
        )}
      {missionDetail.prizes &&
        missionDetail.prizes?.length > 0 &&
        missionDetail.prizes[0].prizeName !== "" && (
          <div style={styles.list_contents} className="list_contents">
            <div style={styles.dot_wrap} className="dot-wrap">
              <div style={styles.dot} className="dot" />
            </div>
            <div
              style={styles.contents_txt_wrap_top}
              className="contents-txt-wrap"
            >
              <p style={styles.contents} className="contents">
                {t("screen.mission.label.missionPrize")}
              </p>
              <div style={styles.txt_bar} className="txt-bar" />
              {missionDetail.missionTypeCode != "RAFFLE" && (
                <div>
                  {missionDetail.prizes.map((prize, index) => (
                    <p key={index} style={styles.contents} className="contents">
                      {prize.prizeName}
                    </p>
                  ))}
                </div>
              )}
              {missionDetail.missionTypeCode == "RAFFLE" && (
                <div>
                  <p className="contents">
                    {missionDetail.missionProduct?.productName}
                  </p>

                  <button
                    key={1}
                    style={styles.navigate_btn}
                    // onPress={() => {
                    //   //callActHistoryByHistoryType(ActHistoryTypeCode.PRODUCT_READ, ActSectionCode.POST);
                    //   push("ProductDetailScreen", { productId: missionDetail.missionProduct?.id });
                    // }}
                    onClick={(_) => {
                      // callActHistoryByHistoryType(ActHistoryTypeCode.PRODUCT_READ, ActSectionCode.POST);
                      // push("ProductDetailScreen", { productId: missionDetail.missionProduct?.id });
                    }}
                    className="navigate_btn"
                  >
                    <p
                      style={styles.modify_btn_text}
                      className="modify_btn_text"
                    >
                      {t("screen.mission.label.prizeDetail")}
                    </p>
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      {/* {missionDetail.voltPolicyCode &&
      missionDetail.voltAmount &&
      (missionDetail.voltPolicyCode == VoltPolicyCode.MISSION_ACCUM ? (
        renderEachInfo(
          t("screen.mission.label.participationBenefit"),
          t("screen.mission.label.voltAccum", { voltAmount: Math.abs(missionDetail.voltAmount || 0) })
        )
      ) : missionDetail.voltPolicyCode == VoltPolicyCode.MISSION_USE ? (
        renderEachInfo(
          t("screen.mission.label.participationCondition"),
          t("screen.mission.label.voltUse", { voltAmount: Math.abs(missionDetail.voltAmount || 0) })
        )
      ) : (
        <></>
      ))} */}
      {missionDetail.missionStartDate &&
        missionDetail.missionEndDate &&
        renderEachInfo(
          t("screen.mission.label.missionDuration"),
          getDateMMDDddd(missionDetail.missionStartDate)
            .concat(" ~ ")
            .concat(getDateMMDDddd(missionDetail.missionEndDate)),
          false
        )}
      {missionDetail.lotteryDate &&
        renderEachInfo(
          t("screen.mission.label.missionResult"),
          getDateMMDDddd(missionDetail.lotteryDate),
          false
        )}
      {missionDetail.deliveryStartDate &&
        renderEachInfo(
          t("screen.mission.label.deliveryStart"),
          getDateMMDDddd(missionDetail.deliveryStartDate),
          false
        )}
    </div>
  );
};

export default DetailInfoComponent;
