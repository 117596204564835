import {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { observer } from "mobx-react";
import { TitleHeader } from "../../../components/common";
import {
  MagazineDetail as MagazineDetailModel,
  RelatedFeed,
  RelatedProduct,
} from "../../../api/magazine/model";
import { t } from "i18next";
import { FeedTypeCode } from "../../../constants/Feed";
import CommonStore from "../../../store/CommonStore";
import { track } from "../../../hooks/tracker/TrackFunction";
import CustomActivityIndicator from "../../../components/common/CustomActivityIndicator";
import MagazineDetailFooter from "./MagazineDetailFooter";
import "./MagazineDetail.scss";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import CustomCarousel from "../../../components/common/CustomCarousel";
import JoinOverlapComponent from "../../../components/common/JoinOverlapComponent";
import AuthStore from "../../../store/AuthStore";
import PostDetailStore from "../../../store/PostDetailStore";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { postMessageCommon } from "../../../native/WebViewInterface";
import { useTranslation } from "react-i18next";
import { useWindowScroll } from "react-use";
import { ActHistoryRequest } from "../../../api/data/model";
import { ActHistoryTypeCode, ActPageCode } from "../../../constants/ActHistory";
import { sendActHistory } from "../../../api/data/api";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";

const MagazineDetail = observer(
  ({
    magazineDetail,
    isLoading,
    setIsLoading,
  }: {
    magazineDetail: MagazineDetailModel | undefined;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
  }) => {
    const widthApp = CommonStore.widthApp;
    const [showTopIcon, setShowTopIcon] = useState(false);
    const isAuthenticated = useRef<boolean>(AuthStore.sessionUser !== null);
    // const centerInScrollView = Dimensions.get('window').height / 2
    const { t } = useTranslation();

    const { y } = useWindowScroll();

    useEffect(() => {
      setTimeout(() => {
        const actHistory: ActHistoryRequest = {
          actHistoryTypeCode: ActHistoryTypeCode.PAGE_VIEW,
          actPageCode: ActPageCode.MAGAZINE_DETAIL,
          actSourcePageCode: CommonStore.fixedPreviousRouteName,
          attr1: (y === 0).toString(),
        };
        void sendActHistory(actHistory);
      }, 1000);
    }, []);

    const webViewInterfaceFunction = (jsonString: string) => {
      if (!postMessageCommon(jsonString)) {
        // 화면에서 따로 필요한 동작
      }
    };

    useEffect(() => {
      (window as any).ReactNativeWebView.postMessage = webViewInterfaceFunction;
    }, []);

    useEffect(() => {
      if (!magazineDetail) return;

      const magazine = magazineDetail.magazine;

      track("view_magazine_detail", {
        content_id: magazine.feedId,
        content_title: magazine.feedTitle,
        content_type: "magazine",
        tag: magazineDetail.relatedTags
          ? magazineDetail.relatedTags.map((t) => t.tagName)
          : [],
        like_count: magazine.likeCount || 0,
        comment_count: magazine.commentCount || 0,
      });
    }, [magazineDetail]);

    const showActivityIndicator = () => {
      return (
        <CustomActivityIndicator
          isDarkSpinner={true}
          // size={'large'}
          // style={{
          //   marginBottom: centerInScrollView,
          //   alignItems: 'center',
          //   justifyContent: 'center',
          // }}
        ></CustomActivityIndicator>
      );
    };

    const renderContentHtml = () => {
      return (
        <div
          style={{
            marginBottom: 64,
            flex: 1,
          }}
        >
          {magazineDetail?.magazine && magazineContentHtml()}
          <div className="magazine-detail">
            {magazineDetail?.relatedTags &&
              magazineDetail.relatedTags.length > 0 &&
              renderRelatedTagHtml()}
            <div className="divider" />
            {(getRelatedMagazine()?.length || 0) > 0 && renderRelatedMagazine()}
            {(getRelatedPost()?.length || 0) > 0 && renderRelatedPost()}
            {(magazineDetail?.relatedProducts.length || 0) > 0 &&
              renderRelatedProduct()}
          </div>
        </div>
      );
    };

    const magazineContentHtml = () => {
      const __html =
        magazineDetail && magazineDetail.magazine
          ? magazineDetail.magazine?.contents
          : "";
      return (
        <div
          className="magazine-content"
          dangerouslySetInnerHTML={{ __html }}
        />
      );
    };

    const renderRelatedTagHtml = () => {
      return (
        <div className="related_tag_wrapper">
          <section className="section">
            <div className="tags">
              {magazineDetail?.relatedTags.map((item, index) => {
                return (
                  <TouchableWithAuthCheck
                    className="tag"
                    onPress={() => {
                      goTo(`/tag/${item.tagId}`, {
                        state: {
                          tagId: item.tagId,
                          feedId: magazineDetail?.magazine?.feedId,
                        },
                      });
                    }}
                  >
                    #{item.tagName}
                  </TouchableWithAuthCheck>
                );
              })}
            </div>
          </section>
        </div>
      );
    };

    const getRelatedMagazine = () =>
      magazineDetail?.relatedFeeds.filter((value, index) => {
        return value.feedTypeCode === FeedTypeCode.MAGAZINE;
      });

    const renderRelatedMagazine = () => {
      return (
        <section className="section related-magazine-wrapper">
          <h1 className="section-title">
            {t("screen.magazine.relatedMagazines")}
          </h1>
          <div className="slider">
            <CustomCarousel
              initialNumToRender={3}
              accessibilityLabel={"main banner carousel"}
              data={getRelatedMagazine()}
              renderItem={renderRelatedMagazineItem}
              dotContainerStyle={{
                width: widthApp,
              }}
              showDotIndicator
              otherSettings={{
                variableWidth: true,
                arrows: false,
                slidesToScroll: 1,
              }}
            />
          </div>
        </section>
      );
    };

    const renderRelatedMagazineItem = (item: RelatedFeed, index: number) => {
      const widthItem = widthApp - 2 * 16;
      return (
        <div style={{ width: widthItem }} className="swiper-slide">
          <button
            className="item"
            onClick={() => {
              goTo(`/magazine/${item.feedId}`, {
                state: {
                  postIndex: index,
                },
              });
            }}
          >
            <div className="img">
              <FastImageWithFallback
                source={{
                  uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${item.thumbnailFilePath}`,
                }}
                width={widthItem}
              />
            </div>
            <div className="title">{item.feedTitle ? item.feedTitle : ""}</div>
          </button>
        </div>
      );
    };

    const getRelatedPost = () =>
      magazineDetail?.relatedFeeds.filter((value, index) => {
        return value.feedTypeCode === FeedTypeCode.POST;
      });

    const renderRelatedPost = () => {
      const relatedPost = getRelatedPost();
      return (
        <section className="section related-post-wrapper">
          <h1 className="section-title">{t("screen.magazine.relatedPosts")}</h1>
          <div className="slider">
            <CustomCarousel
              initialNumToRender={3}
              accessibilityLabel={"main banner carousel"}
              data={relatedPost}
              renderItem={renderRelatedPostItem}
              otherSettings={{
                variableWidth: true,
                arrows: false,
                slidesToShow: relatedPost && relatedPost.length > 1 ? 2 : 1,
              }}
            />
          </div>
        </section>
      );
    };

    const renderRelatedPostItem = (item: RelatedFeed, index: number) => {
      const widthItem = (widthApp - 24) / 2;
      return (
        <div style={{ width: widthItem }} className="swiper-slide">
          <button
            className="item"
            onClick={() => {
              goTo(`/posts/feed/${item.feedId}`, {
                state: {
                  postIndex: index,
                },
              });
            }}
          >
            <div className="img">
              <FastImageWithFallback
                source={{
                  uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${item.thumbnailFilePath}`,
                }}
                width={widthItem}
              />
            </div>
            <div className="title max-line-2">
              {item.feedTitle ? item.feedTitle : ""}
            </div>
            <div className="writer">{item.nickname}</div>
          </button>
        </div>
      );
    };

    const renderRelatedProduct = () => {
      return (
        <section className="section related-product-wrapper">
          <h1 className="section-title">
            {t("screen.magazine.relatedProducts")}
          </h1>
          <div className="slider">
            <CustomCarousel
              initialNumToRender={3}
              showDotIndicator
              accessibilityLabel={"main banner carousel"}
              data={magazineDetail?.relatedProducts}
              renderItem={renderRelatedProductItem}
              otherSettings={{
                variableWidth: true,
                arrows: false,
                slidesToShow:
                  magazineDetail?.relatedProducts &&
                  magazineDetail?.relatedProducts.length > 1
                    ? 2
                    : 1,
              }}
            />
          </div>
        </section>
      );
    };

    const renderRelatedProductItem = (item: RelatedProduct, index: number) => {
      const widthItem = (widthApp - 24) / 2;
      return (
        <div style={{ width: widthItem }} className="swiper-slide">
          <button
            className="item"
            onClick={() => {
              CommonStore.setShowDownloadAppDrive(true);
              // TODO: 미구현
              // navigate('/ProductDetailScreen', {
              //   state: {
              //     feedId: item.productId,
              //     postIndex: index,
              //   },
              // })
            }}
          >
            <div className="img">
              <FastImageWithFallback
                source={{
                  uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${item.thumbnailFilePath}`,
                }}
                width={widthItem}
              />
            </div>
            <div className="title max-line-2">{item.productName}</div>
            <div className="brand">
              <span>{item.brandName ? item.brandName : ""}</span>
              <span>{item.productModelName ? item.productModelName : ""}</span>
            </div>
          </button>
        </div>
      );
    };

    const wref = useRef<HTMLIFrameElement>(null);
    const styleHeader: CSSProperties = {
      position: "fixed",
      top: 0,
      right: 0,
      zIndex: 1,
      maxWidth: 450,
      width: "100%",
    };

    // TODO:
    return (
      <>
        <TitleHeader
          style={styleHeader}
          title={t(`screen.magazine.magazineDetailTitle`)}
          isHome
        />
        {!isAuthenticated.current ? (
          <JoinOverlapComponent>
            <div style={{ height: 2200, overflow: "clip" }}>
              {magazineDetail?.magazine && renderContentHtml()}
            </div>
          </JoinOverlapComponent>
        ) : (
          magazineDetail?.magazine && renderContentHtml()
        )}
        {isAuthenticated.current && (
          <MagazineDetailFooter magazine={magazineDetail?.magazine} />
        )}
      </>
    );
  }
);

export default MagazineDetail;
