import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import "./ListWithStickyTabs.scss";
import { handleTopButtonScroll } from "../../utils/common";
import { Virtuoso, VirtuosoProps } from "react-virtuoso";

type ITEM_TYPE =
  | "FIXED_HEADER"
  | "SCROLLABLE_HEADER"
  | "STICKY_TABS"
  | "STICKY_TABS_CUSTOM"
  | "STICKY_ITEMS";

interface T {
  type: ITEM_TYPE;
}

export interface Tab {
  key: string;
  title?: string;
  accessibilityLabel?: string;
  content?: ReactElement | ((isActive: boolean) => ReactElement);
}

export interface ListWithStickyTabsProps extends VirtuosoProps<any, any> {
  scrollableHeader?: ReactElement;
  stickyItems?: ReactElement;
  listRenderComponent?: ReactElement;
  stickyTabs?: Tab[];
  stickyTabsCustom?: ReactElement;
  onChangeTab?: (key: string) => void;
  activeTabKey?: string;
  // tabStyles?: {
  //   tabTextContainerStyle?: StyleProp<divStyle>;
  //   tabTextContainerActiveStyle?: StyleProp<divStyle>;
  //   tabTextStyle?: StyleProp<divStyle>;
  //   tabTextActiveStyle?: StyleProp<divStyle>;
  //   tabWrapperStyle?: StyleProp<divStyle>;
  //   tabsContainerStyle?: StyleProp<divStyle>;
  // };
  // onScroll?: (e: NativeSyntheticEvent<NativeScrollEvent>) => void;
  showScrollToTop?: boolean;
  topButtonRight?: number;
  contentScrollRef: any;
  onRefresh?: () => void;
  isShowSpacer?: boolean;
}

const Tab = ({
  activeTabIndex,
  stickyTabs,
  onChangeTab,
}: {
  activeTabIndex: number;
} & Pick<ListWithStickyTabsProps, "stickyTabs" | "onChangeTab">) => {
  const handleTabPress = useCallback(
    (key: string) => () => {
      onChangeTab && onChangeTab(key);
    },
    [onChangeTab]
  );
  return (
    <div style={{ flexDirection: "row" }} className="tabsContainerStyle">
      {stickyTabs?.map((tab, index) => {
        return (
          <div
            aria-label={tab.accessibilityLabel}
            key={tab.key}
            className="tabWrapperStyle"
            onClick={handleTabPress(tab.key)}
          >
            <div
              className={
                activeTabIndex === index
                  ? "tabTextContainerActiveStyle"
                  : "tabTextContainerStyle"
              }
            >
              <p
                className={
                  activeTabIndex === index
                    ? "tabTextActiveStyle"
                    : "tabTextStyle"
                }
              >
                {tab.title}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ListWithStickyTabs = ({
  scrollableHeader,
  stickyItems,
  listRenderComponent,
  stickyTabs,
  stickyTabsCustom,
  onChangeTab,
  activeTabKey,
  onScroll,
  topButtonRight,
  showScrollToTop,
  contentScrollRef,
  onRefresh,
  className: tabWrapperClassName,
  isShowSpacer = true,
  // endReached,
  ...props
}: ListWithStickyTabsProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    stickyTabs?.findIndex((tab) => tab.key === activeTabKey) || 0
  );
  const [bounceable, setBounceable] = useState<boolean>(false);
  const [showTopButton, setShowTopButton] = useState<boolean>(false);
  const scrollRef = useRef<typeof Virtuoso<any>>(null);

  const handleTabChange = useCallback(
    (key: string) => {
      setActiveTabIndex(stickyTabs?.findIndex((tab) => tab.key === key) || 0);
      if (contentScrollRef && contentScrollRef.current) {
        contentScrollRef.current.scrollToIndex({ index: 0 });
      }
      onChangeTab && onChangeTab(key);
    },
    [onChangeTab, stickyTabs, contentScrollRef]
  );

  // const memoisedStickyHeaderIndices = useMemo(
  //   () => (scrollableHeader ? [1] : [0]),
  //   [scrollableHeader]
  // );

  const memoisedData = useMemo(() => {
    const _data: T[] = [];
    scrollableHeader && _data.push({ type: "SCROLLABLE_HEADER" });
    stickyTabs && _data.push({ type: "STICKY_TABS" });
    stickyTabsCustom && _data.push({ type: "STICKY_TABS_CUSTOM" });
    stickyItems && _data.push({ type: "STICKY_ITEMS" });
    return _data;
  }, [scrollableHeader, stickyTabs, stickyItems, stickyTabsCustom]);

  const memoisedRenderItem = useCallback(
    (item: any, index: number) => {
      if (scrollableHeader && item.type === "SCROLLABLE_HEADER") {
        return scrollableHeader;
      } else if (stickyTabsCustom && item.type === "STICKY_TABS_CUSTOM") {
        return stickyTabsCustom;
      } else if (stickyTabs && item.type === "STICKY_TABS") {
        return (
          <div
            className={tabWrapperClassName}
            style={{ backgroundColor: "white" }}
          >
            <Tab
              key={index}
              activeTabIndex={activeTabIndex}
              stickyTabs={stickyTabs}
              onChangeTab={handleTabChange}
            />
          </div>
        );
      } else if (stickyItems && item.type === "STICKY_ITEMS") {
        return stickyItems;
      } else {
        return null;
      }
    },
    [
      activeTabIndex,
      handleTabChange,
      scrollableHeader,
      stickyTabs,
      stickyItems,
      stickyTabsCustom,
    ]
  );

  const memoisedKeyExtractor = useCallback(
    (item: T | { type?: ITEM_TYPE }, index: number) => {
      let key = "";
      if ((item as any).type as ITEM_TYPE) {
        key = (item as any).type;
      }
      return key;
    },

    []
  );

  /* eslint-disable */
  // TODO : 스크롤 이벤트
  // const handleScroll = useCallback(
  //   (e: NativeSyntheticEvent<NativeScrollEvent>) => {
  //     const { height } = Dimensions.get("screen");
  //     setBounceable(
  //       e.nativeEvent.contentOffset.y >= responsiveScreenHeight(100)
  //     );
  //     if (onScroll) {
  //       onScroll(e);
  //     }
  //     handleTopButtonScroll(
  //       e,
  //       () => {
  //         setShowTopButton(true);
  //       },
  //       () => {
  //         setShowTopButton(false);
  //       },
  //       contentScrollRef
  //     );
  //   },
  //   [onScroll]
  // );
  /* eslint-enable */

  useEffect(() => {
    setActiveTabIndex(
      stickyTabs?.findIndex((tab) => tab.key === activeTabKey) || 0
    );
  }, [activeTabKey, stickyTabs]);

  const [state, setState] = useState({
    isFetching: false,
  });

  const onRefresh2 = () => {
    setState({ isFetching: false });

    if (onRefresh) {
      onRefresh();
    }
  };
  return (
    <>
      {/* <Virtuoso
        ref={contentScrollRef}
        data={memoisedData}
        itemContent={memoisedRenderItem}
        endReached={endReached}
        components={{
          Footer: () => (
            <>
              {stickyTabs
                ? stickyTabs[activeTabIndex]?.content
                : listRenderComponent}
              <div style={{ height: 60, backgroundColor: "transparent" }} />,
            </>
          ),
        }}
      /> */}
      <>
        {memoisedData.map((item, index) => memoisedRenderItem(item, index))}
        {stickyTabs ? stickyTabs[activeTabIndex]?.content : listRenderComponent}
        {isShowSpacer && (<div style={{ height: 60, backgroundColor: "transparent" }} />)}
      </>
    </>
  );
};

export default ListWithStickyTabs;
