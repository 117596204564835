import React, { memo, useContext, useEffect, useState } from "react";

import MyAppliancesStore, {
  MyAppliancesList,
} from "../../../store/MyAppliancesStore";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";

import { useTranslation } from "react-i18next";

import { ReactComponent as CheckOnIcon } from "../../../assets/icons/checkbox_big_on.svg";
import { ReactComponent as CheckOffIcon } from "../../../assets/icons/checkbox_big_off.svg";
import { ReactComponent as RadioCheckedIcon } from "../../../assets/icons/radio_checked_button.svg";
import { ReactComponent as MyAppliancePlusIcon } from "../../../assets/icons/my_appliance_plus_icon.svg";
import { ReactComponent as MoveToPostIcon } from "../../../assets/icons/move_to_post_icon.svg";
import { ReactComponent as UpTag } from "../../../assets/icons/up_product_label.svg";
import { ReactComponent as DimImage } from "../../../assets/icons/image_add_dim.svg";

import { getDateYYMMDD, getDateYYYYMMDD } from "../../../utils/datetime";
import AuthStore from "../../../store/AuthStore";
import { font_weight_normal } from "../../../assets/styles/texts";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import "./MyAppliancesItem.scss";

export const MyAppliancesItem = memo(
  ({
    item,
    index,
    mode,
    isRadioSelected,
  }: {
    item: MyAppliancesList;
    index: number;
    mode: string;
    isRadioSelected: boolean;
  }) => {
    const { t } = useTranslation();
    const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;
    const [checked, setChecked] = useState(false);

    const getCategoryName = (code: string) => {
      const result = MyAppliancesStore.categoryList?.find(
        (x) => x.codeValue == code
      );
      return result ? result.codeName : "";
    };

    useEffect(() => {
      setChecked(false);
    }, [item.productId, item.tempProductId, mode]);

    const isMyProduct = () => {
      return (
        !MyAppliancesStore.targetMemberUuid ||
        AuthStore.sessionUser?.memberUuid === MyAppliancesStore.targetMemberUuid
      );
    };

    const isViewMode = () => {
      return mode === "" || mode === "none";
    };

    const isEditMode = () => {
      return mode === "edit";
    };

    const isDelMode = () => {
      return mode === "del";
    };

    const checkIcon = () => {
      if (checked) {
        return <CheckOnIcon />;
      } else {
        return <CheckOffIcon />;
      }
    };

    const emptyImage = () => {
      let view = <></>;

      if (isMyProduct() && item.feedId) {
        view = (
          <>
            <div style={{ position: "absolute", top: 47 }}>
              <div
                style={{ backgroundColor: "#be69c3", width: 60, height: 25 }}
              >
                <p className="img_post_txt">{"포스트"}</p>
              </div>
            </div>
          </>
        );
      }

      if (item.productFlag) {
        return <>{view}</>;
      }

      return (
        <>
          <div className="empty_img">
            <p className="empty_txt">{"등록\n대기중"}</p>
          </div>
        </>
      );
    };

    const PlusButton = () => {
      return (
        <>
          <div
            className="product_option_button"
            onClick={() => {
              const tagList: string[] = [];
              item?.brandName &&
                tagList.push(item?.brandName?.replace(/ /g, "").toLowerCase());
              item?.modelName &&
                tagList.push(item?.modelName?.replace(/ /g, "").toLowerCase());
              item?.productName &&
                tagList.push(
                  item?.productName?.replace(/ /g, "").toLowerCase()
                );

              goTo("/PostWriteScreen", {
                state: {
                  tags: tagList,
                  productInfo: {
                    productId: item.productId,
                    tempProductId: item.tempProductId,
                    productName: item.productName,
                    productModelName: item.modelName,
                    thumbnailFilePath: item.imageUrl,
                    originalFilePath: item.imageUrl,
                    brandName: item.brandName,
                    manufacturerName: item.modelName,
                    myProductFlag: item.productId
                      ? item.productId
                      : item.tempProductId,
                  },
                },
              });
            }}
          >
            <MyAppliancePlusIcon />
          </div>
        </>
      );
    };

    const MoveToPostButton = () => {
      return (
        <>
          <div
            className="product_option_button"
            onClick={() => {
              goTo("PostDetailScreen", {
                state: {
                  feedId: item.feedId,
                  postIndex: 0,
                },
              });
            }}
          >
            <MoveToPostIcon />
          </div>
        </>
      );
    };

    const onClick = () => {
      if (isViewMode() && item.productFlag) {
        goTo("/ProductDetailScreen", {
          state: {
            productId: item.productFlag ? item.productId : item.tempProductId,
          },
        });
        return;
      }

      if (isDelMode()) {
        MyAppliancesStore.selectProduct(item);
        setChecked(!checked);
        return;
      }
      if (isEditMode()) {
        const productId = item.productFlag
          ? item.productId
          : item.tempProductId;
        const productFlag = item.productFlag ?? false;

        MyAppliancesStore.setEditInfo(item.idx, productId ?? 0, productFlag);
      }
    };

    const MyProductItem = () => {
      return (
        <div id="my_appliance_item" className="product">
          <div
            className="product_item"
            aria-label={`Product result ${index}`}
            onClick={onClick}
          >
            {isDelMode() && (
              <div className="product_check_btn">{checkIcon()}</div>
            )}

            {isEditMode() && (
              <div
                className={`${
                  isRadioSelected ? "radio_checked_btn" : "radio_btn"
                }`}
              >
                {isRadioSelected && <RadioCheckedIcon />}
              </div>
            )}

            <div
              style={{
                flexDirection: "column",
                marginRight: 12,
                alignItems: "center",
              }}
            >
              <FastImageWithFallback
                aria-label="Product image"
                source={{
                  uri: `${PUBLIC_BUCKET_URL}${item.imageUrl || ""}`,
                }}
                className="product_image"
              >
                {emptyImage()}
              </FastImageWithFallback>
              <p
                style={{
                  fontSize: 12,
                  color: "#999999",
                  letterSpacing: -0.48,
                }}
              >
                {getDateYYMMDD(item.createDatetime ?? "")}
              </p>
            </div>
            <div className="product_info">
              {item.categoryCode && (
                <>
                  <p className="product_text1" aria-label="Product category">
                    {getCategoryName(item.categoryCode || "")}
                  </p>
                </>
              )}
              <p
                className={`${
                  item.productFlag ? "product_text1" : "product_text1_inactive"
                }`}
                aria-label="Product name"
              >
                {item.productName && item.productName.length > 27
                  ? item.productName.substring(0, 27 - 3) + "..."
                  : item.productName}
              </p>
              <div className="product_info_bottom">
                <p
                  className={`${
                    item.productFlag
                      ? "product_text2"
                      : "product_text2_inactive"
                  }
                }`}
                  aria-label="Brand name"
                >
                  {item.brandName}
                </p>
                <p
                  className={`${
                    item.productFlag
                      ? "product_text3"
                      : "product_text3_inactive"
                  }`}
                >
                  |
                </p>
                <p
                  className={`${
                    item.productFlag
                      ? "product_text2"
                      : "product_text2_inactive"
                  }`}
                  aria-label="Product model name"
                >
                  {item.modelName}
                </p>
              </div>
            </div>
          </div>

          <div className="product_option_button_wrap">
            {isViewMode() && isMyProduct() && !item.feedId && <PlusButton />}
            {isViewMode() && isMyProduct() && item.feedId && (
              <MoveToPostButton />
            )}
          </div>
        </div>
      );
    };

    return (
      <>
        <MyProductItem />
      </>
    );
  }
);

MyAppliancesItem.displayName = "MyAppliancesItem";
