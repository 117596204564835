import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import SearchStore from "../../../store/SearchStore";
import { SearchTabMenu, SEARCH_RESULT_COUNT } from "../../../constants/Search";
import SearchCountAndSort from "./SearchCountAndSort";
import SearchEmpty from "./SearchEmpty";
import { SearchItem } from "../../../api/search/model";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import CommonStore from "../../../store/CommonStore";
import { ActHistoryTypeCode, ActPageCode } from "../../../constants/ActHistory";
import { ReactComponent as MallTag } from "../../../assets/icons/mall_sale_tag.svg";
import { useTranslation } from "react-i18next";
import { PullToRefresh } from "antd-mobile";
import { sleep } from "antd-mobile/es/utils/sleep";
import { ReactComponent as DarkSpinner } from "../../../assets/animations/darkSpinner.svg";
import { useWindowScroll } from "react-use";
import CustomActivityIndicatorSmall from "../../../components/common/CustomActivityIndicatorSmall";
import "./SearchProduct.scss";
import { useLocation } from "react-router-dom";
import { useEffectOnlyOnce } from "../../../hooks/UseEffectOnlyOnce";
import SearchResultLayout from "../SearchResultLayout";

const SearchProduct = observer((data: any) => {
  const navigation = data?.navigation;
  const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);
  const FALLBACK_IMAGE_URL = PUBLIC_BUCKET_URL + "/ASSETS/fall_back_image.png";
  const {
    searchText,
    productResult,
    loadingInList,
    getLoadingInList,
    productSortAndIndex,
    refreshing,
    getSearchResult,
    setToast,
    setRefreshing,
  } = SearchStore;

  const { x, y } = useWindowScroll();

  const { t } = useTranslation();

  const [afterMount, setAfterMount] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setAfterMount(true);
    }, 500);
  }, []);

  useEffect(() => {
    if (afterMount) {
      const bottom =
        Math.ceil(window.innerHeight + window.scrollY) >=
        document.documentElement.scrollHeight;

      if (bottom) {
        if ((productResult?.totalCount || 0) > 0 && !loadingInList) {
          const tempStart = productSortAndIndex?.lastIndex + 1;
          if (
            (productResult?.totalCount || 0) >
            tempStart * SEARCH_RESULT_COUNT
          ) {
            void getSearchResult(
              searchText,
              SearchTabMenu.Product,
              productSortAndIndex.sort,
              SEARCH_RESULT_COUNT,
              tempStart
            );
          }
        }
      }
    }
  }, [y]);

  const moveToProductDetail = (item: SearchItem, index: number) => {
    CommonStore.callActHistory(
      ActHistoryTypeCode.PRODUCT_READ,
      (item.productId ?? 0).toString(),
      ActPageCode.SEARCH_PRODUCT,
      undefined,
      index?.toString(),
      searchText,
      SearchStore.getSortType()
    );
    // navigation.push("ProductDetailScreen", { productId: item.productId });
    CommonStore.setShowDownloadAppDrive(true);
  };

  return (
    <SearchResultLayout tabType={SearchTabMenu.Product}>
      <div
        id="SearchProduct"
        className="tab_content"
        aria-label="Search product"
      >
        <PullToRefresh
          onRefresh={async () => {
            await sleep(1000);
            setRefreshing(true);
            void getSearchResult(
              searchText,
              SearchTabMenu.Product,
              productSortAndIndex.sort,
              SEARCH_RESULT_COUNT,
              0
            );
          }}
          renderText={(status) => {
            return (
              <div>
                {status === "refreshing" && (
                  <DarkSpinner style={{ width: 50, height: 50 }} />
                )}
              </div>
            );
          }}
        >
          <SearchCountAndSort
            type={SearchTabMenu.Product}
            sort={productSortAndIndex.sort}
            count={productResult?.totalCount || 0}
          />
          <div className="scroll_wrap product_wrap">
            {productResult?.list?.slice() &&
            productResult?.list?.slice().length > 0 ? (
              productResult?.list?.slice().map((item: any, index: number) => {
                return (
                  <div className="row">
                    <div
                      className="relation_product_item"
                      onClick={() => moveToProductDetail(item, index)}
                      aria-label={`Search Product ${index}`}
                    >
                      <FastImageWithFallback
                        source={{
                          uri: `${PUBLIC_BUCKET_URL}${
                            item.thumbnailFilePath?.replace(
                              "THUMBNAIL",
                              "ORIGINAL"
                            ) || ""
                          }`,
                        }}
                        wrapperClassName="relation_product_image_wrapper"
                        className="relation_product_image"
                        fallbackImageUri={FALLBACK_IMAGE_URL}
                      />
                      <div className="relation_product_item_text1">
                        {item.productName}
                      </div>
                      <div className="relation_product_info">
                        <div className="relation_product_item_text2">
                          {item.brandNameKor}
                        </div>
                        <div className="bar">
                          <div></div>
                        </div>
                        <div className="relation_product_item_text2">
                          {item.productModelName}
                        </div>
                      </div>
                      {item.lifecareProductId &&
                      item.lifecareProductId !== null ? (
                        <div className="on_sale_tag_wrap">
                          <div className="mall_tag">
                            <MallTag />
                          </div>
                          <div className="on_sale_tag_text">
                            {t("screen.main.label.onSale")}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <SearchEmpty isGrid={true} />
            )}
          </div>

          {getLoadingInList ? <CustomActivityIndicatorSmall /> : <></>}
        </PullToRefresh>
      </div>
    </SearchResultLayout>
  );
});

export default SearchProduct;
