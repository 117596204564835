import dayjs from "dayjs";
import { action, computed, makeObservable, observable } from "mobx";
import { StackTypeCode } from "../constants/Stack";
// import { StackTypeCode } from "../constants/Stack";

class TabStore {
  @observable _tabBarDisplay: undefined | "none" = undefined;

  @observable _currentTab: StackTypeCode = StackTypeCode.HOME_STACK;

  @observable _currentTabDate: string | undefined;

  @observable _addModalFocused = false;

  @observable _tabFocused = false;

  constructor() {
    makeObservable(this);
  }

  @computed get tabBarDisplay() {
    return this._tabBarDisplay;
  }

  @computed get currentTab() {
    return this._currentTab;
  }

  @computed get addModalFocused() {
    return this._addModalFocused;
  }

  @computed get tabFocused() {
    return this._tabFocused;
  }
  @action setTabFocused = (x: boolean) => {
    this._tabFocused = x;
  };

  // @action getCurrentTabName = (tabIndex: any) => {
  //   return tabIndex === 0
  //     ? StackTypeCode.HOME_STACK
  //     : tabIndex === 1
  //     ? StackTypeCode.POST_STACK
  //     : tabIndex === 2
  //     ? StackTypeCode.MALLMAIN_STACK
  //     : tabIndex === 3
  //     ? StackTypeCode.BRANDCOMMUNITYMAIN_STACK
  //     : tabIndex === 4
  //     ? StackTypeCode.QNA_STACK
  //     : StackTypeCode.HOME_STACK;
  // };

  @action setTabBarDisplay = (tabBarDisplay: undefined | "none") => {
    this._tabBarDisplay = tabBarDisplay;
  };

  @action setCurrentTab = (currentTab: StackTypeCode) => {
    this._currentTab = currentTab;
  };
  // @action setTabReload = (currentTab2: StackTypeCode) => {
  //   if (this._currentTab === currentTab2) {
  //     this._currentTabDate =
  //       currentTab2.toString() + "_" + dayjs().format("YYYYMMDDHHmmss");
  //   }
  // };
  @computed get tabReload() {
    return this._currentTabDate;
  }
  @action setAddModalFocused = (focused: boolean) => {
    this._addModalFocused = focused;
  };
}

export default new TabStore();
function Moment(arg0: Date) {
  throw new Error("Function not implemented.");
}
