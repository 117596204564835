import { t } from 'i18next'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { UploadFile } from '../api/file/model'
import { FeedFileForUploadS3, RecommendTagResponse } from '../api/post/model'
import {
  BLANK_TEMP_FILE,
  FeedMode,
  FeedTypeCode,
  POST_BLANK_FILE,
  UploadTypeCode,
} from '../constants/Feed'
import CommonStore from './CommonStore'
import { SuccessOrNot } from '../constants/Common.enum'
import { MemberItem } from '../api/member/model'
import { FileType } from '../constants/File'
import { ProductForWrite } from './ProductSearchAddStore'
import { NewProduct } from '../api/product/model'
import UploadStore from './upload/UploadStore'
import BadgeStore from './BadgeStore'
import { BadgeCode } from '../constants/Badge.enum'
import { UploadStatus } from '../constants/upload/UploadStatus'
import { bottomOffset, sleep } from '../utils/common'
import {
  Article,
  ArticleWriteRequest,
  ArticleWriteResponse,
} from '../api/article/model'
import { articleDetail, saveArticle } from '../api/article/api'
import { CommonCode } from '../api/commonCode/model'
import { CustomError } from '../utils/customError'
import ArticleDetailStore from './ArticleDetailStore'
import { showBottomToast } from '../utils/Toast'
import { AlbumMulitResponse, ImageForUI, callNativeAlbum } from '../utils/album'
export interface ArticleModifyModel<T> {
  id: number
  contents: T
  modified?: boolean
}
export const ARTICLE_BLANK = {
  communityId: 0,
  articleTypeCode: '',
  articleTitle: '',
  articleContents: '',
  articleDisclosureYn: 'Y',
  files: [POST_BLANK_FILE],
  products: [],
}

class ArticleWriteStore {
  constructor() {
    makeObservable(this)
  }

  @observable errorMessage?: string
  @observable fileList: ArticleModifyModel<UploadFile>[] = [
    { id: 0, contents: POST_BLANK_FILE },
  ]
  @observable tagList: ArticleModifyModel<string>[] = []
  @observable isVisibleTagList = false
  @observable tagUpdated = false
  @observable recommendTags: RecommendTagResponse[] = []
  @observable successRegister = false

  @observable userList: ArticleModifyModel<MemberItem>[] = []
  @observable userSearchResult?: MemberItem[] | null
  @observable isVisibleUserList = false
  @observable userUpdated = false
  @observable recommendUsers: MemberItem[] = []
  @observable wholeData: ArticleWriteRequest = ARTICLE_BLANK
  @observable toastMessage = ''
  @observable tempProductList: ProductForWrite[] = []
  @observable productUpdated = false
  @observable videoCompressing = false

  @observable lastUploadIndex = 0
  @observable callIndex = 0
  @observable tempUploadFile: FeedFileForUploadS3 = BLANK_TEMP_FILE
  @observable wholeDataForSaveCall: ArticleWriteRequest = ARTICLE_BLANK
  @observable _articleTypeCodeList: CommonCode[] = []

  @computed get articleTypeCodeList() {
    return this._articleTypeCodeList
  }
  @action setArticleTypeCodeList = (list: CommonCode[]) => {
    this._articleTypeCodeList = list
  }
  @action getArticleTypeCodeName = (code: string) => {
    return (
      this._articleTypeCodeList.find((x) => x.code === code)?.codeName || ''
    )
  }

  @action setError = (message?: string) => {
    this.errorMessage = message
  }

  @computed get hasError() {
    return (
      this.errorMessage !== null &&
      this.errorMessage !== undefined &&
      this.errorMessage.length > 0
    )
  }

  @computed get isValid() {
    let checkingValid = false
    checkingValid =
      this.wholeData.articleTypeCode?.trim()?.length > 0 &&
      this.wholeData?.articleTitle?.trim()?.length > 0 &&
      this.wholeData?.articleContents?.trim()?.length > 0

    checkingValid = checkingValid && !this.videoCompressing

    return checkingValid
  }

  @action clear = () => {
    this.errorMessage = ''
    this.toastMessage = ''
    this.fileList = [{ id: 0, contents: POST_BLANK_FILE }]
    this.tagList = []
    this.isVisibleTagList = false
    this.tagUpdated = false
    this.successRegister = false
    this.recommendTags = []
    this.userList = []
    this.userSearchResult = null
    this.isVisibleUserList = false
    this.userUpdated = false
    this.recommendUsers = []
    this.wholeData = ARTICLE_BLANK
    this.lastUploadIndex = 0
    this.callIndex = 0
    this.tempProductList = []
    this.uploadFileClear()
  }

  @action showValidInfo = () => {
    if (this.wholeData?.articleTypeCode?.trim()?.length < 1) {
      showBottomToast(t('screen.article.message.inputTypeCode'), 40)
      return
    }
    if (this.wholeData?.articleTitle?.trim()?.length < 1) {
      showBottomToast(t('screen.article.message.inputTitle'), 40)
      return
    }
    if (this.wholeData?.articleContents?.trim()?.length < 1) {
      showBottomToast(t('screen.article.message.inputContent'), 40)
    }
  }

  @action setWholeData = (data: Article) => {
    this.wholeData.articleId = data.articleId
    this.wholeData.articleContents = data.articleContents || ''
    this.wholeData.articleDisclosureYn = data.articleDisclosureYn || 'N'
    this.wholeData.articleTitle = data.articleTitle
    this.wholeData.articleTypeCode = data.articleTypeCode
    this.wholeData.communityId = data.communityId
    this.wholeData.articleFileGroupId = data.articleFileGroupId

    this.fileList = []
    data.files?.forEach((item, index) => {
      const uploadFile = {
        id: index,
        contents: item,
        modified: false,
      } as ArticleModifyModel<UploadFile>
      uploadFile.contents.modified = false

      this.fileList.push({ id: index, contents: item, modified: false })
    })

    this.fileList.sort(function (a, b) {
      const athum = a.contents.thumbnailYn
      const bthum = b.contents.thumbnailYn
      if (athum > bthum) return -1
      else if (athum < bthum) return 1
      else return 0
    })

    if (data.files !== undefined && data.files.length < 10) {
      this.fileList.unshift({
        id: data.files?.length,
        contents: POST_BLANK_FILE,
        modified: false,
      })
    }

    data.productList?.forEach((item) => {
      this.tempProductList.push({
        isRepresent: item.thumbnailYn === 'Y',
        product: {
          productId: item.productId,
          thumbnailFilePath: item.productFiles[0]?.thumbnailFilePath,
          originalFilePath:
            item.productFiles[0]?.thumbnailFilePath?.replace(
              'THUMBNAIL',
              'ORIGINAL'
            ) || '',
          manufacturerName: item.manufacturerNameKor,
          productModelName: item.productModelName,
          productName: item.productName,
          openApiFlag: item.openApiFlag,
          nproductId: item.nproductId,
        },
      })
    })

    if (this.tempProductList.length > 0) {
      this.productUpdated = !this.productUpdated
      const tempRemakeProductIds: number[] = []
      this.tempProductList?.forEach(
        (item) =>
          item?.product?.productId &&
          tempRemakeProductIds.push(item.product.productId)
      )
    }
  }

  @action setCommunityId = (id: number) => {
    this.wholeData.communityId = id
  }

  @action setArticleTypeCode = (x: string) => {
    this.wholeData.articleTypeCode = x
  }

  @action setTitle = (title: string) => {
    this.wholeData.articleTitle = title
  }

  @action setContent = (content: string) => {
    this.wholeData.articleContents = content
  }
  @action setArticleDisclosureYn = (val: string) => {
    this.wholeData.articleDisclosureYn = val
  }

  @action setToast = (message: string) => {
    this.toastMessage = message
  }

  @action showNativeAlbum = async () => {
    CommonStore.setLoading(true)
    let imageFileLength = 0
    let videoFileLength = 0
    this.fileList.forEach((item) => {
      if (
        item.contents.fileType === FileType.IMAGE &&
        item.contents.fileSize >= 0
      )
        imageFileLength = imageFileLength + 1
      if (
        item.contents.fileType === FileType.VIDEO &&
        item.contents.fileSize >= 0
      )
        videoFileLength = videoFileLength + 1
    })

    const itemtype = this.fileList
    let w = 0
    let h = 0
    if (this.fileList.length > 0) {
      const f = this.fileList[0]
      if (
        f !== null &&
        f.contents !== null &&
        f.contents.fileResolution !== null
      ) {
        const strR = f.contents.fileResolution?.split('x')
        if (strR?.length === 2) {
          const firstW = Number(strR[0])
          const firstH = Number(strR[1])
          w = firstW
          h = firstH
        }
      }
    }

    this.videoCompressing = true
    const maxImageCount = 6 - imageFileLength
    const maxVideoCount = 4 - videoFileLength

    const res = (await callNativeAlbum(
      maxImageCount,
      maxVideoCount,
      false,
      'ARTICLE',
      false,
      w,
      h
    )) as AlbumMulitResponse
    this.videoCompressing = false

    const files: ImageForUI[] = res.param.list
    if (files.length > 0) {
      const blankIndex = this.fileList.findIndex((i) => i.contents.fileSize < 0)
      if (blankIndex > -1) this.fileList.splice(blankIndex, 1)

      files.forEach((item, index) => {
        if (this.fileList.length < 10) {
          const sp = item.fileName.split('.')
          this.fileList.push({
            id: this.fileList.length,
            contents: {
              originalFileName: item.fileName,
              fileName: sp[0],
              filePath: item.base64,
              imageLocalFilePath:
                item.type.toLocaleUpperCase() === 'IMAGE' ? item.fileUri : '',
              videoLocalFilePath:
                item.type.toLocaleUpperCase() === 'VIDEO' ? item.fileUri : '',
              thumbnailTempPath: item.thumbnailImagepath,
              fileResolution: item.resThumbX + 'x' + item.resThumbY,
              fileSize: Number(item.fileSize),
              fileType: item.type.toLocaleUpperCase(),
              fileExtension: sp[sp.length - 1],
              thumbnailYn: 'N',
              modified: true,
            },
            modified: true,
          })
        }
      })

      this.checkAddedFiles()
    }
    CommonStore.setLoading(false)
  }

  @observable fileUpdated = false
  @action checkAddedFiles = () => {
    const fileLength = this.fileList.length
    if (fileLength < 10)
      this.fileList.unshift({
        id: fileLength,
        contents: POST_BLANK_FILE,
        modified: false,
      }) // 사진 추가하는 화면
    if (this.fileList.findIndex((x) => x.contents.thumbnailYn === 'Y') < 0)
      this.fileList[1].contents.thumbnailYn = 'Y' // 대표 이미지 지정
    this.fileUpdated = !this.fileUpdated
  }

  @action deleteFile = (fileIndex: number) => {
    this.fileList?.splice(fileIndex, 1)
    if (
      this.fileList?.length < 10 &&
      this.fileList?.findIndex((item) => item.contents.filePath === '') < 0
    )
      this.fileList.unshift({
        id: this.fileList?.length,
        contents: POST_BLANK_FILE,
        modified: false,
      })

    if (
      this.fileList?.length > 1 &&
      this.fileList.findIndex((item) => item.contents.thumbnailYn === 'Y') < 0
    )
      this.fileList[1].contents.thumbnailYn = 'Y'
    this.fileUpdated = !this.fileUpdated
  }

  @action setRepresentation = (index: number) => {
    this.fileList.forEach((x) =>
      x.id === index
        ? (x.contents.thumbnailYn = 'Y')
        : (x.contents.thumbnailYn = 'N')
    )
    this.fileUpdated = !this.fileUpdated
    this.dataUnion()
  }

  @action successInit = () => {
    this.successRegister = false
  }

  @action addProduct = (item: NewProduct) => {
    const duplication = this.tempProductList.findIndex(
      (info) =>
        item.productId ===
        (info.product.productId || info.product.tempProductId)
    )
    if (duplication < 0) {
      this.tempProductList.push({ product: item, isRepresent: false })
      this.setAutoRepresent()
    } else {
      showBottomToast(
        t('screen.feedProduct.message.alreadyAdd'),
        bottomOffset()
      )
    }
  }

  @action setAutoRepresent = () => {
    if (this.tempProductList && this.tempProductList.length > 0) {
      const representIndex = this.tempProductList.findIndex(
        (item) => item.isRepresent && item.product.productId
      )
      if (representIndex < 0) {
        const willBeRepresentIndex = this.tempProductList.findIndex(
          (item) => item.product.productId
        )
        if (willBeRepresentIndex > -1)
          this.tempProductList[willBeRepresentIndex].isRepresent = true
      }
    }
    this.productUpdated = !this.productUpdated
    this.dataUnion()
  }

  @action deleteProduct = (index: number) => {
    this.tempProductList.splice(index, 1)
    this.setAutoRepresent()
  }

  @action setRepresentProduct = (index: number) => {
    this.tempProductList.forEach((item) => (item.isRepresent = false))
    this.tempProductList[index].isRepresent = true
    this.productUpdated = !this.productUpdated
    this.dataUnion()
  }

  @action dataUnion = () => {
    this.wholeData.files = []
    this.fileList.forEach((item) => {
      if (item.contents.fileSize > -1 && item.contents.filePath.length > 0)
        this.wholeData.files.push(item.contents)
    })

    this.wholeData.products = []

    this.tempProductList.forEach((item) => {
      item.product.openApiFlag && item.product.nproductId === undefined
        ? item.product.myProductId && item.product.myProductId !== null
          ? this.wholeData.products?.push({
              id: item.product.myProductId,
              thumbnailYn: item.isRepresent ? 'Y' : 'N',
              tempProductYn:
                item.product?.openApiFlag || item.product?.tempProductId
                  ? 'Y'
                  : 'N',
              product_from: item.product.from ?? '',
            })
          : this.wholeData.products?.push({
              id: -1,
              openApiFlag: true,
              productId: item.product?.productId,
              productName: item.product?.productName,
              brandNameKor: item.product?.brandName,
              productModelName: item.product?.productModelName,
              lprice: item.product?.lprice,
              hprice: item.product?.hprice,
              thumbnailFilePath: item.product?.thumbnailFilePath,
            })
        : this.wholeData.products?.push({
            id: item.product?.productId || item.product?.tempProductId || -1,
            thumbnailYn: item.isRepresent ? 'Y' : 'N',
            tempProductYn:
              item.product?.openApiFlag || item.product?.tempProductId
                ? 'Y'
                : 'N',
            product_from: item.product.from ?? '',
          })
    })
  }

  @action uploadFileClear = () => {
    this.tempUploadFile = BLANK_TEMP_FILE
  }

  @action getDetailInfo = async (articleId: number) => {
    const res = await articleDetail(articleId)
    runInAction(() => {
      if (res !== null) {
        this.setWholeData(res)
      } else {
        showBottomToast(t('common.error.server'), bottomOffset())
      }
    })
  }

  @action asyncCreateArticle = (mode: FeedMode) => {
    const backGroundPostingTimer = setTimeout(() => {
      UploadStore.start(
        FeedTypeCode.ARTICLE,
        this.wholeData.articleTitle,
        mode === FeedMode.MODIFY
      )
      UploadStore.setAbortController(new AbortController())

      void this.showStartToast()
        .then(() => sleep(200))
        .then(() => this.compressFiles(mode))
        .then(() => UploadStore.clearAbortController())
        .then(() => this.uploadFilesToS3(mode))
        .then(this.createArticle)
        .then(() => {
          if (UploadStore.isFail) throw CustomError(UploadStatus.FAIL)
        })
        .then(() => BadgeStore.obtainBadge(BadgeCode.FEED))
        .then(() => UploadStore.complete())
        .then(this.initList)
        .then(() => this.showCompleteToast(mode))
        .then(this.clear)
        .catch((e) => {
          if (e.name === UploadStatus.STOP || e.name === 'abortVideo') {
            UploadStore.clear()
          } else {
            UploadStore.fail()
          }
        })
        .finally(() => {
          clearTimeout(backGroundPostingTimer)
        })
    }, 500)
  }

  @action compressFiles = async (mode: FeedMode) => {
    this.dataUnion()

    const resultQueue = await UploadStore.compressByQueue(
      this.wholeData.files,
      mode
    )
    //유효성 체크
    UploadStore.validateQueue(resultQueue)
    //최종 데이터 병합
    this.mergeData(resultQueue)
  }

  @action uploadFilesToS3 = async (mode: FeedMode) => {
    if (UploadStore.isStop) throw CustomError(UploadStatus.STOP)

    UploadStore.uploading()
    const resultQueue = await UploadStore.putFileToS3ByQueue(
      this.wholeData.files,
      UploadTypeCode.ARTICLE,
      mode
    )
    //유효성 체크
    UploadStore.validateQueue(resultQueue)
    //최종 데이터 병합
    this.mergeData(resultQueue)
    //서버 전송 데이터 포맷으로 변경
    this.wholeDataForSaveCall = JSON.parse(JSON.stringify(this.wholeData))
  }

  @action createArticle = async () => {
    if (UploadStore.isStop) {
      throw CustomError(UploadStatus.STOP)
    }

    await saveArticle(this.wholeDataForSaveCall)
      .then((res) => {
        if (res.successOrNot === SuccessOrNot.Y && res.statusCode === 'OK') {
          const data: ArticleWriteResponse = res.data as ArticleWriteResponse
          if (data?.articleId !== undefined && data?.articleId > 0) {
            this.clear()
            this.wholeDataForSaveCall = ARTICLE_BLANK
          } else {
            throw CustomError(UploadStatus.FAIL)
          }
        } else {
          throw CustomError(UploadStatus.FAIL)
        }
      })
      .catch((e) => {
        if (e.name === UploadStatus.FAIL) UploadStore.fail()
      })
  }

  showStartToast = () => {
    return new Promise((resolve, reject) => {
      if (!UploadStore.isDefaultStatus) {
        showBottomToast(t('screen.article.message.registering'), bottomOffset())
        resolve('success')
      } else {
        reject(CustomError(UploadStatus.FAIL))
      }
    })
  }

  showCompleteToast = (mode: FeedMode) => {
    return new Promise((resolve, reject) => {
      if (UploadStore.status === UploadStatus.COMPLETE) {
        if (mode === FeedMode.WRITE) {
          showBottomToast(
            t('screen.article.message.registered'),
            bottomOffset()
          )
        }
        if (mode === FeedMode.MODIFY) {
          CommonStore.setToastOption({
            show: true,
            message1: t('screen.article.message.modified'),
          })
        }
        resolve('success')
      } else {
        reject(CustomError(UploadStatus.FAIL))
      }
    })
  }

  initList = () => {
    if (UploadStore.status === UploadStatus.COMPLETE) {
      void ArticleDetailStore.getArticleDetail(
        this.wholeDataForSaveCall.articleId as number
      )
    }
  }

  mergeData = (resultQueue: UploadFile[]) => {
    //최종 데이터 병합
    this.wholeData.files.forEach((prevFile) => {
      for (const compressFile of resultQueue) {
        if (
          prevFile.fileType === FileType.IMAGE &&
          prevFile.imageLocalFilePath === compressFile.imageLocalFilePath
        ) {
          prevFile = compressFile
        }
        if (
          prevFile.fileType === FileType.VIDEO &&
          prevFile.videoLocalFilePath === compressFile.videoLocalFilePath
        ) {
          prevFile = compressFile
        }
      }
    })
  }
}

export default new ArticleWriteStore()
