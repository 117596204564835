import { Navigate, createBrowserRouter } from "react-router-dom";
import LoginAttractScreen from "./screens/auth/LoginAttractScreen";
import LoginPresenter from "./screens/login/LoginPresenter";
import JoinWithEmailScreen from "./screens/join/JoinWithEmailScreen";
import FindEmail from "./screens/login/FindEmail";
import PasswordReset from "./screens/password/PasswordReset";
import QnAList from "./screens/qna/QnaListScreen";
import PreferenceScreen from "./screens/preference/PreferenceScreen";
import ProfileSetting from "./screens/profile/ProfileSetting";
import ProfileUpdate from "./screens/profile/ProfileUpdate";
import ABListScreen from "./screens/ab/AbListScreen";
import App from "./App";
import NotificationScreen from "./screens/notification/NotificationScreen";
import MissionDetailScreen from "./screens/mission/MissionDetailScreen";
import AccountScreen from "./screens/account/AccountScreen";
import InfoAddAgree from "./screens/join/InfoAddAgree";
import LongTermUnusedScreen from "./screens/join/LongtermUnusedScreen";
import MagazineMain from "./screens/magazine/presenter/MagazineMain";
import MagazineDetailScreen from "./screens/magazine/MagazineDetailScreen";
import { LikeScreen } from "./screens/like";
import QnaDetailScreen from "./screens/qna/QnaDetailScreen";
import CommentScreen from "./screens/comment/CommentScreen";
import FAQ from "./screens/setting/faq/FAQ";
import FeedScreen from "./screens/feed/FeedScreen";
import PostDetailScreen from "./screens/post/PostDetailScreen";
import AbDetailScreen from "./screens/ab/AbDetailScreen";
import SearchScreen from "./screens/search/SearchScreen";
import FollowersScreen from "./screens/follower/FollowersScreen";
import Auth from "./screens/auth/Auth";
import React from "react";
import MissionScreen from "./screens/mission/MissionScreen";
import PostScreen from "./screens/post/PostScreen";
import HomeScreen from "./screens/home/HomeScreen";
import QnaScreen from "./screens/qna/QnaScreen";
import MemberRecoveryScreen from "./screens/login/MemberRecoveryScreen";
import DormantMember from "./screens/login/DormantMember";
import VoltInfoScreen from "./screens/volt/VoltInfoScreen";
import Setting from "./screens/setting/SettingScreen";
import TermsSettingScreen from "./screens/setting/TermsSettingScreen";
import AlarmScreen from "./screens/setting/alarm/AlarmScreen";
import InquiryWrite from "./screens/setting/inquiry/InquiryWrite";
import Notice from "./screens/setting/notice/Notice";
import NoticeDetail from "./screens/setting/notice/NoticeDetail";
import Qa from "./screens/setting/qa/Qa";
import QaDetail from "./screens/setting/qa/QaDetail";
import UserScreen from "./screens/setting/user/UserScreen";
import WithdrawAgree from "./screens/setting/user/presenter/WithdrawAgree";
import WithdrawReason from "./screens/setting/user/presenter/WithdrawReason";
import QnaWriteScreen from "./screens/qna/QnaWriteScreen";
import ProductSearchScreen from "./screens/product/ProductSearchScreen";
import ProductAddScreen from "./screens/product/ProductAddScreen";
import QnaModifyScreen from "./screens/qna/QnaModifyScreen";
import BookmarkScreen from "./screens/bookmark/BookmarkScreen";
import BadgeScreen from "./screens/badge/BadgeScreen";
import TagScreen from "./screens/tag/TagScreen";
import RelatedProductScreen from "./screens/product/RelatedProductScreen";
import SearchTotal from "./screens/search/presenter/SearchTotal";
import SearchPost from "./screens/search/presenter/SearchPost";
import SearchProduct from "./screens/search/presenter/SearchProduct";
import SearchPoster from "./screens/search/presenter/SearchPoster";
import SearchQna from "./screens/search/presenter/SearchQna";
import SearchTag from "./screens/search/presenter/SearchTag";
import PostWriteScreen from "./screens/post/PostWriteScreen";
import PostModifyScreen from "./screens/post/PostModifyScreen";
import FilterTypeScreen from "./screens/filter/FilterTypeScreen";
import CouponListScreen from "./screens/coupon/CouponListScreen";
import CouponDetailScreen from "./screens/coupon/CouponDetailScreen";

import FriendRecommendScreen from "./screens/recommender/mission/FriendRecommendScreen";

export interface RouterInfo {
  path: string;
  element: React.ReactNode;
  routeName: string; //actHistory에 보낼때 사용할 이름 (element 이름과 동일하게)
  options?: {
    loginAccess?: boolean; // 로그인 시 접근 가능
    nonLoginAccess?: boolean; // 비 로그인 시 접근 가능
    showWriteBtn?: boolean; // 작성 버튼(+) 노출 여부
    hasBottomBar?: boolean; // 하단 바 존재 여부 (네비게이션, 댓글 입력, 상세 기능 등)
  };
}

export const routerInfo: RouterInfo[] = [
  {
    path: "/",
    element: <HomeScreen />,
    routeName: "HomeScreen",
    options: {
      showWriteBtn: true,
      hasBottomBar: true,
    },
  },
  {
    path: "/Main",
    element: <HomeScreen />,
    routeName: "HomeScreen",
    options: {
      showWriteBtn: true,
      hasBottomBar: true,
    },
  },
  {
    path: "/posts",
    element: <PostScreen />,
    routeName: "PostScreen",
    options: {
      showWriteBtn: true,
      hasBottomBar: true,
    },
  },
  {
    path: "/PostScreen",
    element: <PostScreen />,
    routeName: "PostScreen",
    options: {
      showWriteBtn: true,
      hasBottomBar: true,
    },
  },
  {
    path: "/QnA",
    element: <QnaScreen />,
    routeName: "QnaScreen",
    options: {
      showWriteBtn: true,
      hasBottomBar: true,
    },
  },
  {
    path: "/Mission",
    element: <MissionScreen />,
    routeName: "MissionScreen",
    options: {
      hasBottomBar: true,
    },
  },
  {
    path: "/QnAList",
    element: <QnAList />,
    routeName: "QnAList",
    options: {
      showWriteBtn: true,
    },
  },
  {
    path: "/PreferenceScreen",
    element: <PreferenceScreen />,
    routeName: "PreferenceScreen",
    options: {
      nonLoginAccess: true,
    },
  },
  {
    path: "/ProfileSetting",
    element: <ProfileSetting />,
    routeName: "ProfileSetting",
  },
  {
    path: "/ProfileUpdate",
    element: <ProfileUpdate />,
    routeName: "ProfileUpdate",
  },
  {
    path: "/ABList",
    element: <ABListScreen />,
    routeName: "ABListScreen",
    options: {
      showWriteBtn: true,
    },
  },
  {
    path: "/NotificationScreen",
    element: <NotificationScreen />,
    routeName: "NotificationScreen",
    options: {
      loginAccess: true,
    },
  },
  {
    path: "/MissionDetail",
    element: <MissionDetailScreen />,
    routeName: "MissionDetailScreen",
    options: {
      hasBottomBar: true,
    },
  },
  {
    path: "/mission/:missionId",
    element: <MissionDetailScreen />,
    routeName: "MissionDetailScreen",
  },
  {
    path: "/MyHome",
    element: <AccountScreen />,
    routeName: "AccountScreen",
    options: {
      loginAccess: true,
      showWriteBtn: true,
    },
  },
  {
    path: "/user/:nickname",
    element: <AccountScreen />,
    routeName: "AccountScreen",
    options: {
      loginAccess: true,
      showWriteBtn: true,
    },
  },
  {
    path: "/LoginAttractScreen",
    element: <LoginAttractScreen />,
    routeName: "LoginAttractScreen",
    options: {
      nonLoginAccess: true,
    },
  },
  {
    path: "/LoginPresenter",
    element: <LoginPresenter />,
    routeName: "LoginPresenter",
    options: {
      nonLoginAccess: true,
    },
  },
  {
    path: "/JoinWithEmailScreen",
    element: <JoinWithEmailScreen />,
    routeName: "JoinWithEmailScreen",
    options: {
      nonLoginAccess: true,
    },
  },
  {
    path: "/FindEmail",
    element: <FindEmail />,
    routeName: "FindEmail",
    options: {
      nonLoginAccess: true,
    },
  },
  {
    path: "/MyVoltHistory",
    element: <VoltInfoScreen />,
    routeName: "VoltInfoScreen",
  },
  {
    path: "/PasswordReset",
    element: <PasswordReset />,
    routeName: "PasswordReset",
  },
  {
    path: "/InfoAddAgree",
    element: <InfoAddAgree />,
    routeName: "InfoAddAgree",
    options: {
      nonLoginAccess: true,
    },
  },
  {
    path: "/LongTermUnusedScreen",
    element: <LongTermUnusedScreen />,
    routeName: "LongTermUnusedScreen",
  },
  {
    path: "/magazine",
    element: <MagazineMain />,
    routeName: "MagazineMain",
  },
  {
    path: "/MagazineScreen",
    element: <MagazineMain />,
    routeName: "MagazineMain",
  },
  {
    path: "/magazine/:feedId",
    element: <MagazineDetailScreen />,
    routeName: "MagazineDetailScreen",
    options: {
      hasBottomBar: true,
    },
  },
  {
    path: "/MagazineDetailScreen",
    element: <MagazineDetailScreen />,
    routeName: "MagazineDetailScreen",
    options: {
      hasBottomBar: true,
    },
  },
  {
    path: "/FAQ",
    element: <FAQ />,
    routeName: "FAQ",
  },
  {
    path: "/QnA/:feedId",
    element: <QnaDetailScreen />,
    routeName: "QnaDetailScreen",
    options: {
      hasBottomBar: true,
    },
  },
  {
    path: "/QnA/write",
    element: <QnaWriteScreen />,
    routeName: "QnaWriteScreen",
    options: {
      loginAccess: true,
    },
  },
  {
    path: "/QnA/Modify/:feedId",
    element: <QnaModifyScreen />,
    routeName: "QnaModifyScreen",
    options: {
      loginAccess: true,
    },
  },
  {
    path: "/:feedType/:feedId/like",
    element: <LikeScreen />,
    routeName: "LikeScreen",
  },
  {
    path: "/LikeScreen",
    element: <LikeScreen />,
    routeName: "LikeScreen",
  },
  {
    path: "/:feedType/:feedId/comment",
    element: <CommentScreen />,
    routeName: "CommentScreen",
    options: {
      hasBottomBar: true,
    },
  },
  {
    path: "/AB/:feedId",
    element: <AbDetailScreen />,
    routeName: "AbDetailScreen",
    options: {
      hasBottomBar: true,
    },
  },
  {
    path: "/posts/feed",
    element: <FeedScreen />,
    routeName: "FeedScreen",
  },
  {
    path: "/FeedScreen",
    element: <FeedScreen />,
    routeName: "FeedScreen",
  },
  {
    path: "/posts/feed/:id",
    element: <PostDetailScreen />,
    routeName: "PostDetailScreen",
    options: {
      hasBottomBar: true,
    },
  },
  {
    path: "/PostDetailScreen",
    element: <PostDetailScreen />,
    routeName: "PostDetailScreen",
    options: {
      hasBottomBar: true,
    },
  },
  {
    path: "/Search",
    element: <SearchScreen />,
    routeName: "SearchScreen",
  },
  {
    path: "/Search/:keyword/total",
    element: <SearchTotal />,
    routeName: "SearchTotal",
  },
  {
    path: "/Search/:keyword/post",
    element: <SearchPost />,
    routeName: "SearchPost",
  },
  {
    path: "/Search/:keyword/product",
    element: <SearchProduct />,
    routeName: "SearchProduct",
  },
  {
    path: "/Search/:keyword/poster",
    element: <SearchPoster />,
    routeName: "SearchPoster",
  },
  {
    path: "/Search/:keyword/qna",
    element: <SearchQna />,
    routeName: "SearchQna",
  },
  {
    path: "/Search/:keyword/tag",
    element: <SearchTag />,
    routeName: "SearchTag",
  },
  {
    path: "/Follow",
    element: <FollowersScreen />,
    routeName: "FollowersScreen",
  },
  {
    path: "/auth",
    element: <Auth />,
    routeName: "Auth",
  },
  {
    path: "/setting",
    element: <Setting />,
    routeName: "Setting",
  },
  {
    path: "/setting/terms",
    element: <TermsSettingScreen />,
    routeName: "TermsSettingScreen",
  },
  {
    path: "/setting/alarm",
    element: <AlarmScreen />,
    routeName: "AlarmScreen",
  },
  {
    path: "/notice",
    element: <Notice />,
    routeName: "Notice",
  },
  {
    path: "/notice/:noticeId",
    element: <NoticeDetail />,
    routeName: "NoticeDetail",
  },
  {
    path: "/setting/user",
    element: <UserScreen />,
    routeName: "UserScreen",
  },
  {
    path: "/withdraw/agree",
    element: <WithdrawAgree />,
    routeName: "WithdrawAgree",
  },
  {
    path: "/withdraw/reason",
    element: <WithdrawReason />,
    routeName: "WithdrawReason",
  },
  {
    path: "/qa",
    element: <Qa />,
    routeName: "Qa",
  },
  {
    path: "/qa/:qaId",
    element: <QaDetail />,
    routeName: "QaDetail",
  },
  {
    path: "/qa/write",
    element: <InquiryWrite />,
    routeName: "InquiryWrite",
  },
  {
    path: "/MemberRecovery",
    element: <MemberRecoveryScreen />,
    routeName: "MemberRecoveryScreen",
  },
  {
    path: "/DormantMember",
    element: <DormantMember />,
    routeName: "DormantMember",
  },
  {
    path: "/Product/Search",
    element: <ProductSearchScreen />,
    routeName: "ProductSearchScreen",
  },
  {
    path: "/Product/Add",
    element: <ProductAddScreen />,
    routeName: "ProductAddScreen",
  },
  {
    path: "/Bookmark",
    element: <BookmarkScreen />,
    routeName: "BookmarkScreen",
    options: {
      loginAccess: true,
    },
  },
  {
    path: "/Badge",
    element: <BadgeScreen />,
    routeName: "BadgeScreen",
    options: {
      loginAccess: true,
    },
  },
  {
    path: "/tag/:tagId",
    element: <TagScreen />,
    routeName: "TagScreen",
    options: {
      showWriteBtn: true,
      loginAccess: true,
    },
  },
  {
    path: "/products/:productId/relates",
    element: <RelatedProductScreen />,
    routeName: "RelatedProductScreen",
    options: {
      showWriteBtn: true,
    },
  },
  {
    path: "/post/write",
    element: <PostWriteScreen />,
    routeName: "PostWriteScreen",
    options: {
      loginAccess: true,
    },
  },
  {
    path: "/post/modify",
    element: <PostModifyScreen />,
    routeName: "PostModifyScreen",
    options: {
      loginAccess: true,
    },
  },
  {
    path: "/filter",
    element: <FilterTypeScreen />,
    routeName: "FilterTypeScreen",
  },
  {
    path: "/coupon",
    element: <CouponListScreen />,
    routeName: "CouponListScreen",
  },
  {
    path: "/coupon/detail",
    element: <CouponDetailScreen />,
    routeName: "CouponDetailScreen",
  },
  {
    path: "/FriendRecommend",
    element: <FriendRecommendScreen />,
    routeName: "FriendRecommendScreen",
  },
];

const router = createBrowserRouter(
  routerInfo.map((router) => {
    return {
      errorElement: <Navigate to="/" />,
      path: router.path,
      element: (
        <App routeName={router.routeName} options={router.options}>
          {router.element}
        </App>
      ),
    };
  })
);

export default router;
