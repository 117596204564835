import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from "mobx";
import { sendActHistory } from "../api/data/api";
import { ActHistoryRequest } from "../api/data/model";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../constants/ActHistory";
import AuthStore from "./AuthStore";
import { NotificationModalProps } from "../components/modals/NotificationModal";
import { ShareInfo } from "../utils/share";
// import { navigationRef, reset } from "../navigation/NavigationFunction";
import { track } from "../hooks/tracker/TrackFunction";
import { goTo } from "../hooks/navigate/NavigateFunction";
// import { cache } from "../utils/cache";

export interface ShowInAppBrowserOption {
  url: string;
}

export interface ToastOption {
  show: boolean;
  type?: string;
  message1?: string;
  message2?: string;
  bottomOffset?: number;
  autoHide?: boolean;
  durationTime?: number;
  keyboardHeight?: number;
  onPress?: () => void;
}

export const routeNameList = [
  "HomeScreen",
  "QnaScreen",
  "QnaDetailScreen",
  "PostScreen",
  "PostDetailScreen",
  "PostWriteScreen",
  "MissionScreen",
  "CommentScreen",
  "LoginScreen",
  "FindEmailScreen",
  "LoginAttractScreen",
  "PreferenceScreen",
  "JoinWithEmailScreen",
  "PasswordReset",
  "InfoAddAgreeScreen",
  "ProfileSetting",
  "UserScreen",
  "AccountScreen",
  "ProfileUpdate",
  "Setting",
  "TermsSettingScreen",
  "ServiceTermsScreen",
  "FAQ",
  "Qa",
  "QaDetail",
  "InquiryWrite",
  "TagManagementScreen",
  "BookmarkScreen",
  "Notice",
  "NoticeDetail",
  "FollowersScreen",
  "PostModifyScreen",
  "AbWriteScreen",
  "QnaWriteScreen",
  "QnaModifyScreen",
  "AbDetailScreen",
  "AbListScreen",
  "SearchScreen",
  "AlarmScreen",
  "NotificationScreen",
  "ProductSearchScreen",
  "ProductAddScreen",
  "ProductDetailScreen",
  "RelatedProductScreen",
  "QnaListScreen",
  "LikeScreen",
  "Preference",
  "MissionDetailScreen",
  "MagazineScreen",
  "RankingScreen",
  "TagScreen",
  "DormantMember",
  "WithdrawalMember",
  "BadgeScreen",
  "CouponListScreen",
  "CouponDetailScreen",
  "MagazineDetailScreen",
  "WithdrawReason",
  "FeedScreen",
  "RecommenderScreen",
  "WithdrawAgree",
  "MyAppliancesScreen",
  "MyApplianceDeleteScreen",
  "FilterTypeScreen",
  "BrandCommunityArticleListScreen",
  "BrandCommunityArticleSearchScreen",
  "BrandCommunityProductListScreen",
  "BrandCommunityMain",
  "BrandCommunityIntroduction",
  "BrandCommunityHome",
  "BrandCommunityListScreen",
  "MallCategoryScreen",
  "LargeCategoryScreen",
  "MediumCategoryScreen",
  "SmallCategoryScreen",
  "ArticleWriteScreen",
  "ArticleDetailScreen",
  "ArticleModifyScreen",
  "MallMainScreen",
  "VoltInfoScreen",
  "FriendRecommendScreen",
  "LifecareScreen",
  "LongTermUnusedScreen",
];

class CommonStore {
  constructor() {
    makeObservable(this);
  }

  @observable _keyboardHeight = 0;
  @observable _widthApp = 450;
  @observable _offsetLeftApp = 0;
  @observable _loading = false;
  @observable _toastOption: ToastOption = {
    show: false,
    keyboardHeight: 0,
  };
  @observable _topButtonTimer: NodeJS.Timeout | undefined;

  @observable _currentRouteName = "";
  @observable _fixedCurrentRouteName = "";
  @observable _fixedPreviousRouteName = "";
  @observable _isScrollToBrandPopularArticle = false;
  @observable beforeHeightOfScroll = 0;
  @observable topButtonOption: {
    isShow: boolean;
    ref: any;
    onTopPress?: () => void;
  } = {
    isShow: false,
    ref: null,
  };
  @observable _isShowMainTooltip1 = false;
  @observable _isShowMainTooltip2 = false;
  @observable _isShowMallMainTooltip1 = false;
  @observable _isShowMallMainTooltip2 = false;
  @observable _isShowShareBottomSheet = false;
  @observable _isShowDownloadAppDrive = false;
  @observable _isShowAppInstallSideBanner = false;

  _currentShareInfo: ShareInfo | undefined;

  @computed get widthApp() {
    return this._widthApp;
  }

  @computed get offsetLeftApp() {
    return this._offsetLeftApp;
  }

  @computed get currentShareInfo() {
    return this._currentShareInfo;
  }

  @computed get isShowShareBottomSheet() {
    return this._isShowShareBottomSheet;
  }

  @computed get loading() {
    return this._loading;
  }

  @computed get toastOption() {
    return this._toastOption;
  }

  @computed get keyboardHeight() {
    return this._keyboardHeight;
  }

  @computed get currentRouteName() {
    return this._currentRouteName;
  }

  @computed get fixedCurrentRouteName() {
    return this._fixedCurrentRouteName;
  }

  @computed get fixedPreviousRouteName() {
    return this._fixedPreviousRouteName;
  }

  @computed get isScrollToBrandPopularArticle() {
    return this._isScrollToBrandPopularArticle;
  }

  @action setWidthApp = (data: number) => {
    this._widthApp = data;
  };

  @action setOffsetLeftApp = (data: number) => {
    this._offsetLeftApp = data;
  };

  @action setCurrentShareInfo = (data: ShareInfo) => {
    this._currentShareInfo = data;
  };

  @action setShowShareBottomSheet = (show: boolean) => {
    this._isShowShareBottomSheet = show;
  };
  @computed get isShowDownloadAppDrive() {
    return this._isShowDownloadAppDrive;
  }

  @computed get isShowAppInstallSideBanner() {
    return this._isShowAppInstallSideBanner;
  }

  @action setLoading = (isLoading: boolean) => {
    this._loading = isLoading;
  };

  @action setToastOption = ({ ...option }: ToastOption) => {
    this._toastOption = { ...option, keyboardHeight: this._keyboardHeight };
  };

  @action setKeyboardHeight = (keyboardHeight: number) => {
    this._keyboardHeight = keyboardHeight;
  };

  @action setKeyboardHeightZero = () => {
    if (this._toastOption.show) {
      this._toastOption = toJS(this._toastOption);
    }
    this._keyboardHeight = 0;
    this._toastOption.keyboardHeight = 0;
  };

  @action clearToastOption = () => {
    this._toastOption = { show: false, keyboardHeight: 0 };
  };

  @action setShowDownloadAppDrive(show: boolean) {
    this._isShowDownloadAppDrive = show;
  }

  @action setIsShowAppInstallSideBanner(show: boolean) {
    this._isShowAppInstallSideBanner = show;
  }

  @action callActHistory = (
    historyCode: ActHistoryTypeCode,
    targetId: string,
    pageCode: ActPageCode | null,
    sectionCode?: ActSectionCode,
    attr1?: string,
    attr2?: string,
    attr3?: string,
    attr4?: string,
    attr5?: string,
    attr6?: string
  ) => {
    const actHistory: ActHistoryRequest = {
      actHistoryTypeCode: historyCode,
      actHistoryTargetId: targetId,
      actPageCode: pageCode,
      actSectionCode: sectionCode,
      actMemberUuid:
        AuthStore.sessionUser?.memberUuid ||
        AuthStore.tempMember?.tempMemberUUID ||
        "",
      attr1: attr1,
      attr2: attr2,
      attr3: attr3,
      attr4: attr4,
      attr5: attr5,
      attr6: attr6,
    };
    void sendActHistory(actHistory);
  };

  restMainAfterLogin(loginType: string) {
    //complete_login
    //logging : 최종 로그인 후 메인으로 화면으로 이동하기 위해 실행됨
    //loginType 에 따라 로그인 로깅 처리 (SocialTypeCode로 구분함)
    track("complete_login", {
      method: loginType,
    });
  }

  resetMainAfterSignUp(loginType: string) {
    //logging : 회원가입 후 메인으로 이동하기 위해 실행.
    //loginType 에 따라 로그인 로깅 처리 (SocialTypeCode로 구분함)
    track("complete_signup", {
      method: loginType,
    });
    goTo("/", { replace: true });
  }

  //[4925] 상단이동 버튼 노출 정의 수정
  @action startTopButtonTimer = (event: () => void) => {
    if (this._topButtonTimer) {
      clearTimeout(this._topButtonTimer);
    }

    this._topButtonTimer = setTimeout(() => {
      runInAction(() => {
        event();
        this._topButtonTimer = undefined;
      });
    }, 1500);
  };

  @action setCurrentRouteName = (x: string) => {
    this._currentRouteName = x;
  };

  @action setFixedCurrentRouteName = (x: string) => {
    // if (routeNameList.indexOf(x) >= 0) {
    this._fixedCurrentRouteName = x;
    // }
  };

  @action setFixedPreviousRouteName = (x: string) => {
    // if (routeNameList.indexOf(x) >= 0) {
    this._fixedPreviousRouteName = x;
    // }
  };

  @action setIsScrollToBrandPopularArticle = (x: boolean) => {
    this._isScrollToBrandPopularArticle = x;
  };

  @action resetTopButtonOption = () => {
    this.topButtonOption = {
      isShow: false,
      ref: null,
    };
  };

  @action setShowTopButtonOption = (x: boolean) => {
    this.topButtonOption.isShow = x;
  };

  @computed get isShowMainTooltip1() {
    return this._isShowMainTooltip1;
  }
  @computed get isShowMainTooltip2() {
    return this._isShowMainTooltip2;
  }
  @computed get isShowMallMainTooltip1() {
    return this._isShowMallMainTooltip1;
  }
  @computed get isShowMallMainTooltip2() {
    return this._isShowMallMainTooltip2;
  }

  @action setIsShowMainTooltip1 = (x: boolean) => {
    this._isShowMainTooltip1 = x;
  };
  @action setIsShowMainTooltip2 = (x: boolean) => {
    this._isShowMainTooltip2 = x;
  };
  @action setIsShowMallMainTooltip1 = (x: boolean) => {
    this._isShowMallMainTooltip1 = x;
  };
  @action setIsShowMallMainTooltip2 = (x: boolean) => {
    this._isShowMallMainTooltip2 = x;
  };

  @action disabledMainTooltip = () => {
    if (this._isShowMainTooltip1) {
      this.setIsShowMainTooltip1(false);
    }
    if (this._isShowMainTooltip2) {
      this.setIsShowMainTooltip2(false);
      // void cache.set("showMainTooltip2", "SUCCESS");
    }
  };
  @action disabledMallMainTooltip = () => {
    if (this._isShowMallMainTooltip1) {
      this.setIsShowMallMainTooltip1(false);
    }
    if (this._isShowMallMainTooltip2) {
      this.setIsShowMallMainTooltip2(false);
      // void cache.set("showMallMainTooltip2", "SUCCESS");
    }
  };

  @observable _notificationModalProps: NotificationModalProps = {
    isVisible: false,
  };

  @action resetNotificationModalProps = () => {
    this._notificationModalProps = { isVisible: false };
  };
  @action setNotificationModalProps = (props: NotificationModalProps) => {
    this._notificationModalProps = props;
  };

  @computed get notificationModalProps() {
    return this._notificationModalProps;
  }
}
export default new CommonStore();
