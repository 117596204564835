import React, { useCallback, useEffect, useRef } from "react";
import {
  Arranges,
  FlexBoxs,
  Positions,
  Spacings,
  Texts,
} from "../../../assets/styles";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { ReactComponent as EmptyImg } from "../../../assets/icons/empty_appliances.svg";
import MyAppliancesStore, {
  MyAppliancesList,
} from "../../../store/MyAppliancesStore";
import { handleTopButtonScroll } from "../../../utils/common";
import { MyAppliancesItem } from "./MyAppliancesItem";
import { MyAppliancesItemForProduct } from "./MyAppliancesItemForProduct";
import { InfiniteScrollObserver } from "../../../components/common/InfiniteScrollObserver";

const MyAppliancesFlatList = observer(
  ({
    from,
    mode,
    tempSize,
  }: {
    from: string;
    mode: string;
    tempSize: number;
  }) => {
    const { t } = useTranslation();
    const ref = useRef(null);

    const { editNum, handleTotalCheck } = MyAppliancesStore;

    useEffect(() => {
      return () => {
        handleTotalCheck(false);
      };
    }, [handleTotalCheck]);

    const renderItem = useCallback(
      (item: any, index: number) => {
        if (from === "product") {
          return (
            <MyAppliancesItemForProduct
              item={item}
              mode={mode}
              index={index}
              tempSize={tempSize}
            />
          );
        } else {
          return (
            <MyAppliancesItem
              item={item}
              index={index}
              mode={mode}
              isRadioSelected={editNum === item.idx}
            />
          );
        }
      },
      [from, mode, editNum, tempSize]
    );

    const renderEmpty = () => {
      return (
        <div className="empty">
          <div style={{ flex: 1 }}>
            <EmptyImg />
            <div className="empty_view">
              <p className="empty_text">
                {t("screen.productAdd.mssage.emptyMyAppliances")}
              </p>
            </div>
          </div>
        </div>
      );
    };

    const handleMoreRequest = useCallback(() => {
      if (
        MyAppliancesStore.myAppliancesList &&
        MyAppliancesStore.myAppliancesList?.length <
          MyAppliancesStore.totalCount
      ) {
        void MyAppliancesStore.getMyProduct(true);
      }
    }, [MyAppliancesStore.myAppliancesList, MyAppliancesStore.getMyProduct]);

    return (
      <div
        style={{
          padding: "0 16px",
          display: "flex",
          flexDirection: "column",
          rowGap: 4,
        }}
        ref={ref}
        aria-label="Product search result list"
      >
        {MyAppliancesStore.myAppliancesList ? (
          <>
            {MyAppliancesStore.myAppliancesList?.map((item, index) =>
              renderItem(item, index)
            )}
            <InfiniteScrollObserver onObserver={handleMoreRequest} />
          </>
        ) : (
          renderEmpty()
        )}
      </div>
    );
  }
);

export default MyAppliancesFlatList;
