// import { kakaoShareLink } from "../native/kakaoLogin/native";

import { MissionShareRequest } from '../api/mission/model'
import { ActHistoryTypeCode, ActPageCode } from '../constants/ActHistory'
import { Platform, PlatformTypes } from '../native/platform'
import CommonStore from '../store/CommonStore'
import MissionStore from '../store/MissionStore'

export interface ActHistoryData {
  actHistoryTypeCode: ActHistoryTypeCode
  actPageCode: ActPageCode
  feedId: string
  index?: string
  likeCount: string
  commentCount: string
  postCount?: string
  qnaCount?: string
}

const callActHistory = (
  activityType: string,
  actHistoryData?: ActHistoryData
) => {
  if (!actHistoryData) {
    return
  }

  CommonStore.callActHistory(
    actHistoryData.actHistoryTypeCode,
    actHistoryData.feedId,
    actHistoryData.actPageCode,
    undefined,
    actHistoryData.index,
    activityType,
    actHistoryData.likeCount,
    actHistoryData.commentCount,
    actHistoryData.postCount,
    actHistoryData.qnaCount
  )
}

// /**
//  * w : 오상익.
//  * URL 공유하기의 경우 화면에는 노출되지않는 React hook exception 발생해서 토스트 팝업 삭제처리.
//  * 공유하기 os 팝업올라오고 팝업 외 화면 눌러서 팝업이 닫힌 경우에도 불필요하게 토스트팝업이 올라오도록 되어있어 삭제함.
//  */
export interface ShareInfo {
  title?: string
  descriptionText?: string
  path?: string
  imageUrl?: string
  screen?: string
  targetId?: number // 게시물 번호로 사용하는 항목
  target?: string // 라이프케어의 경우 redirectUrl을 넘기기 위해 사용
}

export interface KakaoLinkInfo extends ShareInfo {
  deeplinkImageUrl?: string
  buttonName?: string
  nickName?: string
  imgWidth?: number
  imgHeight?: number
}

export const onShareLifecare = async (
  info?: ShareInfo,
  actHistoryData?: ActHistoryData
) => {
  onShare(info, actHistoryData)
}

const isWebShareSupport = () => {
  switch (Platform.OS) {
    case PlatformTypes.ANDROID_APP:
    case PlatformTypes.IOS_APP:
    case PlatformTypes.ANDROID_WEB:
    case PlatformTypes.IOS_WEB:
      return true
    case PlatformTypes.WINDOWS_WEB:
    case PlatformTypes.MACOS_WEB:
    case PlatformTypes.WEB:
      return false
  }
}

export const onShare = (info?: ShareInfo, actHistoryData?: ActHistoryData) => {
  if (!info) return
  if (navigator.share && isWebShareSupport()) {
    // 모바일 OS 의 자체 공유기능 사용
    // 데스크톱에서도 일부 사용이 가능하나 브라우저별로 호환성이 영 좋지 못하다
    const data: ShareData = {
      url: `${window.location.protocol}//${window.location.host}/${info.path}`,
      title: info.title?.substring(0, 100),
      text: info.descriptionText?.substring(0, 100),
    }
    void navigator.share(data)
  } else {
    // 자체적으로 공유 지원되지 않는 브라우저 (웹)
    CommonStore.setCurrentShareInfo(info)
    CommonStore.setShowShareBottomSheet(true)
  }
  callActHistory('sharedAction', actHistoryData)

  // TODO: 추후에 네이티브와 url 공유 가능할 시에 사용
  // const imageUrl = info.imageUrl
  //   ? process.env.REACT_NATIVE_PUBLIC_BUCKET_URL + encodeURI(info.imageUrl)
  //   : ''
  // const param: DeepLinkData = {
  //   feature: actHistoryData?.actPageCode,
  //   channel: Platform.OS,
  //   campaign: info.title,
  //   data: {
  //     $og_image_url: imageUrl,
  //     $og_description: info.descriptionText?.substring(0, 100),
  //     $og_title: info.title?.substring(0, 100),
  //     screen: info.screen,
  //     targetId: info.targetId?.toString(),
  //   },
  // }
  // branch.link(param, (error, link) => {
  //   if (error) {
  //     return
  //   }
  //   info.linkUrl = link || ''
  //   const data: ShareData = {
  //     url: info.linkUrl,
  //     title: info.title?.substring(0, 100),
  //     text: info.descriptionText?.substring(0, 100),
  //   }

  //   if (navigator.share) {
  //     // 모바일 OS 의 자체 공유기능 사용
  //     void navigator.share(data).then(() => {
  //       callActHistory('sharedAction', actHistoryData)
  //     })
  //   } else {
  //     // 자체적으로 공유 지원되지 않는 브라우저 (웹)
  //     CommonStore.setCurrentShareInfo(info)
  //     CommonStore.setShowShareBottomSheet(true)
  //   }
  // })
}

export const onShareByKakao = (info: ShareInfo) => {
  const domain = `${window.location.protocol}//${window.location.host}`
  Kakao.Share.sendCustom({
    templateId: parseInt(process.env.REACT_APP_KAKAO_SHARE_TEMPLATE_ID || '0'),
    templateArgs: {
      domain: encodeURI(domain),
      path: encodeURI(info.path || ''),
      title: info.title,
      description: info.descriptionText,
      image_url: info.imageUrl
        ? process.env.REACT_APP_PUBLIC_BUCKET_URL + encodeURI(info.imageUrl)
        : '',
      button_name: '220 Code & Code 시작하기',
    },
  })
}

// export const onNicknameKakaoShareLinkWithBranch = async (
//   info?: KakaoLinkInfo,
//   nickname?: string,
//   missionShareRequest?: MissionShareRequest
// ) => {
//   if (!info || !info.screen) return;

//   if (!nickname) return;

//   const buo = await branch.createBranchUniversalObject(`nickname/${info.targetId?.toString() || ""}`, {
//     title: info.title?.substring(0, 100) || "",
//     contentDescription: info.descriptionText?.substring(0, 100) || "",
//     contentImageUrl: info.imageUrl ? Config.REACT_NATIVE_PUBLIC_BUCKET_URL + encodeURI(info.imageUrl) : "",
//     contentMetadata: {
//       customMetadata: {
//         screen: info.screen,
//         targetId: info.targetId?.toString() || "",
//         nickname: nickname ?? "",
//       },
//     },
//   });

//   const linkProperties = {
//     feature: "sharing",
//     channel: "app",
//     campaign: "share_nickname",
//   };

//   const controlParams = {};

//   try {
//     const { url } = await buo.generateShortUrl(linkProperties, controlParams);

//     const info2: KakaoLinkInfo = {
//       title: info.title,
//       descriptionText: info.descriptionText,
//       imageUrl: info.imageUrl,
//       screen: info.screen,
//       targetId: info.targetId,
//       deeplinkImageUrl: info.imageUrl ? Config.REACT_NATIVE_PUBLIC_BUCKET_URL + encodeURI(info.imageUrl) : "",
//       buttonName: info.buttonName ? info.buttonName : "220 Code & Code 시작하기",
//       imgHeight: "500",
//       imgWidth: "800",
//     };

//     await kakaoShareLink(info2, url);

//     if (missionShareRequest) {
//       MissionStore.updateClickCount({
//         missionId: missionShareRequest.missionId,
//         missionTypeCode: missionShareRequest.missionTypeCode,
//         shareType: missionShareRequest.shareType,
//       });
//     }
//   } catch (error) {
//     return;
//   }
// };

// export const onNicknameShareLinkWithBranch = async (
//   info?: ShareInfo,
//   nickname?: string,
//   missionShareRequest?: MissionShareRequest,
//   actHistoryData?: ActHistoryData
// ) => {
//   if (!info || !info.screen) return;
//   if (!nickname) return;
//   if (!missionShareRequest) return;

//   const title = info.title?.substring(0, 100);

//   const buo = await branch.createBranchUniversalObject(`nickname/${info.targetId?.toString() || ""}`, {
//     title: info.title?.substring(0, 100) || "",
//     contentDescription: info.descriptionText?.substring(0, 100) || "",
//     contentImageUrl: info.imageUrl ? Config.REACT_NATIVE_PUBLIC_BUCKET_URL + encodeURI(info.imageUrl) : "",
//     contentMetadata: {
//       customMetadata: {
//         screen: info.screen,
//         targetId: info.targetId?.toString() || "",
//         nickname: nickname ?? "",
//       },
//     },
//   });

//   const linkProperties = {
//     feature: "sharing",
//     channel: "app",
//     campaign: "share_nickname",
//   };

//   const controlParams = {};

//   try {
//     const { url } = await buo.generateShortUrl(linkProperties, controlParams);

//     const result = await Share.share({
//       message: String(title) + "\n" + String(url),
//     });

//     if (result.action === Share.sharedAction) {
//       if (actHistoryData && result.activityType) {
//         // TODO: activityType 에 맞추어 처리
//         callActHistory(result.activityType, actHistoryData);
//       }

//       if (missionShareRequest) {
//         MissionStore.updateClickCount({
//           missionId: missionShareRequest.missionId,
//           missionTypeCode: missionShareRequest.missionTypeCode,
//           shareType: missionShareRequest.shareType,
//         });
//       }
//     }
//   } catch (error: any) {
//     return;
//   }
// };

export const onNicknameShareLink = async (
  info?: ShareInfo,
  nickname?: string,
  missionShareRequest?: MissionShareRequest,
  actHistoryData?: ActHistoryData
) => {
  if (!info) return
  if (!nickname) return

  if (navigator.share) {
    // 모바일 OS 의 자체 공유기능 사용
    // 데스크톱에서도 일부 사용이 가능하나 브라우저별로 호환성이 영 좋지 못하다
    const data: ShareData = {
      url: `${window.location.protocol}//${window.location.host}`,
      // title: info.title?.substring(0, 100),
      // text: info.descriptionText?.substring(0, 100),
      // ios 에서는 title 영역이 노출되지 않음
      text: `${info.title?.substring(
        0,
        100
      )}\n${info.descriptionText?.substring(0, 100)}`,
    }
    void navigator.share(data)

    if (actHistoryData) {
      callActHistory('sharedAction', actHistoryData)
    }
    if (missionShareRequest) {
      MissionStore.updateClickCount({
        missionId: missionShareRequest.missionId,
        missionTypeCode: missionShareRequest.missionTypeCode,
        shareType: missionShareRequest.shareType,
      })
    }
  } else {
    // 자체적으로 공유 지원되지 않는 브라우저 (웹)
    CommonStore.setCurrentShareInfo(info)
    CommonStore.setShowShareBottomSheet(true)
  }
}

export const onNicknameKakaoShareLink = async (
  info?: KakaoLinkInfo,
  nickname?: string,
  missionShareRequest?: MissionShareRequest
) => {
  if (!info || !info.screen) return
  if (!nickname) return

  const domain = `${window.location.protocol}//${window.location.host}`
  Kakao.Share.sendCustom({
    templateId: parseInt(process.env.REACT_APP_KAKAO_SHARE_TEMPLATE_ID || '0'),
    templateArgs: {
      domain: encodeURI(domain),
      // path: `user/${encodeURI(nickname)}`,
      title: info.title?.substring(0, 100) || '',
      description: info.descriptionText?.substring(0, 100) || '',
      image_url: info.imageUrl
        ? process.env.REACT_APP_PUBLIC_BUCKET_URL + encodeURI(info.imageUrl)
        : '',
      screen: info.screen,
      target_id: info.targetId,
      button_name: info.buttonName
        ? info.buttonName
        : '220 Code & Code 시작하기',
      image_width: info.imgWidth,
      // img_height: "500",
      // img_width: "800",
    },
  })

  if (missionShareRequest) {
    MissionStore.updateClickCount({
      missionId: missionShareRequest.missionId,
      missionTypeCode: missionShareRequest.missionTypeCode,
      shareType: missionShareRequest.shareType,
    })
  }
}

export const onShareByCustomLink = async (url: string) => {
  if (navigator.share) {
    void navigator.share({ url })
  } else {
    // 공유 지원되지 않는 브라우저
    if (navigator.clipboard) {
      navigator.clipboard.writeText(url)
    }
  }
}
