import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from "mobx";
import { UploadFile } from "../api/file/model";
import { FeedFileForUploadS3 } from "../api/post/model";
import {
  BLANK_TEMP_FILE,
  FeedMode,
  POST_BLANK_FILE,
  UploadTypeCode,
} from "../constants/Feed";
import { FileType } from "../constants/File";
import { PRODUCT_SEARCH_WORD } from "../constants/Storage";
import {
  AlbumMulitResponse,
  callNativeOnlyPhotoAlbum,
  ImageForUI,
} from "../utils/album";
import CommonStore from "./CommonStore";
import { t } from "i18next";
import {
  createMyProduct,
  createNProduct,
  createProduct,
  getMyProductDetail,
  updateProduct,
} from "../api/product/api";
import { NewProduct, TempProduct } from "../api/product/model";
import { BLANK_TEMP_PRODUCT, PRODUCT_SEARCH_COUNT } from "../constants/Product";
import { getAutoComplete, getSearchResult } from "../api/search/api";
import { SearchItem, SearchResultResponse } from "../api/search/model";
import AuthStore from "./AuthStore";
import {
  AutoCompleteWhat,
  SearchSortType,
  SearchTabMenu,
} from "../constants/Search";
import { CommaAddInTextInput } from "../utils/number";
import { bottomOffset } from "../utils/common";
import { CreateType, PurchaseCountryType } from "../constants/Common.enum";
import { isValidDate, onChangeTextForDateYYYYMMDD } from "../utils/datetime";
import { track } from "../hooks/tracker/TrackFunction";
import CommentStore from "./CommentStore";
import QnaWriteStore from "./QnaWriteStore";
import ArticleWriteStore from "./ArticleWriteStore";
import PostWriteStore from "./PostWriteStore";
import PostModifyStore from "./PostModifyStore";
import UploadStore from "./upload/UploadStore";
import { showBottomToast } from "../utils/Toast";

export interface ProductForWrite {
  product: NewProduct;
  isRepresent: boolean;
}

class ProductSearchAddStore {
  constructor() {
    makeObservable(this);
  }

  @observable errorMessage?: string;
  @observable toastMessage?: string;
  @observable keyword? = "";
  @observable recentKeywordList: string[] = [];
  @observable filterRecentList: string[] = [];
  @observable autoCompleteList: string[] = [];
  @observable searchResult: SearchResultResponse<SearchItem> | null = null;
  @observable showWord = false;
  @observable showResult = false;
  @observable refreshing = false;
  @observable loading = false;
  @observable _myProductFileList: UploadFile[] = [POST_BLANK_FILE];
  @observable _myReceiptFileList: UploadFile[] = [POST_BLANK_FILE];
  @observable fileUpdated = false;
  @observable writingProduct: TempProduct = BLANK_TEMP_PRODUCT;
  @observable selectedProduct: NewProduct | null = null;
  @observable selectedMyProduct: NewProduct | null = null;

  @observable selectedSearchProductMap: Map<string, SearchItem> = new Map<
    string,
    SearchItem
  >();

  @observable searchPageIndex = 0;
  @observable loadingInList = false;
  @observable feedMode =
    FeedMode.MODIFY |
    FeedMode.WRITE |
    FeedMode.QNA_WRITE |
    FeedMode.QNA_MODIFY |
    FeedMode.ARTICLE_MODIFY |
    FeedMode.ARTICLE_WRITE;
  @observable brandCommunityProductList = false;

  @observable _tempProductList: ProductForWrite[] = [];

  @computed get searchFeedMode() {
    return this.feedMode;
  }
  @computed get myProductFileList() {
    return this._myProductFileList;
  }

  @computed get myReceiptFileList() {
    return this._myReceiptFileList;
  }

  @computed get selectedNewProduct() {
    return this.selectedProduct;
  }

  @computed get tempProductList() {
    return this._tempProductList;
  }

  @computed get searchSearchProductMap() {
    return this.selectedSearchProductMap;
  }

  @computed get hasError() {
    return this.errorMessage !== undefined && this.errorMessage.length > 0;
  }

  @computed get isValid() {
    return this.isValidProductNames && this.isImageRegComplete;
  }

  @computed get isValidProductNames() {
    return (
      this.writingProduct.manufacturerName?.trim().length > 0 &&
      this.writingProduct.productModelName?.trim().length > 0 &&
      this.writingProduct.productName?.trim().length > 0
    );
  }

  @computed get isImageRegComplete() {
    return (
      this.myProductFileList &&
      this.myProductFileList.findIndex((u) => u.fileName !== "") > -1
    );
  }

  @computed get isValidDate() {
    if (this.writingProduct.purchaseDatetime) {
      return isValidDate(this.writingProduct.purchaseDatetime);
    } else {
      return false;
    }
  }

  @computed get selectedSearchProductCnt() {
    if (this.selectedSearchProductMap?.values()) {
      return Array.from(this.selectedSearchProductMap?.values()).length;
    } else {
      return 0;
    }
  }

  @action createNewProductObj = (value: SearchItem) => {
    return {
      thumbnailFilePath: value.thumbnailFilePath,
      productId: value.productId,
      productName: value.productName || "",
      manufacturerName: value.brandNameKor || "",
      originalFilePath: value?.thumbnailFilePath?.replace(
        "THUMBNAIL",
        "ORIGINAL"
      ),
      productModelName: value.productModelName,
      openApiFlag: value.openApiFlag,
      lprice: value.lprice,
      hprice: value.hprice,
      myProductId: value.myProductId,
      category1: value.category1,
      category2: value.category2,
      category3: value.category3,
      productUrl: value.productUrl,
    } as NewProduct;
  };

  @action myProductFilesSplice = (blankIndex: number) => {
    return this._myProductFileList.splice(blankIndex, 1);
  };

  @action addMyProductFiles = (item: UploadFile) => {
    return this._myProductFileList.push(item);
  };

  @action setFeedMode = (mode: FeedMode) => {
    this.feedMode = mode;
  };

  @action setBrandCommunityProductList = (value: boolean) => {
    this.brandCommunityProductList = value;
  };

  @action setError = (message?: string) => {
    this.errorMessage = message;
  };

  @action setToast = (message?: string) => {
    this.toastMessage = message;
  };

  @action clear = (leaveSearchResult?: boolean) => {
    if (!leaveSearchResult) {
      this.searchResult = null;
      this.showResult = false;
      this.keyword = "";
    }
    this.errorMessage = "";
    this.toastMessage = "";
    this.recentKeywordList = [];
    this.autoCompleteList = [];
    this.filterRecentList = [];
    this.showWord = false;
    this.refreshing = false;
    this.loading = false;
    this._myProductFileList = [POST_BLANK_FILE];
    this._myReceiptFileList = [POST_BLANK_FILE];
    this.lastUploadIndex = 0;
    this.callIndex = 0;
    this.wholeDataForSaveCall = BLANK_TEMP_PRODUCT;
    this.writingProduct = BLANK_TEMP_PRODUCT;
    this.selectedProduct = null;
    this.selectedMyProduct = null;
    this.searchPageIndex = 0;
    this.loadingInList = false;
    this.brandCommunityProductList = false;
    this.uploadFileClear();
    this._tempProductList = [];
    this.selectedSearchProductMap?.clear();
  };

  @action showValidToast = () => {
    if (!this.writingProduct.manufacturerName) {
      this.setToast(t("screen.productAdd.message.inputBrand"));
      return;
    }
    if (!this.writingProduct.productName) {
      this.setToast(t("screen.productAdd.message.inputProductName"));
      return;
    }
    if (!this.writingProduct.productModelName) {
      this.setToast(t("screen.productAdd.message.inputModel"));
    }
  };

  @action setShowWord = (isVisible: boolean) => {
    this.showWord = isVisible;
  };

  @action setShowResult = (isVisible: boolean) => {
    this.showResult = isVisible;
  };

  @action setRefreshing = (ing: boolean) => {
    this.refreshing = ing;
  };

  @action setLoading = (ing: boolean) => {
    this.loading = ing;
  };

  @action setLoadingInList = (ing: boolean) => {
    this.loadingInList = ing;
  };

  @action setKeyword = (word?: string) => {
    this.keyword = word;
    if (word) {
      this.filteringRecentKeyword(word);
      void this.getAutoCompleteWord(word);
    }
  };

  @action getRecentWord = () => {
    const result = window.localStorage.getItem(PRODUCT_SEARCH_WORD);
    if (result) {
      this.recentKeywordList = JSON.parse(result) as string[];
    }
  };

  @action setRecentWord = async (word: string) => {
    const alreadyExist = this.recentKeywordList.findIndex(
      (text: string) => word === text
    );
    if (alreadyExist > -1) this.recentKeywordList.splice(alreadyExist, 1);
    if (this.recentKeywordList.length > 5) {
      this.recentKeywordList.splice(5, this.recentKeywordList.length);
    }
    this.recentKeywordList.splice(0, 0, word);
    window.localStorage.setItem(
      PRODUCT_SEARCH_WORD,
      JSON.stringify(this.recentKeywordList)
    );
  };

  @action filteringRecentKeyword = (word: string) => {
    this.filterRecentList =
      this.recentKeywordList.filter(
        (text) => text.toLowerCase().indexOf(word.toLowerCase()) > -1
      ) || [];
  };

  @action getAutoCompleteWord = async (word: string) => {
    const result =
      word.length > 0
        ? await getAutoComplete(word, AutoCompleteWhat.PRODUCT)
        : [];
    this.setAutoCompleteWord(result);
  };

  @action setAutoCompleteWord = (result: string[]) => {
    this.autoCompleteList = result;
  };

  @action getSearchResult = (
    word: string,
    pageIndex: number,
    sortType?: SearchSortType
  ) => {
    this.searchPageIndex = pageIndex;
    this.setLoading(true);
    if (pageIndex === 0) this.searchResult = null;

    getSearchResult(
      SearchTabMenu.Product,
      word,
      PRODUCT_SEARCH_COUNT,
      pageIndex,
      sortType || SearchSortType.LATEST,
      AuthStore.sessionUser?.memberUuid === undefined ||
        AuthStore.sessionUser?.memberUuid === null ||
        AuthStore.sessionUser?.memberUuid?.length < 1
        ? AuthStore.tempMember?.tempMemberUUID
        : undefined,
      SearchTabMenu.Product === "product"
    )
      .then((res) => {
        runInAction(() => {
          this.setLoading(false);
          this.setRefreshing(false);
          this.setLoadingInList(false);
          this.setSearchResult(res);
        });
      })
      .catch((err) => {
        this.setLoading(false);
        this.setRefreshing(false);
        this.setToast(t("common.message.unknownException"));
      });
  };

  @action setSearchResult = (res: SearchResultResponse<SearchItem>) => {
    if (this.searchPageIndex === 0) this.searchResult = res;
    else {
      if (this.searchResult?.list && res.list) {
        this.searchResult?.list.push(...res.list);
      }
    }
  };

  @action setSelectedProduct = (value: SearchItem) => {
    if (this.selectedSearchProductMap?.values()) {
      const newProductsCnt = Array.from(
        this.selectedSearchProductMap?.values()
      ).length;
      if (this.tempProductList.length + newProductsCnt >= 5) {
        showBottomToast(t("screen.feedProduct.message.max5Count"));
        return false;
      }
    }

    //중복 체크
    let dupleCnt = 0;
    if (value.openApiFlag) {
      //네이버 중복 체크
      dupleCnt =
        this.tempProductList.filter((temp) => {
          if (temp.product.tempProductId) {
            return temp.product.tempProductId === value.myProductId;
          }
          if (temp.product.nproductId) {
            return temp.product.nproductId === value.productId;
          }
          return false;
        }).length ?? 0;
    } else {
      //220제품 중복 체크
      dupleCnt =
        this.tempProductList.filter(
          (temp) => temp.product.productId === value.productId
        ).length ?? 0;
    }

    if (dupleCnt > 0) {
      showBottomToast(
        t("screen.feedProduct.message.alreadyAdd"),
        bottomOffset()
      );
      return false;
    }

    this.addSelectedProduct(value);
    return true;
  };

  @action initTempProductListByType = () => {
    let tempProductList: ProductForWrite[] = [];

    if (
      this.searchFeedMode === FeedMode.QNA_MODIFY ||
      this.searchFeedMode === FeedMode.QNA_WRITE
    ) {
      tempProductList = QnaWriteStore.tempProductList;
    }
    if (
      this.searchFeedMode === FeedMode.ARTICLE_MODIFY ||
      this.searchFeedMode === FeedMode.ARTICLE_WRITE
    ) {
      tempProductList = ArticleWriteStore.tempProductList;
    }
    if (this.searchFeedMode === FeedMode.WRITE) {
      tempProductList = PostWriteStore.tempProductList;
    }
    if (this.searchFeedMode === FeedMode.MODIFY) {
      tempProductList = PostModifyStore.tempProductList;
    }
    if (this.searchFeedMode === FeedMode.COMMENT_QNA) {
      tempProductList = CommentStore.tempProductList;
    }

    this.initTempProductList(tempProductList);
  };

  @action setModel = (value: string) => {
    this.writingProduct.productModelName = value;
  };

  @action setCompany = (value: string) => {
    this.writingProduct.manufacturerName = value;
  };

  @action setProductName = (value: string) => {
    this.writingProduct.productName = value;
  };

  @action setPrice = (value: string) => {
    this.writingProduct.priceForScreen = CommaAddInTextInput(value);
  };

  /* @action setPriceNumber = () => {
    if (this.writingProduct.priceForScreen) {
      const priceInNumber = this.writingProduct.priceForScreen?.replace(/,/g, "");
      if (priceInNumber) {
        this.writingProduct.price = Number(priceInNumber);
      }
    }
  };*/

  @action setPurchaseCountry = (value: string) => {
    let country;
    if (value === PurchaseCountryType.DOMESTIC) {
      country = PurchaseCountryType.DOMESTIC;
    }
    if (value === PurchaseCountryType.OVERSEAS) {
      country = PurchaseCountryType.OVERSEAS;
    }
    this.writingProduct.purchaseCountry = country;
  };

  @action setPurchasePlace = (value: string) => {
    this.writingProduct.purchasePlace = value;
  };

  @action setPurchaseDateTime = (value: string) => {
    this.writingProduct.purchaseDatetime = onChangeTextForDateYYYYMMDD(value);
  };

  @action showNativeAlbum = async (mode?: CreateType) => {
    CommonStore.setLoading(true);

    let fileLength = 0;
    this.myProductFileList.forEach((item) => {
      if (item.fileSize >= 0) fileLength = fileLength + 1;
    });

    let w = 0;
    let h = 0;
    if (this.myProductFileList.length > 0) {
      const f = this.myProductFileList[0];

      if (f.fileResolution != null) {
        const strR = f.fileResolution.split("x");
        if (strR.length === 2) {
          const firstW = Number(strR[0]);
          const firstH = Number(strR[1]);
          w = firstW;
          h = firstH;
        }
      }
    }

    const res = (await callNativeOnlyPhotoAlbum(
      10 - fileLength,
      "PRODUCTSEARCH",
      w,
      h
    )) as AlbumMulitResponse;
    const files: ImageForUI[] = res.param.list;
    if (files.length > 0) {
      const blankIndex = this.myProductFileList.findIndex(
        (i) => i.fileSize < 0
      );
      if (blankIndex > -1) this.myProductFilesSplice(blankIndex);
    }

    files.forEach((item) => {
      const sp = item.fileName.split(".");
      const uploadFile = {
        originalFileName: item.fileName,
        fileName: sp[0],
        filePath: item.base64,
        imageOriginLocalFilePath:
          item.type.toLocaleUpperCase() === "IMAGE" ? item.fileUri : "",
        fileResolution: item.resThumbX + "x" + item.resThumbY,
        imageLocalFilePath:
          item.type.toLocaleUpperCase() === "IMAGE" ? item.fileUri : "",
        thumbnailTempPath: item.thumbnailImagepath,
        fileSize: Number(item.fileSize),
        fileType: item.type.toLocaleUpperCase(),
        fileExtension: sp[sp.length - 1],
        thumbnailYn: "N",
        modified: mode === CreateType.MODIFY,
      };

      if (this.myProductFileList.length < 10) {
        this.addMyProductFiles(uploadFile);
      }
    });

    this.checkAddedFiles();
    CommonStore.setLoading(false);
  };

  @action showNativeAlbumForReceipt = async (mode?: CreateType) => {
    CommonStore.setLoading(true);
    let fileLength = 0;

    this.myReceiptFileList.forEach((item) => {
      if (item.fileSize >= 0) fileLength = fileLength + 1;
    });

    let w = 0;
    let h = 0;
    if (this.myReceiptFileList.length > 0) {
      const f = this.myReceiptFileList[0];

      if (f.fileResolution != null) {
        const strR = f.fileResolution.split("x");
        if (strR.length === 2) {
          const firstW = Number(strR[0]);
          const firstH = Number(strR[1]);
          w = firstW;
          h = firstH;
        }
      }
    }

    const res = (await callNativeOnlyPhotoAlbum(
      10 - fileLength,
      "PRODUCTSEARCH",
      w,
      h
    )) as AlbumMulitResponse;
    const files: ImageForUI[] = res.param.list;
    if (files.length > 0) {
      const blankIndex = this.myReceiptFileList.findIndex(
        (i) => i.fileSize < 0
      );
      if (blankIndex > -1) this.myReceiptFileList.splice(blankIndex, 1);
    }

    files.forEach((item) => {
      const sp = item.fileName.split(".");
      const uploadFile = {
        originalFileName: item.fileName,
        fileName: sp[0],
        filePath: item.base64,
        imageOriginLocalFilePath:
          item.type.toLocaleUpperCase() === "IMAGE" ? item.fileUri : "",
        fileResolution: item.resThumbX + "x" + item.resThumbY,
        imageLocalFilePath:
          item.type.toLocaleUpperCase() === "IMAGE" ? item.fileUri : "",
        thumbnailTempPath: item.thumbnailImagepath,
        fileSize: Number(item.fileSize),
        fileType: item.type.toLocaleUpperCase(),
        fileExtension: sp[sp.length - 1],
        thumbnailYn: "N",
        modified: mode === CreateType.MODIFY,
      };

      if (this.myReceiptFileList.length < 10) {
        this.myReceiptFileList.push(uploadFile);
      }
    });

    this.checkAddedFilesForReceipt();
    CommonStore.setLoading(false);
  };

  @action checkAddedFiles = () => {
    if (this.myProductFileList.length < 10)
      this.myProductFileList.push(POST_BLANK_FILE);
    this.fileUpdated = !this.fileUpdated;
  };

  @action checkAddedFilesForReceipt = () => {
    if (this.myReceiptFileList.length < 10)
      this.myReceiptFileList.push(POST_BLANK_FILE);
    this.fileUpdated = !this.fileUpdated;
  };

  @action deleteFile = (fileIndex: number, type?: string) => {
    if (type === "receipt") {
      this.myReceiptFileList?.splice(fileIndex, 1);
      if (
        this.myReceiptFileList?.length < 10 &&
        this.myReceiptFileList?.findIndex(
          (item) => item.filePath === "" && item.fileSize < 0
        ) < 0
      )
        this.myReceiptFileList.push(POST_BLANK_FILE);

      this.fileUpdated = !this.fileUpdated;
    } else {
      this.myProductFileList?.splice(fileIndex, 1);
      if (
        this.myProductFileList?.length < 10 &&
        this.myProductFileList?.findIndex(
          (item) => item.filePath === "" && item.fileSize < 0
        ) < 0
      )
        this.myProductFileList.push(POST_BLANK_FILE);

      this.fileUpdated = !this.fileUpdated;
    }
  };

  checkPurchaseDatetime = () => {
    if (
      this.wholeDataForSaveCall.purchaseDatetime != null &&
      this.wholeDataForSaveCall.purchaseDatetime.trim().length === 0
    )
      this.wholeDataForSaveCall["purchaseDatetime"] = null;
  };

  @action createProduct = async () => {
    this.checkPurchaseDatetime();
    const res = await createProduct(this.wholeDataForSaveCall);

    let complete = false;
    CommonStore.setLoading(false);
    runInAction(() => {
      if (res?.tempProductId && res?.tempProductId > 0) {
        track("complete_regist_my_product", {
          manufacture: this.wholeDataForSaveCall.manufacturerName,
          product_name: this.wholeDataForSaveCall.productName,
          product_model_name: this.wholeDataForSaveCall.modelName,
          product_image_count: this.wholeDataForSaveCall.myProductFiles?.length,
          purchase_price: this.wholeDataForSaveCall.purchasePrice,
          purchase_country: this.wholeDataForSaveCall.purchaseCountry,
          purchase_place: this.wholeDataForSaveCall.purchasePlace,
          purchase_date: this.wholeDataForSaveCall.purchaseDatetime,
          purchase_receipt_count:
            this.wholeDataForSaveCall.myReceiptFiles?.length,
          category1: this.wholeDataForSaveCall.category1,
          category2: this.wholeDataForSaveCall.category2,
          category3: this.wholeDataForSaveCall.category3,
          productUrl: this.wholeDataForSaveCall.productUrl,
        });
        this.selectedProduct = res;
        this.selectedProduct.productModelName =
          this.wholeDataForSaveCall.productModelName;
        complete = true;
      } else {
        this.setError(t("common.error.server"));
        complete = false;
      }
      this.wholeDataForSaveCall = BLANK_TEMP_PRODUCT;
    });
    return complete;
  };

  @action createMyProduct = async () => {
    this.checkPurchaseDatetime();
    const res = await createMyProduct(this.wholeDataForSaveCall);
    CommonStore.setLoading(false);

    if (res && res.successOrNot === "Y") {
      track("complete_regist_my_product", {
        manufacture: this.wholeDataForSaveCall.manufacturerName,
        product_name: this.wholeDataForSaveCall.productName,
        product_model_name: this.wholeDataForSaveCall.modelName,
        product_image_count: this.wholeDataForSaveCall.myProductFiles?.length,
        purchase_price: this.wholeDataForSaveCall.purchasePrice,
        purchase_country: this.wholeDataForSaveCall.purchaseCountry,
        purchase_place: this.wholeDataForSaveCall.purchasePlace,
        purchase_date: this.wholeDataForSaveCall.purchaseDatetime,
        purchase_receipt_count:
          this.wholeDataForSaveCall.myReceiptFiles?.length,
        category1: this.wholeDataForSaveCall.category1,
        category2: this.wholeDataForSaveCall.category2,
        category3: this.wholeDataForSaveCall.category3,
        productUrl: this.wholeDataForSaveCall.productUrl,
      });
      return true;
    } else {
      showBottomToast(t("내 가전 등록에 실패했습니다."));
      return false;
    }
    // this.wholeDataForSaveCall = BLANK_TEMP_PRODUCT
  };

  @action updateProduct = async () => {
    this.checkPurchaseDatetime();
    const res = await updateProduct(this.wholeDataForSaveCall);

    let complete = false;
    CommonStore.setLoading(false);
    runInAction(() => {
      if (res?.statusCode === "OK" && res?.successOrNot === "Y") {
        complete = true;
      } else {
        showBottomToast(t("common.error.server"));
      }
      this.wholeDataForSaveCall = BLANK_TEMP_PRODUCT;
    });
    return complete;
  };

  @action createNProduct = async (item: SearchItem) => {
    const res = await createNProduct(item);
    CommonStore.setLoading(false);
    return res;
  };

  @action dataUnion = () => {
    this.writingProduct.myReceiptFiles = [];
    this.myReceiptFileList.forEach((item) => {
      if (item.fileSize > -1 && item.filePath.length > 0)
        this.writingProduct?.myReceiptFiles?.push(item);
    });

    this.writingProduct.myProductFiles = [];
    this.myProductFileList.forEach((item) => {
      if (item.fileSize > -1 && item.filePath.length > 0)
        this.writingProduct?.myProductFiles?.push(item);
    });
  };

  @observable lastUploadIndex = 0;
  @observable callIndex = 0;
  @observable tempUploadFile: FeedFileForUploadS3 = BLANK_TEMP_FILE;
  @observable wholeDataForSaveCall: TempProduct = BLANK_TEMP_PRODUCT;

  @action setWriteProduct = (searchItem: SearchItem) => {
    //제품상세, 가전등록(검색)에서 제품 찍고 들어오는 경우
    if (searchItem) {
      if (searchItem.openApiFlag) {
        this.writingProduct.nproductId = searchItem.productId ?? 0;
        this.writingProduct.thumbnailFilePath =
          searchItem.thumbnailFilePath ?? "";
        this.writingProduct.category1 = searchItem.category1 ?? "";
        this.writingProduct.category2 = searchItem.category2 ?? "";
        this.writingProduct.category3 = searchItem.category3 ?? "";
        this.writingProduct.productUrl = searchItem.productUrl ?? "";
      } else {
        this.writingProduct.productId = searchItem.productId ?? 0;
      }
      this.writingProduct.productName = searchItem.productName ?? "";
      this.writingProduct.manufacturerName = searchItem.brandNameKor ?? "";
      this.writingProduct.modelName = searchItem.productModelName;
      this.writingProduct.productModelName = searchItem.productModelName ?? "";
    }
  };

  @action createNewMyProduct = async () => {
    UploadStore.setAbortController(new AbortController());

    return await this.compressFiles()
      .then(() => this.uploadFilesToS3())
      .then(this.createMyProduct);
  };

  @action createNewProduct = async () => {
    UploadStore.setAbortController(new AbortController());

    return await this.compressFiles()
      .then(() => this.uploadFilesToS3())
      .then(this.createProduct);
  };

  @action updateMyProduct = async () => {
    UploadStore.setAbortController(new AbortController());

    return await this.compressFiles(FeedMode.MODIFY)
      .then(() => this.uploadFilesToS3(FeedMode.MODIFY))
      .then(this.updateProduct);
  };

  @action compressFiles = async (mode?: FeedMode) => {
    this.dataUnion();

    const compressFiles = [];
    if (
      this.writingProduct.myProductFiles &&
      this.writingProduct.myProductFiles.length > 0
    ) {
      compressFiles.push(...this.writingProduct.myProductFiles);
      const resultQueue = await UploadStore.compressByQueue(
        compressFiles,
        mode
      );
      UploadStore.validateQueue(resultQueue);
      this.mergeData(this.writingProduct.myProductFiles, resultQueue);
    }

    if (
      this.writingProduct.myReceiptFiles &&
      this.writingProduct.myReceiptFiles.length > 0
    ) {
      compressFiles.push(...this.writingProduct.myReceiptFiles);
      const resultQueue = await UploadStore.compressByQueue(
        compressFiles,
        mode
      );
      UploadStore.validateQueue(resultQueue);
      this.mergeData(this.writingProduct.myReceiptFiles, resultQueue);
    }
  };

  @action uploadFilesToS3 = async (mode?: FeedMode) => {
    if (
      this.writingProduct.myProductFiles &&
      this.writingProduct.myProductFiles.length > 0
    ) {
      const resultQueue = await UploadStore.putFileToS3ByQueue(
        this.writingProduct.myProductFiles,
        UploadTypeCode.NEW_PRODUCT,
        mode
      );
      UploadStore.validateQueue(resultQueue);
      this.mergeData(this.writingProduct.myProductFiles, resultQueue);
    }

    if (
      this.writingProduct.myReceiptFiles &&
      this.writingProduct.myReceiptFiles.length > 0
    ) {
      const resultQueue = await UploadStore.putFileToS3ByQueue(
        this.writingProduct.myReceiptFiles,
        UploadTypeCode.NEW_PRODUCT,
        mode
      );
      UploadStore.validateQueue(resultQueue);
      this.mergeData(this.writingProduct.myReceiptFiles, resultQueue);
    }
    this.wholeDataForSaveCall = JSON.parse(JSON.stringify(this.writingProduct));
  };

  mergeData = (
    targetFiles: UploadFile[] | undefined,
    resultQueue: UploadFile[],
    type?: string
  ) => {
    //최종 데이터 병합
    if (targetFiles) {
      targetFiles.forEach((prevFile) => {
        for (const compressFile of resultQueue) {
          this.setCompressFileResult(prevFile, compressFile);
        }
      });
    }
  };

  setCompressFileResult = (prevFile: UploadFile, compressFile: UploadFile) => {
    if (
      prevFile.fileType === FileType.IMAGE &&
      prevFile.imageLocalFilePath === compressFile.imageLocalFilePath
    ) {
      prevFile = compressFile;
    }
    if (
      prevFile.fileType === FileType.VIDEO &&
      prevFile.videoLocalFilePath === compressFile.videoLocalFilePath
    ) {
      prevFile = compressFile;
    }
  };

  @action uploadFileClear = () => {
    this.tempUploadFile = BLANK_TEMP_FILE;
  };

  @action addSelectedProduct = (item: SearchItem) => {
    if (!item.productId) {
      return;
    }

    let key;
    //네이버에서 내려준 제품
    if (item.openApiFlag) {
      key = `naver_${item.productId}`;
    } else {
      key = `220_${item.productId}`;
    }
    this.selectedSearchProductMap?.set(key, item);
  };

  @action removeSelectedProduct = (key: string) => {
    this.selectedSearchProductMap?.delete(key);
  };

  @action initTempProductList = (tempProductList: ProductForWrite[]) => {
    this._tempProductList = toJS(tempProductList);
  };

  @action getMyProductDetail = async (
    productId: number,
    productFlag: boolean
  ) => {
    const response = await getMyProductDetail(productId, productFlag);

    this.writingProduct.productId = response.productId;
    this.writingProduct.tempProductId = response.tempProductId;
    this.writingProduct.productName = response.productName;
    this.writingProduct.purchaseCountry = response.purchaseCountry;
    this.writingProduct.purchasePlace = response.purchasePlace ?? "";
    this.writingProduct.purchaseDatetime = response.purchaseDatetime ?? null;
    this.writingProduct.manufacturerName = response.manufacturerName;

    this.writingProduct.productModelName = response.modelName ?? "";
    this.writingProduct.priceForScreen = response.purchasePrice
      ? CommaAddInTextInput(String(response.purchasePrice))
      : "";
    this.writingProduct.price = response.purchasePrice ?? 0;

    if (response.myProductFiles) {
      this._myProductFileList = response.myProductFiles;
      this.myProductFileList.push(POST_BLANK_FILE);
    }
    if (response.myReceiptFiles) {
      this._myReceiptFileList = response.myReceiptFiles;
      this.myReceiptFileList.push(POST_BLANK_FILE);
    }

    if (response.fileGroupId) {
      this.writingProduct.fileGroupId = response.fileGroupId;
    }
    if (response.receiptFileGroupId) {
      this.writingProduct.receiptFileGroupId = response.receiptFileGroupId;
    }
  };

  @action requestPreProcessing = () => {
    if (this.writingProduct.priceForScreen) {
      const price = this.writingProduct.priceForScreen.replace(/,/g, "");
      this.writingProduct.purchasePrice = Number(price);
    }
    if (
      !this.myProductFileList ||
      this.myProductFileList.findIndex((u) => u.fileName !== "") == -1
    ) {
      this.writingProduct.fileGroupId = undefined;
    }
    if (
      !this.myReceiptFileList ||
      this.myReceiptFileList.findIndex((u) => u.fileName !== "") == -1
    ) {
      this.writingProduct.receiptFileGroupId = undefined;
    }
  };
}

export default new ProductSearchAddStore();
