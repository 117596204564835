import React, { useEffect, useRef, useState } from "react";
import "./ProductAdd.scss";

import { MaxLengthTextInput, TitleHeader } from "../../../components/common";
import { HeaderItem } from "../../../constants/Header";

import { ReactComponent as QnaPhoto } from "../../../assets/icons/qna_photo.svg";
import { ReactComponent as QnaPlus } from "../../../assets/icons/qna_plus.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/white_arrow_down.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete_background_opacity.svg";

import { observer } from "mobx-react";
import ProductSearchAddStore from "../../../store/ProductSearchAddStore";
import { NotificationModal } from "../../../components/modals";

import { UploadFile } from "../../../api/file/model";
import CommonStore from "../../../store/CommonStore";
import { BLANK_TEMP_PRODUCT } from "../../../constants/Product";

import MyAppliancesStore from "../../../store/MyAppliancesStore";
import {
  CreateType,
  PurchaseCountryType,
} from "../../../constants/Common.enum";

import { SearchItem } from "../../../api/search/model";
import { showBottomToast } from "../../../utils/Toast";
import { ProductSelectFromEnum } from "../../../constants/product/ProductSelectFrom";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { goBack, goBackTwice } from "../../../hooks/navigate/NavigateFunction";
import useDragScrollWithMouse from "../../../hooks/useDragScrollWithMouse";

const ProductAdd = observer((data: any) => {
  const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;
  const { widthApp } = CommonStore;
  const { t } = useTranslation();

  const myApplianceScreen = data.myApplianceScreen;

  const createType = data.createType as CreateType;
  const screenFrom = data.screenFrom;
  const searchItem = data.searchItem as SearchItem;
  const onComplete = data.onComplete;

  const {
    isImageRegComplete,
    isValidProductNames,
    isValid,
    hasError,
    errorMessage,
    myProductFileList,
    myReceiptFileList,
    fileUpdated,
    brandCommunityProductList,
    writingProduct,
    setError,
    clear,
    showNativeAlbum,
    showNativeAlbumForReceipt,
    deleteFile,
    setModel,
    setCompany,
    setProductName,
    setPrice,
    setPurchaseCountry,
    setPurchasePlace,
  } = ProductSearchAddStore;

  const [showLeavePopup, setShowLeavePopup] = useState(false);
  const [showConfirmPopup, setConfirmPopup] = useState(false);
  const [showDateValidPopup, setShowDateValidPopup] = useState(false);
  const [optionViewLayout, setOptionViewLayout] = useState(0);

  const productNameRef = useRef<HTMLInputElement>(null);
  const manufactureRef = useRef<HTMLInputElement>(null);
  const modelNameRef = useRef<HTMLInputElement>(null);
  const purchaseDateRef = useRef<HTMLInputElement>(null);
  const purchasePlaceRef = useRef<HTMLInputElement>(null);
  const priceRef = useRef<HTMLInputElement>(null);
  const scrollViewRef = useRef<HTMLDivElement>(null);
  const imageListRef = useRef<HTMLDivElement>(null);
  const receiptListRef = useRef<HTMLDivElement>(null);

  const isDragImageList = useDragScrollWithMouse(imageListRef);
  const isDragReceiptImageList = useDragScrollWithMouse(receiptListRef);
  useEffect(() => {
    ProductSearchAddStore.setWriteProduct(searchItem);
  }, [searchItem]);

  const isNew = () => {
    if (createType == CreateType.MODIFY) {
      return false;
    }
    return !searchItem;
  };

  const showProductImgView = () => {
    if (createType == CreateType.NEW) {
      if (screenFrom == "post") {
        return false;
      }
      if (screenFrom !== "post") {
        return true;
      }
    } else {
      return myProductFileList && isImageRegComplete && screenFrom !== "post";
    }
  };

  //포스트 작성 -> 신규 가전 등록
  const isValidInput = () => {
    //마이홈 ,QNA => 신규 등록 케이스
    if (!searchItem && createType === CreateType.NEW && screenFrom !== "post") {
      return isValid;
    }
    //포스트 작성  => 신규 등록 케이스
    if (!searchItem && createType === CreateType.NEW && screenFrom === "post") {
      return isValidProductNames;
    }

    //제품 데이터도 안물고 오고 신규여부도 확인 불가한 케이스(예외처리용)
    if (!searchItem && !createType) {
      return isValid;
    }

    //제품을 찍고 들어오는 경우 (제품 상세 => 내가전등록, 내 가전 => 가전등록 => 제품 선택)
    if (searchItem && createType === CreateType.NEW) {
      return isImageRegComplete;
    }

    return true;
  };

  const checkTempContents = () => {
    if (
      JSON.stringify(writingProduct) !== JSON.stringify(BLANK_TEMP_PRODUCT) ||
      myProductFileList.length > 1
    )
      setShowLeavePopup(true);
    else {
      if (myApplianceScreen || brandCommunityProductList) {
        goBack();
      } else {
        goBackTwice();
      }
    }
  };

  // useBackHandler(() => {
  //   checkTempContents();
  //   return true;
  // });

  const fileRender = (item: UploadFile, index: number) => {
    const isNewAdd = createType !== CreateType.MODIFY;
    const widthItem = (widthApp - 36 * 2 - 8) / 2;

    if (
      index == myProductFileList?.length - 1 &&
      item.fileSize < 0 &&
      isNewAdd
    ) {
      // blank
      return (
          <div
            key={index.toString()}
            className="upload"
            onClick={() => !isDragImageList && void showNativeAlbum(createType)}
            style={{ width: widthItem, height: widthItem }}
            arai-label="Select image"
          >
            <QnaPlus className="qna_plus_icon"/>
            <p>{t("screen.qnaWrite.label.max10_2")}</p>
            <div className="qna_photo_icon_wrap">
              <QnaPhoto />
            </div>
          </div>
      );
    } else {
      // image
      let filePath;
      if (item.thumbnailTempPath) {
        filePath = item.thumbnailTempPath;
      } else {
        filePath = `${PUBLIC_BUCKET_URL}${item.filePath || ""}`;
      }
      return (
        <div
          style={{ width: widthItem }}
          className="img_wrap"
          arai-label={`Selected image ${index}`}
        >
          <div
            className="icon_wrap"
            onClick={() => isNewAdd && deleteFile(index)}
          >
            {isNewAdd && <DeleteIcon />}
          </div>
          <LazyLoadImage
            src={filePath}
            className="images"
          />
        </div>
      );
    }
  };

  const fileRenderReceipt = (item: UploadFile, index: number) => {
    const widthItem = (widthApp - 36 * 2 - 8) / 2;
    if (index == myReceiptFileList?.length - 1 && item.fileSize < 0) {
      // blank
      return (
        <div
          key={index.toString()}
          className="upload"
          onClick={() =>
            !isDragReceiptImageList &&
            void showNativeAlbumForReceipt(createType)
          }
          style={{ width: widthItem, height: widthItem }}
          arai-label="Select image"
        >
          <QnaPlus className="qna_plus_icon" />
          <p>{t("screen.qnaWrite.label.max10_2")}</p>
          <div className="qna_photo_icon_wrap">
            <QnaPhoto />
          </div>
        </div>
      );
    } else {
      // image
      let filePath;
      if (item.thumbnailTempPath) {
        filePath = item.thumbnailTempPath;
      } else {
        filePath = `${PUBLIC_BUCKET_URL}${item.filePath || ""}`;
      }
      return (
        <div
          style={{ width: widthItem, height: widthItem }}
          className="img_wrap"
          arai-label={`Selected image ${index}`}
        >
          <div
            className="icon_wrap"
            onClick={() => deleteFile(index, "receipt")}
          >
            <DeleteIcon />
          </div>
          <LazyLoadImage className="images" src={filePath} />
        </div>
      );
    }
  };

  const scrollToOptionalView = () => {
    window.scrollTo(0, optionViewLayout);
  };

  const save = async () => {
    setConfirmPopup(false);
    const { setLoading } = CommonStore;

    setLoading(true);
    ProductSearchAddStore.requestPreProcessing();

    let isComplete = false;

    try {
      if (createType === CreateType.MODIFY) {
        isComplete = await ProductSearchAddStore.updateMyProduct();
      } else {
        if (searchItem) {
          //내 가전 추가
          isComplete = await ProductSearchAddStore.createNewMyProduct();
        } else {
          //신규 가전 추가
          isComplete = await ProductSearchAddStore.createNewProduct();
        }
      }
    } catch (e) {
      //
    }

    if (!isComplete) {
      return false;
    }

    //포스트 작성 or QNA 작성일 경우
    if (screenFrom == "post" || screenFrom == "qna") {
      if (data?.addProductList) {
        const newProduct = ProductSearchAddStore.selectedNewProduct;
        if (newProduct) {
          newProduct.from = ProductSelectFromEnum.MY;
        }
        data?.addProductList(newProduct);
      }
      goBackTwice();
    }

    //마이홈=>내가전에서 진입시
    if (screenFrom == "my") {
      await MyAppliancesStore.initMyProduct(MyAppliancesStore.currentTab);
      if (createType === CreateType.MODIFY) {
        goBack();
      } else {
        goBackTwice();
      }
    }

    //제품 상세에서 진입시.
    if (screenFrom == "product") {
      if (onComplete) {
        onComplete(true);
      }
      goBack();
    }

    clear();
    setLoading(false);

    setTimeout(() => {
      if (createType == CreateType.NEW) {
        if (screenFrom == "post") {
          showBottomToast(t("screen.productAdd.message.registered"));
        } else {
          showBottomToast(t("screen.productAdd.message.registered_my"));
        }
      } else {
        showBottomToast(t("screen.productAdd.message.modified"));
      }
    }, 100);
  };

  return (
    <>
      <div
        id="product_add"
        // keyboardVerticalOffset={Platform.OS === "ios" ? 0 : statusBarHeight}
        // behavior={Platform.OS == "ios" ? "padding" : undefined}
        style={{ flex: 1, backgroundColor: "#fff" }}
      >
        <TitleHeader
          title={
            isNew() ? t("screen.productAdd.title.new") : "내 가전 정보 등록"
          }
          rightItem={HeaderItem.CLOSE}
          onClickClose={() => checkTempContents()}
          isBack={false}
        />
        <div
          ref={scrollViewRef}
          className="home_app_write"
          // contentContainerStyle={{ paddingBottom: 50 }}
          // showsVerticalScrollIndicator={false}
          // alwaysBounceVertical={false}
          // scrollToOverflowEnabled={true}
          // overScrollMode={"never"}
          // scrollEnabled={true}
        >
          <div className="contents">
            <div style={{ marginBottom: 40 }}>
              <p className="header">
                {t("screen.productAdd.label.myProductRegistrationGuide")}
              </p>
            </div>
            <div className="line_section" />
            <div className="contents_title">
              <p className="contents_title_txt1" arai-label="Required info">
                {t("screen.productAdd.label.required")}
              </p>
            </div>

            {/** essential info **/}
            {!searchItem && (
              <>
                <div
                  className="input_wrap"
                  onClick={() => manufactureRef.current?.focus()}
                  // activeOpacity={1}
                >
                  <p className="label_text">
                    {t("screen.productAdd.label.manufacture")}
                  </p>
                  <MaxLengthTextInput
                    arai-label="Manufacture name"
                    editable={createType !== CreateType.MODIFY}
                    ref={manufactureRef}
                    className="input_text"
                    placeholder={t(
                      "screen.productAdd.label.inputBrandPlaceholder"
                    )}
                    placeholderTextColor={"#999"}
                    maxLength={30}
                    value={writingProduct.manufacturerName}
                    onChangeText={(text: string) => setCompany(text)}
                  />
                </div>
                <div
                  className="input_wrap"
                  onClick={() => productNameRef.current?.focus()}
                  // activeOpacity={1}
                >
                  <p className="label_text">
                    {t("screen.productAdd.label.productName")}
                  </p>
                  <MaxLengthTextInput
                    arai-label="Product name"
                    ref={productNameRef}
                    className="input_text"
                    editable={createType !== CreateType.MODIFY}
                    placeholder={t(
                      "screen.productAdd.label.inputProductNamePlaceholder"
                    )}
                    placeholderTextColor={"#999"}
                    maxLength={30}
                    value={writingProduct.productName}
                    onChangeText={(text: string) => setProductName(text)}
                  />
                </div>
                <div
                  className="input_wrap"
                  onClick={() => modelNameRef.current?.focus()}
                  // activeOpacity={1}
                >
                  <p className="label_text">
                    {t("screen.productAdd.label.modelName")}
                  </p>
                  <MaxLengthTextInput
                    arai-label="Model name"
                    ref={modelNameRef}
                    className="input_text"
                    editable={createType !== CreateType.MODIFY}
                    placeholder={t(
                      "screen.productAdd.label.inputModelNamePlaceholder"
                    )}
                    placeholderTextColor={"#999"}
                    maxLength={30}
                    value={writingProduct.productModelName}
                    onChangeText={(text: string) => setModel(text)}
                  />
                </div>
              </>
            )}
            {showProductImgView() && (
              <div className="img_input_wrap">
                <div className="img_input_inner">
                  <p className="label_text">
                    {t("screen.productAdd.label.myProductImg")}
                  </p>
                </div>
                <div ref={imageListRef} className="product_image_list_wrap first_list" arai-label="Product Image list">
                    {myProductFileList.map((item, index) =>
                      fileRender(item, index)
                    )}
                </div>
                <div className="img_guide_text">
                  <p className="product_img_input_inner_text">
                    {t("screen.productAdd.label.productImageGuideText")}
                  </p>
                </div>
              </div>
            )}
          </div>
          {/** 필수 정보 영역 end **/}

          <div className="middle_view">
            <p className="middle">
              {t("screen.productAdd.label.middleViewInnerText")}
            </p>
            <ArrowDown />
          </div>

          {/** optional info **/}
          <div className="contents">
            <p className="contents_title_text">{t("screen.productAdd.label.optional")}</p>
            {/* price */}
            <div
              onClick={() => {
                scrollToOptionalView();
                priceRef.current?.focus();
              }}
              className="input_wrap"
              // activeOpacity={1}
            >
              <p className="label_text">{t("screen.productAdd.label.price")}</p>
              <input
                arai-label="Price"
                className="input_text"
                ref={priceRef}
                onFocus={() => {
                  scrollToOptionalView();
                }}
                placeholder={t("screen.productAdd.label.inputOnlyNumber")}
                // keyboardType={"number-pad"}
                // textAlign={
                //   String(writingProduct.priceForScreen || "").length > 0
                //     ? "right"
                //     : "left"
                // }
                style={writingProduct.priceForScreen ? { textAlign: "right" } : {}}
                value={writingProduct.priceForScreen || ""}
                onChange={(e) => {
                  if (e.target.value.length < 14) setPrice(e.target.value);
                }}
              />
              <p className="unit_text">{t("screen.productAdd.label.won")}</p>
            </div>
            {/* country */}
            <div className="input_wrap input_country_wrap">
              <p className="label_text">{t("screen.productAdd.label.country")}</p>
              <div
                onClick={() => {
                  if (
                    writingProduct.purchaseCountry ===
                    PurchaseCountryType.DOMESTIC
                  ) {
                    setPurchaseCountry("");
                  } else {
                    setPurchaseCountry(PurchaseCountryType.DOMESTIC);
                  }
                }}
                className="country_chk_btn_view_item"
              >
                <div
                  className="country_chk_btn_view"
                  style={
                    writingProduct.purchaseCountry ===
                    PurchaseCountryType.DOMESTIC
                      ? { backgroundColor: "black" }
                      : {}
                  }
                >
                  <p
                    className="country_chk_btn_text"
                    style={
                      writingProduct.purchaseCountry ===
                      PurchaseCountryType.DOMESTIC
                        ? { color: "#fff" }
                        : {}
                    }
                  >
                    {"국내"}
                  </p>
                </div>
              </div>
              <div
                onClick={() => {
                  if (
                    writingProduct.purchaseCountry ===
                    PurchaseCountryType.OVERSEAS
                  ) {
                    setPurchaseCountry("");
                  } else {
                    setPurchaseCountry(PurchaseCountryType.OVERSEAS);
                  }
                }}
                className="country_chk_btn_view_item"
              >
                <div
                  className="country_chk_btn_view"
                  style={
                    writingProduct.purchaseCountry ===
                    PurchaseCountryType.OVERSEAS
                      ? { backgroundColor: "black" }
                      : {}
                  }
                >
                  <p
                    className="country_chk_btn_text"
                    style={
                      writingProduct.purchaseCountry ===
                      PurchaseCountryType.OVERSEAS
                        ? { color: "#fff" }
                        : {}
                    }
                  >
                    {"해외"}
                  </p>
                </div>
              </div>
            </div>
            {/* place */}
            <div
              onClick={() => {
                scrollToOptionalView();
                purchasePlaceRef.current?.focus();
              }}
              className="input_wrap"
              // activeOpacity={1}
            >
              <p className="label_text">{t("screen.productAdd.label.place")}</p>
              <MaxLengthTextInput
                arai-label="Purchase Place"
                className="input_text"
                ref={purchasePlaceRef}
                onFocus={() => {
                  scrollToOptionalView();
                }}
                placeholder={t("screen.productAdd.label.placeInputPlaceholder")}
                placeholderTextColor={"#999"}
                maxLength={20}
                value={writingProduct.purchasePlace}
                onChangeText={(text: string) => setPurchasePlace(text)}
              />
            </div>
            {/* purchaseDate */}
            <div
              onClick={() => {
                scrollToOptionalView();
                purchaseDateRef.current?.focus();
              }}
              className="input_wrap"
              // activeOpacity={1}
            >
              <p className="label_text">{t("screen.productAdd.label.purchaseDate")}</p>
              <MaxLengthTextInput
                arai-label="PurchaseDate"
                className="input_text"
                ref={purchaseDateRef}
                onFocus={() => {
                  scrollToOptionalView();
                }}
                placeholder={t(
                  "screen.productAdd.label.purchaseDatePlaceholder"
                )}
                placeholderTextColor={"#999"}
                keyboardType={"number-pad"}
                maxLength={10}
                value={writingProduct.purchaseDatetime ?? ""}
                onChangeText={(text: string) => {
                  ProductSearchAddStore.setPurchaseDateTime(text);
                }}
              />
            </div>
            {/* receipt */}
            <div className="img_input_wrap">
              <p className="img_input_inner">{t("screen.productAdd.label.receipt")}</p>
              <div ref={receiptListRef} className="product_image_list_wrap receipt_list" arai-label="Product Receipt Image list">
                {myReceiptFileList.map((item, index) =>
                  fileRenderReceipt(item, index)
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="spacer" style={{ height: 76 }}></div>

        <div className="footer">
          <div
            arai-label="Product register"
            className={
              isValidInput() ? "regi_button_active regi_button" : "regi_button regi_button_inactive"
            }
            onClick={() => {
              if (isValidInput()) {
                if (
                  writingProduct.purchaseDatetime &&
                  writingProduct.purchaseDatetime?.length > 0 &&
                  !ProductSearchAddStore.isValidDate
                ) {
                  alert("구매시기를 정확하게 입력해주세요(2023-01-01)");
                  return;
                }
                setConfirmPopup(true);
              }
            }}
          >
            <p>
              {createType === CreateType.MODIFY
                ? t("screen.productAdd.button.modify")
                : t("screen.productAdd.button.register2")}
            </p>
          </div>
        </div>
      </div>

      {hasError && (
        <NotificationModal
          isVisible={hasError}
          contents1={errorMessage!}
          onRequestClose={() => {
            setError("");
          }}
        />
      )}

      {showLeavePopup && (
        <NotificationModal
          isVisible={showLeavePopup}
          contents1={t("screen.productAdd.message.leave")}
          useTwoButton={true}
          isBlack={false}
          defaultButtonText={t("screen.notificationModal.button.OK")}
          extraButtonText={t("screen.notificationModal.button.cancel")}
          onClickDefaultButton={() => {
            clear(true);
            setShowLeavePopup(false);

            if (myApplianceScreen || brandCommunityProductList) {
              goBack();
            } else {
              goBackTwice();
            }
          }}
          onClickExtraButton={() => setShowLeavePopup(false)}
          onRequestClose={() => setShowLeavePopup(false)}
        />
      )}
      {showConfirmPopup && (
        <NotificationModal
          isVisible={showConfirmPopup}
          contents1={
            createType === CreateType.NEW
              ? t("screen.productAdd.message.registerConfirm")
              : t("screen.productAdd.message.modifyConfirm")
          }
          textAlign={"center"}
          useTwoButton={true}
          isBlack={false}
          defaultButtonText={t("screen.notificationModal.button.OK")}
          extraButtonText={t("screen.notificationModal.button.cancel")}
          onClickDefaultButton={() => void save()}
          onClickExtraButton={() => setConfirmPopup(false)}
          onRequestClose={() => setConfirmPopup(false)}
        />
      )}

      {showDateValidPopup && (
        <NotificationModal
          isVisible={showDateValidPopup}
          contents1={"구매시기를 정확하게 입력해주세요"}
          onRequestClose={() => {
            setShowDateValidPopup(false);
          }}
        />
      )}
    </>
  );
});

export default ProductAdd;
