import { memo, useContext } from "react";
import { SearchItem } from "../../../../api/search/model";
import { t } from "i18next";
import FastImageWithFallback from "../../../../components/common/FastImageWithFallback";
import ProductSearchAddStore from "../../../../store/ProductSearchAddStore";
// @ts-ignore
import { ReactComponent as ArrowRightIcon } from "../../../../assets/icons/prd_search_arrow_right.svg";
import { ReactComponent as UpTag } from "../../../../assets/icons/up_product_label.svg";
import { ProductSearchContext } from "../../ProductSearchScreen";
import { showBottomToast } from "../../../../utils/Toast";
import { CreateType } from "../../../../constants/Common.enum";
import { goTo } from "../../../../hooks/navigate/NavigateFunction";
import "./ProductSearchItem.scss";
import clsx from "clsx";

export const ProductSearchItem = memo(
  ({
    item,
    fromMyAppliance,
    index,
  }: {
    item: SearchItem;
    fromMyAppliance: boolean;
    index: number;
  }) => {
    const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;

    const { _screenFrom } = useContext(ProductSearchContext);

    const isClicked = () => {
      const key = item.openApiFlag
        ? `naver_${item.productId}`
        : `220_${item.productId}`;
      return ProductSearchAddStore.searchSearchProductMap.get(key);
    };

    return (
      <>
        <div
          id="product_search_item_section"
          className={clsx(isClicked() && "product_checked")}
          aria-label={`Product search result ${index}`}
          onClick={() => {
            // 내 가전 등록에서 들어온 경우
            if (_screenFrom == "my") {
              if (item.myProductId) {
                showBottomToast(t("screen.productAdd.message.alreadyMyPrdAdd"));
                return;
              }

              goTo("/Product/Add", {
                state: {
                  myApplianceScreen: true,
                  screenFrom: _screenFrom,
                  searchItem: item,
                  createType: CreateType.NEW,
                },
              });
              return;
            }

            if (isClicked() && item.productId) {
              const key = item.openApiFlag
                ? `naver_${item.productId}`
                : `220_${item.productId}`;
              ProductSearchAddStore.removeSelectedProduct(key);
            } else {
              void ProductSearchAddStore.setSelectedProduct(item);
            }
          }}
        >
          <FastImageWithFallback
            aria-label="Product image"
            wrapperClassName="product_image_wrapper"
            source={{
              uri: `${
                item.openApiFlag
                  ? item.thumbnailFilePath
                  : `${PUBLIC_BUCKET_URL}${item.thumbnailFilePath || ""}`
              }`,
            }}
            fallbackImageUri={
              item.openApiFlag
                ? item.thumbnailFilePath
                : `${PUBLIC_BUCKET_URL}${
                    item.thumbnailFilePath?.replace("THUMBNAIL", "ORIGINAL") ||
                    ""
                  }`
            }
            className={"product_image"}
          />
          <div className={"product_info"}>
            <p
              style={isClicked() ? { color: "#BE69C2" } : {}}
              className="product_text1"
              aria-label="Product name"
            >
              {item.productName && item.productName.length > 30
                ? item.productName.substring(0, 25) + "..."
                : item.productName}
            </p>
            <div className={"product_info_bottom"}>
              <p className={"product_text2"} aria-label="Brand name">
                {item.brandNameKor && item.brandNameKor.length > 0
                  ? item.brandNameKor
                  : "-"}
              </p>
              <p className={"product_text3"}>|</p>
              <p className={"product_text2"} aria-label="Product model name">
                {item.productModelName && item.productModelName.length > 0
                  ? item.productModelName
                  : "-"}
              </p>
            </div>
          </div>
          {isClicked() && (
            <div style={{ display: "flex" }}>
              <ArrowRightIcon />
            </div>
          )}
        </div>
      </>
    );
  }
);

ProductSearchItem.displayName = "ProductSearchItem";
