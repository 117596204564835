import { observer } from 'mobx-react'
import { ReactComponent as CloseIcon } from '../../assets/icons/close_white.svg'
import { ReactComponent as HomeNIcon } from '../../assets/icons/home_n.svg'
import { ReactComponent as LogOutIcon } from '../../assets/icons/logoutIcon.svg'
import { ReactComponent as ServiceIcon } from '../../assets/icons/serviceIcon.svg'
import { ReactComponent as Alert } from '../../assets/icons/alert.svg'
import { ReactComponent as AlertOn } from '../../assets/icons/alarm_on.svg'
import { useTranslation } from 'react-i18next'
import AuthStore from '../../store/AuthStore'
import CommonStore from '../../store/CommonStore'
import TouchableWithAuthCheck from '../../components/common/TouchableWithAuthCheck'
import NotificationStore from '../../store/NotificationStore'
import { ReactComponent as SettingIcon } from '../../assets/icons/ic_menu_setting.svg'
import { ReactComponent as InstagramIcon } from '../../assets/icons/menu_insta.svg'
import { ReactComponent as KakaoIcon } from '../../assets/icons/menu_kakao.svg'
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow_right_purple2.svg'
import { ReactComponent as FriendGift } from '../../assets/icons/recommend/friend_icon-gift.svg'
import {
  INSTAGRAM_URL,
  KAKAO_CHANNEL_URL,
} from '../../constants/BrandSocialChannel'
import TouchableWithAsyncTask from '../../components/common/TouchableWithAsyncTask'
import { TermsTypeCode } from '../../constants/Terms.enum'
import {
  ActHistoryTargetId,
  ActHistoryTypeCode,
  ActPageCode,
} from '../../constants/ActHistory'
import { ReactComponent as IconOrder } from '../../assets/icons/nav_icon_order.svg'
import { ReactComponent as IconVolt } from '../../assets/icons/nav_icon_volt.svg'
import { ReactComponent as IconCoupon } from '../../assets/icons/nav_icon_coupon.svg'
import { ReactComponent as DrawerHomeIcon } from '../../assets/icons/myhome.svg'
import { ReactComponent as DrawerPostIcon } from '../../assets/icons/icon_nav_post.svg'
import { ReactComponent as DrawerQnaIcon } from '../../assets/icons/icon_nav_qa.svg'
import { ReactComponent as DrawerMissionIcon } from '../../assets/icons/mission.svg'
import { ReactComponent as DrawerMagazineIcon } from '../../assets/icons/magazine.svg'
import { ReactComponent as DrawerRankingIcon } from '../../assets/icons/ranking.svg'
import { ReactComponent as DrawerBrandPageIcon } from '../../assets/icons/navigation/brand_page_icon.svg'
import { ReactComponent as DrawerMallIcon } from '../../assets/icons/navigation/mall_icon.svg'
import { goToLifecareMyPage } from '../../utils/mall'
import { getStringRemoteConfig } from '../../utils/remoteConfig'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Modal from 'react-modal'
import SettingStore from '../../store/SettingStore'
import { removeSessionUserFromStorage } from '../../service/Session.service'
import { clearStore } from '../../service/Common.service'
import styles from './RightNav.module.scss'
import clsx from 'clsx'
import { useRef, useState } from 'react'
import TermsOfUseModal, { TermsDetailProps } from '../modals/TermsOfUseModal'
import { goTo } from '../../hooks/navigate/NavigateFunction'

const RightNav = observer(
  ({ open, onClose }: { open: boolean; onClose: () => void }) => {
    const { t } = useTranslation()
    const {
      right_nav,
      container,
      header,
      header_icons_wrap,
      header_icons_left,
      icons,
      main_text_wrap,
      welcome_text_wrap,
      intro_text_wrap,
      intro_text,
      user_action_text,
      draw_list,
      alert,
      image_wrap,
      image,
      info_wrap,
      id_text,
      sir_text,
      bar2,
      close_icon,
      bottom_info_wrap,
      bottom_info_wrap_text,
      bottom_info_wrap_text_bold,
      sns_wrap,
      sns_btn,
      before_login_id_wrap,
      id_text2,
      before_login_text,
      signup_btn_wrap,
      signup_btn,
      arrow_icon,
      display_block,
    } = styles

    const defaultTermsDetailProps = {
      show: false,
      termsId: '',
      termsTypeCode: '',
      onClose: () => {},
    }
    const [termsDetailProps, setTermsDetailProps] = useState<TermsDetailProps>(
      defaultTermsDetailProps
    )

    const showFriendRecommendView = getStringRemoteConfig(
      'show_friend_recommend_navigation'
    )

    const { sessionUser, tempMember } = AuthStore

    const PUBLIC_PROFILE_BUCKET_URL = String(
      process.env.REACT_APP_PUBLIC_BUCKET_URL
    )

    const moveToMain = () => {
      closeModal()
      CommonStore.callActHistory(
        ActHistoryTypeCode.PAGE_CLICK,
        ActHistoryTargetId.MAIN,
        ActPageCode.SIDE_MENU
      )
      goTo('/Main')
    }

    const showDownloadAppDrive = () => {
      closeModal()
      CommonStore.setShowDownloadAppDrive(true)
    }

    const moveToSettings = () => {
      goTo('/setting')
      // showDownloadAppDrive();
    }

    const onCloseTermsOfUseModal = () => {
      setTermsDetailProps(defaultTermsDetailProps)
    }

    const moveToServiceTermsModal = () => {
      closeModal()
      setTermsDetailProps({
        show: true,
        termsId: '',
        termsTypeCode: TermsTypeCode.SERVICE,
        onClose: () => onCloseTermsOfUseModal(),
      })
    }

    const moveToPrivacyTermsModal = () => {
      closeModal()
      setTermsDetailProps({
        show: true,
        termsId: '',
        termsTypeCode: TermsTypeCode.PRIVACY_DETAIL,
        onClose: () => onCloseTermsOfUseModal(),
      })
    }

    const openInstagram = async () => {
      window.open(INSTAGRAM_URL, '_blank')
    }

    const openKakaoChannel = async () => {
      window.open(KAKAO_CHANNEL_URL, '_blank')
    }

    const openServiceUrl = async () => {
      const url = getStringRemoteConfig('web_service_intro_url')
      window.open(url, '_blank')
    }

    const onAfterOpen = () => {
      document.body.classList.add('modal-open')
    }

    const closeModal = () => {
      onClose()
    }

    const onAfterClose = () => {
      document.body.classList.remove('modal-open')
    }

    const headerRef = useRef<HTMLDivElement>(null)
    const onScroll = (e: any) => {
      if (headerRef.current) {
        if (e.target.scrollTop > 0) {
          headerRef.current?.classList.add(styles.shadow)
        } else {
          headerRef.current?.classList.remove(styles.shadow)
        }
      }
    }

    const logout = () => {
      SettingStore.logout().then(() => {
        removeSessionUserFromStorage()
        clearStore()
        closeModal()
        goTo('/main')
        CommonStore.setToastOption({
          show: true,
          message1: t('screen.setting.label.logoutToast'),
        })
      })
    }

    return (
      <>
        <Modal
          isOpen={open}
          onAfterOpen={onAfterOpen}
          onRequestClose={closeModal}
          onAfterClose={onAfterClose}
          style={{
            content: {
              outline: 'none',
              position: 'absolute',
              top: 0,
              right: 0,
              left: 'unset',
              border: 'none',
              boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)',
              height: '100dvh',
              animation: !open
                ? 'right-nav-close-animation 0.3s ease-in'
                : 'right-nav-open-animation 0.3s ease-out',
              overflow: 'unset',
              width: 330,
              padding: 0,
            },
            overlay: {
              outline: 'none',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 999,
              left: 'clamp(0px, calc(50% - 225px), 50vw)',
              maxWidth: 450,
              overflow: 'hidden',
            },
          }}
          closeTimeoutMS={250}
          className={{
            base: clsx(styles.modal),
            afterOpen: clsx(styles.modal_after_open),
            beforeClose: clsx(styles.modal_before_close),
          }}
        >
          <div className={right_nav}>
            <button
              aria-label="close drawer"
              onClick={closeModal}
              className={close_icon}
            >
              <CloseIcon />
            </button>
            <div className={header} ref={headerRef}>
              <div className={header_icons_wrap}>
                <div className={header_icons_left}>
                  <button className={icons} onClick={moveToMain}>
                    <HomeNIcon />
                  </button>
                </div>
                <div className={styles.header_icons_right}>
                  {sessionUser != null &&
                    Object.keys(sessionUser).length !== 0 && (
                      <button
                        className={icons}
                        onClick={logout}
                        aria-label={'Right Logout Menu'}
                      >
                        <LogOutIcon />
                      </button>
                    )}

                  <div className={alert}>
                    <TouchableWithAuthCheck
                      className={icons}
                      beforeOnPress={closeModal}
                      onPress={() => {
                        goTo('/NotificationScreen')
                      }}
                    >
                      {NotificationStore.notCheckedCount > 0 ? (
                        <AlertOn />
                      ) : (
                        <Alert />
                      )}
                    </TouchableWithAuthCheck>
                  </div>
                  <TouchableWithAuthCheck
                    className={icons}
                    beforeOnPress={closeModal}
                    onPress={moveToSettings}
                    aria-label={'Right Setting Menu'}
                  >
                    <SettingIcon />
                  </TouchableWithAuthCheck>
                </div>
              </div>
              {sessionUser != null && Object.keys(sessionUser).length !== 0 ? (
                <div
                  className={styles.bx_profile}
                  style={{ paddingBottom: 28 }}
                >
                  <div className={image_wrap}>
                    <TouchableWithAuthCheck
                      beforeOnPress={() => {
                        CommonStore.callActHistory(
                          ActHistoryTypeCode.PAGE_CLICK,
                          ActHistoryTargetId.PROFILE_IMAGE,
                          ActPageCode.SIDE_MENU
                        )
                        closeModal()
                      }}
                      onPress={() => {
                        goTo('/MyHome')
                      }}
                    >
                      <LazyLoadImage
                        aria-label="right profile image"
                        src={`${PUBLIC_PROFILE_BUCKET_URL}${
                          sessionUser.profileUrl || ''
                        }`}
                        className={image}
                      />
                    </TouchableWithAuthCheck>
                  </div>
                  <div>
                    <div className={info_wrap} aria-label="user information">
                      <TouchableWithAuthCheck
                        beforeOnPress={() => {
                          CommonStore.callActHistory(
                            ActHistoryTypeCode.PAGE_CLICK,
                            ActHistoryTargetId.PROFILE_IMAGE,
                            ActPageCode.SIDE_MENU
                          )
                          closeModal()
                        }}
                        onPress={() => {
                          goTo('/MyHome')
                        }}
                      >
                        <span
                          className={id_text}
                          aria-label="nickname"
                          // numberOfLines={1}
                        >
                          {sessionUser.nickname}
                        </span>
                      </TouchableWithAuthCheck>

                      <span className={sir_text}>
                        {t('screen.rightNav.label.userInformation')}
                      </span>
                    </div>
                    <div
                      className={intro_text_wrap}
                      aria-label="sign in welcome message"
                    >
                      <span className={intro_text}>
                        {t('screen.rightNav.label.welcomeText')}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{ paddingBottom: 24 }}>
                  <div className={main_text_wrap}>
                    <div className={before_login_id_wrap}>
                      <span className={id_text2}>{'GUEST'}</span>
                      <span className={before_login_text}>
                        {t('screen.rightNav.label.userInformation')}
                      </span>
                    </div>
                    <div className={welcome_text_wrap}>
                      <span className={clsx(before_login_text, display_block)}>
                        {t('screen.rightNav.label.introMessage1')}
                      </span>
                      <span className={clsx(before_login_text, display_block)}>
                        {t('screen.rightNav.label.introMessage2')}
                      </span>
                    </div>
                    <div className={signup_btn_wrap}>
                      <TouchableWithAuthCheck
                        beforeOnPress={closeModal}
                        aria-label="sign up"
                        className={signup_btn}
                      >
                        <span className={user_action_text}>
                          {t('screen.rightNav.button.signUp')}
                        </span>
                        <ArrowIcon className={arrow_icon} />
                      </TouchableWithAuthCheck>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div
              className={container}
              aria-label="side navigation bar"
              onScroll={onScroll}
            >
              {sessionUser != null && Object.keys(sessionUser).length !== 0 ? (
                <div className={styles.bx_link_wrap}>
                  <div className={styles.bx_link_item}>
                    <button
                      className={styles.touch_link}
                      onClick={() => {
                        // TODO YY
                        CommonStore.callActHistory(
                          ActHistoryTypeCode.PAGE_CLICK,
                          ActHistoryTargetId.ORDER_HISTORY,
                          ActPageCode.SIDE_MENU
                        )
                        // goToLifecareMyPage();
                        showDownloadAppDrive()
                      }}
                    >
                      <IconOrder />
                      <span className={styles.txt_link_label}>
                        {t('screen.rightNav.label.orderHistory')}
                      </span>
                    </button>
                  </div>
                  <div className={styles.bx_link_item}>
                    <button
                      className={styles.touch_link}
                      onClick={() => {
                        CommonStore.callActHistory(
                          ActHistoryTypeCode.PAGE_CLICK,
                          ActHistoryTargetId.VOLT,
                          ActPageCode.SIDE_MENU
                        )
                        goTo('/myVoltHistory')
                      }}
                    >
                      <IconVolt />
                      <span className={styles.txt_link_label}>
                        {t('screen.rightNav.label.myVolt')}
                      </span>
                    </button>
                  </div>
                  <div className={styles.bx_link_item}>
                    <button
                      className={styles.touch_link}
                      onClick={() => {
                        // TODO YY
                        CommonStore.callActHistory(
                          ActHistoryTypeCode.PAGE_CLICK,
                          ActHistoryTargetId.COUPON,
                          ActPageCode.SIDE_MENU
                        )
                        // navigate("CouponListScreen");
                        showDownloadAppDrive()
                      }}
                    >
                      <IconCoupon />
                      <span className={styles.txt_link_label}>
                        {t('screen.rightNav.label.coupon')}
                      </span>
                    </button>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {showFriendRecommendView && (
                <TouchableWithAuthCheck
                    beforeOnPress={() => {
                      closeModal()
                      CommonStore.callActHistory(
                        ActHistoryTypeCode.PAGE_CLICK,
                        ActHistoryTargetId.INVITE,
                        ActPageCode.SIDE_MENU
                      );
                    }}
                    onPress={() => {
                    goTo("/FriendRecommend");
                  }}
                  className={styles.recommend_friend_view_btn}
                >
                  <div className={styles.recommend_friend_view}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <FriendGift style={{ marginRight: 8, marginBottom: 2 }} />
                      <span
                        style={{ marginTop: 2 }}
                        className={styles.recommend_friend_txt1}
                      >
                        {t('screen.rightNav.label.friendRecommend1')}
                        <span className={styles.recommend_friend_txt2}>
                          {t('screen.rightNav.label.friendRecommend2')}
                        </span>
                      </span>
                    </div>
                  </div>
                </TouchableWithAuthCheck>
              )}
              <div className={draw_list}>
                <>
                  <TouchableWithAuthCheck
                    className={styles.label_wrap}
                    aria-label="Right Home Menu"
                    beforeOnPress={() => {
                      closeModal()
                      CommonStore.callActHistory(
                        ActHistoryTypeCode.PAGE_CLICK,
                        ActHistoryTargetId.MY_HOME,
                        ActPageCode.SIDE_MENU
                      )
                    }}
                    onPress={() => {
                      goTo('/MyHome')
                    }}
                  >
                    <DrawerHomeIcon />
                    <span className={styles.label}>
                      {t('common.menu.myHome')}
                    </span>
                  </TouchableWithAuthCheck>
                  <button
                    className={styles.label_wrap}
                    aria-label="Right Post Menu"
                    onClick={() => {
                      closeModal()
                      CommonStore.callActHistory(
                        ActHistoryTypeCode.PAGE_CLICK,
                        ActHistoryTargetId.POST,
                        ActPageCode.SIDE_MENU
                      )
                      goTo('/posts')
                    }}
                  >
                    <DrawerPostIcon />
                    <span className={styles.label}>
                      {t('common.menu.post')}
                    </span>
                  </button>
                  <button
                    className={styles.label_wrap}
                    aria-label="Right Qna Menu"
                    onClick={() => {
                      closeModal()
                      CommonStore.callActHistory(
                        ActHistoryTypeCode.PAGE_CLICK,
                        ActHistoryTargetId.QNA,
                        ActPageCode.SIDE_MENU
                      )
                      goTo('/QnA')
                    }}
                  >
                    <DrawerQnaIcon />
                    <span className={styles.label}>{t('common.menu.qna')}</span>
                  </button>
                  <button
                    className={styles.label_wrap}
                    aria-label="Right Mission Menu"
                    onClick={() => {
                      closeModal()
                      CommonStore.callActHistory(
                        ActHistoryTypeCode.PAGE_CLICK,
                        ActHistoryTargetId.MISSION,
                        ActPageCode.SIDE_MENU
                      )
                      goTo('/Mission')
                    }}
                  >
                    <DrawerMissionIcon />
                    <span className={styles.label}>
                      {t('common.menu.mission')}
                    </span>
                  </button>
                  <button
                    className={styles.label_wrap}
                    aria-label="Right Magazine Menu"
                    onClick={() => {
                      closeModal()
                      CommonStore.callActHistory(
                        ActHistoryTypeCode.PAGE_CLICK,
                        ActHistoryTargetId.MAGAZINE,
                        ActPageCode.SIDE_MENU
                      )
                      goTo('/magazine')
                    }}
                  >
                    <DrawerMagazineIcon />
                    <span className={styles.label}>
                      {t('common.menu.magazine')}
                    </span>
                  </button>
                  <button
                    className={styles.label_wrap}
                    aria-label="Right Ranking Menu"
                    onClick={() => {
                      closeModal()
                      CommonStore.callActHistory(
                        ActHistoryTypeCode.PAGE_CLICK,
                        ActHistoryTargetId.RANKING,
                        ActPageCode.SIDE_MENU
                      )
                      // TODO YY
                      // navigation.navigate("RankingScreen");
                      showDownloadAppDrive()
                    }}
                  >
                    <DrawerRankingIcon />
                    <span className={styles.label}>
                      {t('common.menu.ranking')}
                    </span>
                  </button>
                  <button
                    className={styles.label_wrap}
                    aria-label="Right mall Menu"
                    onClick={() => {
                      closeModal()
                      CommonStore.callActHistory(
                        ActHistoryTypeCode.PAGE_CLICK,
                        ActHistoryTargetId.MALL_MAIN,
                        ActPageCode.SIDE_MENU
                      )
                      // TODO YY
                      // AuthStore.sessionUser?.memberUuid
                      //   ? navigation.navigate("MainTab", {
                      //       screen: "MallMainScreen",
                      //     })
                      //   : navigate("JoinModal");
                      showDownloadAppDrive()
                    }}
                  >
                    <DrawerMallIcon />
                    <span className={styles.label}>
                      {t('common.menu.mall')}
                    </span>
                  </button>
                  {getStringRemoteConfig('show_brand_page') === 'true' && (
                    <div
                      className={styles.label_wrap}
                      aria-label="Right Brand Menu"
                      onClick={() => {
                        // TODO YY
                        // navigation.navigate("MainTab", {
                        //   screen: "BrandCommunityMain",
                        // });
                        showDownloadAppDrive()
                      }}
                    >
                      <DrawerBrandPageIcon />
                      <span className={styles.label}>
                        {t('common.menu.brand')}
                      </span>
                    </div>
                  )}
                  <button
                    className={styles.label_wrap}
                    onClick={openServiceUrl}
                  >
                    <ServiceIcon />
                    <span className={styles.label}>
                      {t('common.menu.service')}
                    </span>
                  </button>
                </>
              </div>
              <div className={bar2} />
              <div className={bottom_info_wrap}>
                <div style={{ flexDirection: 'row' }}>
                  <button onClick={moveToServiceTermsModal}>
                    <span className={bottom_info_wrap_text}>
                      {t('screen.rightNav.button.service')}
                    </span>
                  </button>
                  <button onClick={moveToPrivacyTermsModal}>
                    <span
                      style={{ marginLeft: 12 }}
                      className={bottom_info_wrap_text_bold}
                    >
                      {t('screen.rightNav.button.personal')}
                    </span>
                  </button>
                </div>
                <div className={sns_wrap}>
                  <TouchableWithAsyncTask
                    className={sns_btn}
                    onClick={openKakaoChannel}
                  >
                    <KakaoIcon />
                  </TouchableWithAsyncTask>
                  <TouchableWithAsyncTask
                    className={sns_btn}
                    onClick={openInstagram}
                  >
                    <InstagramIcon />
                  </TouchableWithAsyncTask>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <TermsOfUseModal termsDetailProps={termsDetailProps} />
      </>
    )
  }
)

export default RightNav
