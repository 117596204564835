import { useEffect, useRef, useState } from "react";
import { Styles } from "../../../assets/types/Style";
import {
  Arranges,
  Colors,
  Positions,
  Spacings,
  Texts,
} from "../../../assets/styles";
import { observer } from "mobx-react";
import CommentStore, { CommentInEdit } from "../../../store/CommentStore";
import { useTranslation } from "react-i18next";
import { CommentListItem } from "../../../api/comment/model";
import { NotificationModal } from "../../../components/modals";
import { CommentTypeCode } from "../../../constants/Comment";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import { ReactComponent as EmptyImg } from "../../../assets/icons/img_no_data_reply.svg";
import ArticleDetailStore from "../../../store/ArticleDetailStore";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import { useLocation } from "react-router-dom";
import CommentItem from "./CommentItem";
import "./DetailReply.scss";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

const DetailReply = observer(
  ({
    feedId,
    detailStore,
    commentTypeCode,
  }: {
    feedId: number;
    detailStore: any;
    commentTypeCode: CommentTypeCode;
  }) => {
    const {
      bar,
      comment_list,
      comment_list_empty,
      comment_all_view,
      comment_all_view_btn,
      comment_all_view_btn_text,
      comment_list_empty_text_wrap,
      empty_btn_wrap,
      empty_btn,
    } = styles;

    const {
      commentList,
      commentCount,
      loading,
      deleteComment,
      setCommentInEdit,
      setDeleteTargetCommentInfo,
      deleteTargetCommentInfo,
      setCommentTargetAndRetrieveCommentList,
      clear,
    } = CommentStore;

    const { t } = useTranslation();
    const { pathname } = useLocation();

    const commentListRef = useRef<VirtuosoHandle>(null);

    const [showDeletePopup, setDeletePopup] = useState(false);
    const [showReportPopup, setReportPopup] = useState(false);
    const [renderItemMaxIndex, setRenderItemMaxIndex] = useState(
      commentTypeCode === CommentTypeCode.ARTICLE
        ? commentList?.length || 10
        : 10
    );

    useEffect(() => {
      if (feedId) {
        void setCommentTargetAndRetrieveCommentList({
          authorId: String(detailStore.createMemberUuid),
          commentTypeCode: commentTypeCode,
          commentTargetId: feedId,
          orderByLike: "Y",
        });
      }
    }, [
      detailStore,
      setCommentTargetAndRetrieveCommentList,
      feedId,
      commentTypeCode,
    ]);

    const moveToComment = (feedId: number) => {
      CommentStore.clear();

      goTo(`${pathname}/comment`, {
        state: {
          feedId: feedId,
          feedType: commentTypeCode,
          feedAuthorId: detailStore.createMemberUuid,
        },
      });
    };

    const handleDeleteReplyBtn = () => {
      setDeletePopup(true);
    };

    const scrollFlatlistToIndex = (index: number, waitFor = 0) => {
      if (waitFor) {
        setTimeout(() => {
          commentListRef?.current?.scrollToIndex({ index });
        }, waitFor);
      } else {
        commentListRef?.current?.scrollToIndex({ index });
      }
    };

    const handlePressAddReplyBtn = (comment: CommentInEdit) => {
      setCommentInEdit(comment);
    };
    const handleChildCommentShown = (index: number) => () => {
      scrollFlatlistToIndex(index, 100);
    };

    const handleBestPicked = () => {
      scrollFlatlistToIndex(0);
    };

    const handlePressWriteComment = () => {
      CommentStore.clear();
      goTo("comment", {
        state: {
          feedId: feedId,
          feedType: commentTypeCode,
          feedAuthorId: detailStore?.createMemberUuid,
        },
      });
    };

    const renderFooter = () => {
      if (
        commentTypeCode !== CommentTypeCode.QNA &&
        commentTypeCode !== CommentTypeCode.ARTICLE &&
        commentList &&
        commentList.length > 10
      ) {
        return (
          <TouchableWithAuthCheck
            style={comment_all_view_btn}
            onPress={() => {
              moveToComment(feedId);
            }}
          >
            <span style={comment_all_view_btn_text}>
              {t(`screen.qna.button.allReply`)}
            </span>
          </TouchableWithAuthCheck>
        );
      }
      return <></>;
    };
    const renderItem = (index: number, item: CommentListItem) => {
      return (
        <CommentItem
          comment={item}
          index={index}
          isAbDetailReply={commentTypeCode === CommentTypeCode.AB}
          isQnaDetailReply={commentTypeCode === CommentTypeCode.QNA}
          onDeleteReplyBtn={handleDeleteReplyBtn}
          onPressAddReplyBtn={handlePressAddReplyBtn}
          onAddReplyBtn={() =>
            commentTypeCode !== CommentTypeCode.QNA &&
            commentTypeCode !== CommentTypeCode.ARTICLE &&
            moveToComment(feedId)
          }
          onModifyReplyBtn={() =>
            commentTypeCode !== CommentTypeCode.ARTICLE &&
            commentTypeCode !== CommentTypeCode.QNA &&
            moveToComment(feedId)
          }
          onChildCommentShown={handleChildCommentShown(index)}
          onBestPicked={handleBestPicked}
          showReply={
            detailStore.articleCanCommentYn &&
            detailStore.articleCanCommentYn === "N"
              ? false
              : undefined
          }
        />
      );
    };
    const renderCommentList = () => {
      return (
        <>
          <div
            style={comment_list}
            aria-label={"detail comment list"}
            className="comment_list"
          >
            {commentList && commentList.length > 0 ? (
              <>
                {commentList.map((item: CommentListItem, index: number) =>
                  renderItem(index, item)
                )}
                {renderFooter()}
              </>
            ) : (
              <div
                style={{ position: "relative" }}
                className="empty_comment_list"
              >
                <div
                  style={comment_list_empty_text_wrap}
                  className="comment_list_empty_text_wrap"
                >
                  {ArticleDetailStore.articleDetail.articleCanCommentYn !==
                    "N" && (
                    <span
                      style={comment_list_empty}
                      className="comment_list_empty"
                    >
                      {t(`screen.qna.message.emptyReply`)}
                    </span>
                  )}
                  {commentTypeCode !== CommentTypeCode.ARTICLE &&
                    commentTypeCode !== CommentTypeCode.QNA && (
                      <button
                        style={empty_btn_wrap}
                        className="empty_btn_wrap"
                        onClick={handlePressWriteComment}
                      >
                        <span style={empty_btn} className="empty_btn">
                          {t(`screen.qna.message.writeReply`)}
                        </span>
                      </button>
                    )}
                </div>
                <div className="empty_image">
                  <EmptyImg />
                </div>
              </div>
            )}
          </div>
        </>
      );
    };

    return (
      !loading && (
        <>
          <div style={bar} />
          {renderCommentList()}
          <div
            style={comment_all_view}
            aria-label={"detail show all reply button"}
          >
            {showDeletePopup && (
              <NotificationModal
                isVisible={showDeletePopup}
                contents1={t(
                  "screen.notificationModal.message.deleteCommentMessage"
                )}
                useTwoButton={true}
                isBlack={false}
                defaultButtonText={t("screen.notificationModal.button.OK")}
                extraButtonText={t("screen.notificationModal.button.cancel")}
                onClickDefaultButton={() => {
                  if (deleteTargetCommentInfo !== null) {
                    void deleteComment(deleteTargetCommentInfo);
                  }
                  setDeletePopup(false);
                }}
                onClickExtraButton={() => {
                  setDeletePopup(false);
                  setDeleteTargetCommentInfo(null);
                }}
                onRequestClose={() => {
                  setDeletePopup(false);
                  setDeleteTargetCommentInfo(null);
                }}
              />
            )}

            {showReportPopup && (
              <NotificationModal
                isVisible={showReportPopup}
                contents1={t(
                  "screen.notificationModal.message.reportCommentMessage"
                )}
                useTwoButton={true}
                isBlack={false}
                defaultButtonText={t("screen.notificationModal.button.OK")}
                extraButtonText={t("screen.notificationModal.button.cancel")}
                onClickDefaultButton={() => {
                  setReportPopup(false);
                  // TODO : 신고하기 요청, Qna list refresh
                }}
                onClickExtraButton={() => setReportPopup(false)}
                onRequestClose={() => setReportPopup(false)}
              />
            )}
          </div>
        </>
      )
    );
  }
);

export default DetailReply;

const styles: Styles = {
  bar: {
    height: 6,
    ...Spacings.margin_top_8,
    ...Colors.background_255,
  },
  comment_list: {
    ...Colors.background_255,
    paddingBottom: 48,
  },
  comment_list_empty: {
    ...Texts.contents_text_38,
    ...Spacings.margin_bottom_14,
    ...Colors.font_666666,
    fontWeight: "500",
  },
  comment_all_view: {
    ...Spacings.margin_top_8,
    ...Spacings.padding_left_right_20_responsive,
    ...Spacings.margin_bottom_60,
  },
  comment_all_view_btn: {
    ...Arranges.center_v_h,
    ...Colors.border_color_eee,
    ...Spacings.border_radius_6,
    borderWidth: 1,
    height: 46,
  },
  comment_all_view_btn_text: {
    ...Texts.btn_text_1,
    ...Texts.font_weight_500,
  },
  comment_list_empty_text_wrap: {
    ...Positions.absolute,
    ...Arranges.center_h,
    top: 90,
    left: 0,
    right: 0,
    zIndex: 1,
  },
  empty_btn_wrap: {
    width: 118,
    height: 44,
    borderWidth: 1,
    borderRadius: 300,
    ...Colors.border_color_a760b1,
    ...Arranges.center_v_h,
  },
  empty_btn: {
    ...Texts.button_text_10,
    ...Colors.font_a760b1,
    fontWeight: "500",
  },
};
