export const absolute = {
  position: "absolute",
};

export const relative = {
  position: "relative",
};

export const top = {
  top: 0,
};

export const right = {
  right: 0,
};

export const bottom = {
  bottom: 0,
};

export const left = {
  left: 0,
};

export const left_8 = {
  left: 8,
};

export const left_9 = {
  left: 9,
};

export const rleft_10 = {
  left: 10,
};

export const left_16 = {
  left: 16,
};

export const zIndex_bottom = {
  zIndex: 1,
};

export const zIndex_middle = {
  zIndex: 1000,
};

export const zIndex_top = {
  zIndex: 10000,
};

export const position_bottom_20 = {
  bottom: 20,
};

export const right_8 = {
  right: 8,
};

export const right_10 = {
  right: 10,
};

export const right_16 = {
  right: 16,
};

export const top_2 = {
  top: 2,
};

export const top_6 = {
  top: 6,
};

export const top_16 = {
  top: 16,
};

export const top_24 = {
  top: 24,
};

export const bottom_24 = {
  bottom: 24,
};

export const fullscreen = {
  ...absolute,
  ...top,
  ...right,
  ...bottom,
  ...left,
};
