import React, { useEffect, useState } from "react";
import "./BookmarkProduct.scss";

import { ReactComponent as CheckOffIcon } from "../../../assets/icons/checkbox_big_off.svg";
import { ReactComponent as CheckOnIcon } from "../../../assets/icons/checkbox_big_on.svg";

import { BookmarkFeedItem } from "../../../api/bookmark/model";
import { observer } from "mobx-react-lite";
import BookmarkStore from "../../../store/BookmarkStore";
import { FeedTypeCode } from "../../../constants/Feed";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import CommonStore from "../../../store/CommonStore";

const BookmarkProduct = observer(({ data }: { data: BookmarkFeedItem }) => {
  const {
    bookmarkFeeds,
    selectedBookmarkFeeds,
    isTotalChecked,
    isEdit,
    setIsTotalChecked,
    selectBookmarkFeed,
    currentTab,
  } = BookmarkStore;

  const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;

  const [isSelected, setIsSelected] = useState(false);

  const postTotalChecked = isTotalChecked.get(currentTab);
  const postEdit = isEdit.get(currentTab);
  const selectedPosts = selectedBookmarkFeeds.get(currentTab);

  const toggleIsSelected = () => {
    setIsSelected(!isSelected);
  };

  const handleSelect = (bookmarkTargetId?: number) => () => {
    toggleIsSelected();
    bookmarkTargetId !== undefined &&
      selectBookmarkFeed(currentTab, bookmarkTargetId);

    const lengthOfBookmarkFeeds: number =
      bookmarkFeeds.get(currentTab)?.length || 0;
    const lengthOfSelectedBookmarkFeeds: number =
      selectedBookmarkFeeds.get(currentTab)?.size || 0;
    setIsTotalChecked(
      currentTab,
      lengthOfBookmarkFeeds === lengthOfSelectedBookmarkFeeds ? true : false
    );
  };

  useEffect(() => {
    setIsSelected(false);
  }, [postEdit]);

  useEffect(() => {
    if (isTotalChecked.get(currentTab)) {
      setIsSelected(true);
    }
  }, [postTotalChecked, isTotalChecked, currentTab]);

  useEffect(() => {
    const selectedBookmarkPosts = selectedBookmarkFeeds.get(currentTab);
    selectedBookmarkPosts !== undefined &&
      data.productId !== undefined &&
      setIsSelected(selectedBookmarkPosts.has(data.productId));
  }, [
    selectedBookmarkFeeds,
    selectedPosts,
    postTotalChecked,
    data.productId,
    currentTab,
  ]);

  return (
    <div id="bookmark_product">
      {isEdit.get(FeedTypeCode.PRODUCT) ? (
        <div className="product_item" aria-label="Product Item">
          <button
            className="product_check_btn"
            onClick={handleSelect(data.productId)}
            aria-label="Fill in or out checkbox"
          >
            {isSelected ? <CheckOnIcon /> : <CheckOffIcon />}
          </button>
          <FastImageWithFallback
            source={{
              uri: `${PUBLIC_BUCKET_URL}${data.thumbnailFilePath ?? ""}`,
            }}
            className="product_image"
          />
          <div className="product_info">
            <p className="product_text1">{data.productName}</p>
            <div className="product_info_bottom">
              <p className="product_text2">{data.brandNameKor}</p>
              <p className="product_text3">|</p>
              <p className="product_text2">{data.productModelName}</p>
            </div>
          </div>
        </div>
      ) : (
        <button
          className="product_item"
          onClick={() => {
            CommonStore.setShowDownloadAppDrive(true);
            // goTo("/ProductDetailScreen", { productId: data.productId });
          }}
          aria-label="Click Product item"
        >
          <FastImageWithFallback
            source={{
              uri: `${PUBLIC_BUCKET_URL}${data.thumbnailFilePath ?? ""}`,
            }}
            fallbackImageUri={`${PUBLIC_BUCKET_URL}${
              data.thumbnailFilePath?.replace("THUMBNAIL", "ORIGINAL") || ""
            }`}
            className="product_image"
          />
          <div className="product_info">
            <p className="product_text1">{data.productName}</p>
            <div className="product_info_bottom">
              <p className="product_text2">{data.brandNameKor}</p>
              <p className="product_text3">|</p>
              <p className="product_text2">{data.productModelName}</p>
            </div>
          </div>
        </button>
      )}
    </div>
  );
});

export default BookmarkProduct;
