import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import {
  MemberInfoResponse,
  MemberTagItem,
  SimilarFollowerItem,
} from "../../../api/member/model";

import "./AccountHeader.scss";
import {
  ProfileColor,
  ProfileColorList,
} from "../../../constants/ProfileColor";
import { countFollow } from "../../../utils/numberCount";
// import { navigate, push } from "../../../navigation/NavigationFunction";
import { follow, unfollow } from "../../../api/follow/api";
import { FollowersTab } from "../../../constants/Account";
import CommonStore from "../../../store/CommonStore";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import CustomParsedText from "../../../components/common/CustomParsedText";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../../constants/ActHistory";
import { ReactComponent as PencilIcon } from "../../../assets/icons/icon_pencil.svg";
import { ReactComponent as IconVolt } from "../../../assets/icons/icon_my_volt.svg";
import { ReactComponent as IconCommunity } from "../../../assets/icons/icon_my_community.svg";

import TouchableWithAsyncTask from "../../../components/common/TouchableWithAsyncTask";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import BadgeStore from "../../../store/BadgeStore";
import { padding_right_16 } from "../../../assets/styles/spacings";
import { RoleTypeCode } from "../../../constants/CommonCode.enum";
import { useTracker } from "../../../hooks/tracker";
import { UserActTypeCode } from "../../../constants/FeedType.enum";
import FollowerStore from "../../../store/FollowerStore";
import { getSimilarFollowers } from "../../../api/member/api";
import AuthStore from "../../../store/AuthStore";
import { CommaAddInTextInput, formatVoltAmount } from "../../../utils/number";
// import RemoteConfigStore from "../../../store/RemoteConfigStore";
import { BadgeCode } from "../../../constants/Badge.enum";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ProfileUpdate from "../../profile/ProfileUpdate";
import clsx from "clsx";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

const AccountHeader = observer(
  ({
    isMy,
    nickname,
    profileUrl,
    profileBackgroundColor,
    accountInfo,
    isLoading,
  }: {
    isMy: boolean;
    nickname?: string;
    profileUrl?: string;
    profileBackgroundColor?: string;
    accountInfo: MemberInfoResponse;
    isLoading?: boolean;
  }) => {
    const { t } = useTranslation();
    const [colorInfo, setColorInfo] = useState(ProfileColor.PURPLE);
    const [isFollow, setIsFollow] = useState<boolean>();
    const [followerCount, setFollowerCount] = useState(
      accountInfo?.followerCount || 0
    );
    const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;
    const { setToastOption } = CommonStore;
    const OFFICIAL_BADGE_URI = PUBLIC_BUCKET_URL + "/ASSETS/badge_official.png";
    const [similarFollowerList, setSimilarFollowerList] =
      useState<SimilarFollowerItem[]>();
    const [similarFollowerTotalCount, setSimilarFollowerTotalCount] =
      useState<number>();

    const { track } = useTracker();

    const renderTag = (item: { item: MemberTagItem; index: number }) => {
      return (
        <TouchableWithAuthCheck
          className="tag_item_wrap"
          onPress={() => {
            CommonStore.callActHistory(
              ActHistoryTypeCode.TAG_CLICK,
              (item.item.tagId ?? 0).toString(),
              ActPageCode.MY_HOME,
              ActSectionCode.MY_TAGS
            );
            moveToTagSearch(item.item);
          }}
        >
          {item.index + 1 !== accountInfo?.interestedTagList?.length ? (
            <div className="tag_item" key={item.index} aria-label="Tag item">
              <p className="tag_text">#{item.item.tagName}</p>
            </div>
          ) : (
            <div className="tag_item" key={item.index} aria-label="Tag item">
              <p className="tag_text">#{item.item.tagName}</p>
            </div>
          )}
        </TouchableWithAuthCheck>
      );
    };

    const renderFollowerInfo = () => {
      if (!similarFollowerList || similarFollowerList.length == 0) return <></>;

      if (similarFollowerList.length == 1) {
        // 한명만 있을때
        return (
          <p className="txt_follow_info">
            <span className="txt_follow_info_bold">
              {similarFollowerList[0]?.nickname}
            </span>
            님이 팔로우 중입니다.
          </p>
        );
      } else if (similarFollowerList.length == 2) {
        // 2명 있을때
        return (
          <p className="txt_follow_info">
            <span className="txt_follow_info_bold">
              {similarFollowerList[0]?.nickname}
            </span>
            님,{" "}
            <span className="txt_follow_info_bold">
              {similarFollowerList[1]?.nickname}
            </span>
            님이 팔로우 중입니다.
          </p>
        );
      } else {
        // 3명 이상 있을때
        return (
          <p className="txt_follow_info">
            <span className="txt_follow_info_bold">
              {similarFollowerList[0]?.nickname}
            </span>
            님,{" "}
            <span className="txt_follow_info_bold">
              {similarFollowerList[1]?.nickname}
            </span>
            님 외{" "}
            <span className="txt_follow_info_bold">
              {CommaAddInTextInput(
                ((similarFollowerTotalCount || 0) - 2)?.toString() || ""
              )}
            </span>
            명이 팔로우 중입니다.
          </p>
        );
      }
    };

    const renderFollowerList = () => {
      return (
        <TouchableWithAuthCheck onPress={moveToFollower}>
          <div className="bx_follow_list">
            <div
              className={clsx(
                "bx_profile_img",
                `bx_profile_img_${similarFollowerList?.length}_people`
              )}
            >
              {similarFollowerList?.flatMap((item, index) => {
                return (
                  <div className={clsx("img_profile", "img_profile_" + index)}>
                    <LazyLoadImage
                      className="in_img_profile"
                      src={`${PUBLIC_BUCKET_URL}${item.profileUrl}`}
                    />
                  </div>
                );
              })}
            </div>
            {renderFollowerInfo()}
          </div>
        </TouchableWithAuthCheck>
      );
    };

    const moveToTagSearch = (item: MemberTagItem) => {
      goTo(`/tag/${item.tagId}`, {
        state: { tagId: item.tagId },
      });
    };

    const moveToBadge = () => {
      if (isMy) goTo("/Badge", { state: { accountInfo: accountInfo } });
    };

    const moveToProfile = () => {
      if (isMy)
        goTo("/ProfileUpdate", {
          state: {
            accountInfo: accountInfo,
            profileImage: `${PUBLIC_BUCKET_URL}${
              accountInfo?.profileUrl || ""
            }`,
            backgroundColor: accountInfo?.profileBackgroundColor,
            nickname: accountInfo?.nickname,
            introduction: accountInfo?.introduce,
            snsUrl: accountInfo?.snsUrl,
            gender: accountInfo?.genderCode,
            birthYear: accountInfo?.birthYear,
            recommendedNicknameYn: accountInfo?.recommendedNicknameYn,
          },
        });
    };

    const moveToFollower = () => {
      if (accountInfo !== undefined && accountInfo !== null) {
        goTo("/follow", {
          state: {
            targetUserId: accountInfo.memberUuid,
            targetUserNickname: accountInfo.nickname,
            type: FollowersTab.FOLLOWERS,
          },
        });
      }
    };

    const moveToFollowing = () => {
      if (accountInfo !== undefined && accountInfo !== null) {
        goTo("/follow", {
          state: {
            targetUserId: accountInfo.memberUuid,
            targetUserNickname: accountInfo.nickname,
            type: FollowersTab.FOLLOWINGS,
          },
        });
      }
    };

    const moveToMyAppliancesScreen = () => {
      if (accountInfo !== undefined && accountInfo !== null) {
        CommonStore.setShowDownloadAppDrive(true);
        // navigate("/MyAppliancesScreen", {
        //   state: {
        //     targetMemberUuid: accountInfo.memberUuid,
        //     nickname: accountInfo.nickname,
        //   },
        // });
      }
    };

    const moveToSnsUrl = () => {
      // todo
    };

    const doUnFollow = async () => {
      if (accountInfo?.memberUuid !== undefined) {
        //PPIT210117-4818 UNFOLLOW 시 actHistory 처리 추가
        CommonStore.callActHistory(
          ActHistoryTypeCode.USER_FOLLOWING,
          accountInfo?.memberUuid,
          ActPageCode.OTHER_USER_HOME,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          "CANCEL"
        );
        setIsFollow(false);
        setFollowerCount(followerCount - 1);
        const res = await unfollow(accountInfo?.memberUuid);
        FollowerStore.handlePostFollowUpdate(
          UserActTypeCode.UN_FOLLOW,
          accountInfo?.memberUuid
        );
        if (!res) {
          setIsFollow(true);
          setFollowerCount(followerCount + 1);
        } else {
          onToast(false);
        }
      }
    };

    const doFollow = async () => {
      if (accountInfo?.memberUuid !== undefined) {
        CommonStore.callActHistory(
          ActHistoryTypeCode.USER_FOLLOWING,
          accountInfo?.memberUuid,
          ActPageCode.OTHER_USER_HOME
        );
        setIsFollow(true);
        setFollowerCount(followerCount + 1);
        const res = await follow(accountInfo?.memberUuid);
        FollowerStore.handlePostFollowUpdate(
          UserActTypeCode.FOLLOW,
          accountInfo?.memberUuid
        );
        if (!res) {
          setIsFollow(false);
          setFollowerCount(followerCount - 1);
        } else {
          onToast(true);
          void BadgeStore.obtainBadge(BadgeCode.FOLLOW);
        }
      }

      track("click_follow_button", {
        tag: accountInfo.interestedTagList?.map((t) => t.tagName),
      });
    };

    const onToast = (follow: boolean) => {
      setToastOption({
        show: true,
        message1: follow
          ? t("screen.myHome.message.followToast", {
              nickname: accountInfo?.nickname,
            })
          : t("screen.myHome.message.unfollowToast", {
              nickname: accountInfo?.nickname,
            }),
        // bottomOffset: 30,
      });
    };

    // TODO
    // function isCommunityFound() {
    //   if (RemoteConfigStore.show_brand_page === "false") return false;
    //   else {
    //     return (accountInfo.communityCount || 0) > 0;
    //   }
    // }

    const renderBasicFollowButtonArea = () => {
      return (
        <>
          {isLoading && (
            <TouchableWithAsyncTask
              className="follow_btn1"
              // TODO
              // onPress={doFollow}
              authCheckProps={{ enabled: true }}
            >
              <p
                className="follow_btn_text1"
                style={{ color: currentBackgroundColor }}
                aria-label="Follow"
              >
                {t("screen.myHome.label.followRequesting")}
              </p>
            </TouchableWithAsyncTask>
          )}
          {!isLoading && isFollow != undefined && isFollow && (
            <>
              {/* 팔로우 후 */}
              <TouchableWithAsyncTask
                className="follow_btn2"
                onPress={doUnFollow}
                authCheckProps={{ enabled: true }}
              >
                <p className="follow_btn_text2" aria-label="Followed">
                  {t("screen.myHome.label.following")}
                </p>
              </TouchableWithAsyncTask>
            </>
          )}
          {!isLoading && isFollow != undefined && !isFollow && (
            <>
              {/* 팔로우 전 */}
              <TouchableWithAsyncTask
                className="follow_btn1"
                onPress={doFollow}
                authCheckProps={{ enabled: true }}
              >
                <p
                  className="follow_btn_text1"
                  style={{ color: currentBackgroundColor }}
                  aria-label="Follow"
                >
                  {t("screen.followers.button.follow")}
                </p>
              </TouchableWithAsyncTask>
            </>
          )}
        </>
      );
    };

    function moveToCommunity() {
      // TODO
      // if (isCommunityFound()) {
      //   navigate("MainTab", {
      //     screen: "BrandCommunityHome",
      //     params: { targetBrandId: `${accountInfo.communityId || 0}` },
      //   });
      // }
    }

    const renderCorporateCommunityButtonArea = () => {
      return (
        <>
          <div className="follow_btn2" onClick={moveToCommunity}>
            <p className="follow_btn_text2" aria-label="Followed">
              {t("screen.myHome.label.community")}
            </p>
          </div>
        </>
      );
    };

    // TODO
    const renderCorporateFollowButtonArea = () => {
      // TODO: 팔로우랑 팔로잉 버튼 스타일이 달라야 함
      return (
        <div className="bx_add_btn">
          <div className="bx_com_btn">
            <div className="bx_touch_btn" onClick={moveToCommunity}>
              <p className="txt_touch_border">
                {t("screen.myHome.label.community")}
              </p>
              {/* <BtnArrowDown /> */}
            </div>
          </div>
          <div className="bx_com_btn">
            {isLoading ? (
              <TouchableWithAsyncTask
              // style={[styles.bx_touch_btn, styles.btn_full]}
              >
                <p className="txt_touch_full">
                  {t("screen.myHome.label.followRequesting")}
                </p>
              </TouchableWithAsyncTask>
            ) : (
              <TouchableWithAsyncTask
                // style={[styles.bx_touch_btn, styles.btn_full]}
                onPress={
                  isLoading ? undefined : isFollow ? doUnFollow : doFollow
                }
              >
                <p className="txt_touch_full">
                  {t(
                    isFollow
                      ? "screen.myHome.label.following"
                      : "screen.followers.button.follow"
                  )}
                </p>
              </TouchableWithAsyncTask>
            )}
          </div>
        </div>
      );
    };

    useEffect(() => {
      setIsFollow(accountInfo?.followingYN === "Y");
      setFollowerCount(accountInfo?.followerCount || 0);
      const color = ProfileColorList.find(
        (item) => accountInfo?.profileBackgroundColor?.toLowerCase() == item.hex
      );
      setColorInfo(color || ProfileColor.COLOR10);

      if (accountInfo.roleTypeCode == RoleTypeCode.CORPORATE) {
        void getSimilarFollowers({
          loginMemberUuid: AuthStore.sessionUser?.memberUuid || "",
          corpMemberUuid: accountInfo.memberUuid,
          maxMemberCount: 3,
        }).then((response) => {
          if (response?.successOrNot == "Y") {
            setSimilarFollowerList(response.data?.similarFollowerList);
            setSimilarFollowerTotalCount(response.data?.totalCount);
          }
        });
      }
    }, [accountInfo]);

    const currentBackgroundColor =
      (profileBackgroundColor
        ? profileBackgroundColor
        : accountInfo?.profileBackgroundColor) || "#672C78";

    return (
      <div
        className="profile"
        style={{ backgroundColor: currentBackgroundColor }}
      >
        <div className="profile_info_wrap">
          <div
            className="profile_image_container"
            onClick={moveToProfile}
            aria-label="Profile image"
          >
            <div className="corp_badge">
              {accountInfo?.roleTypeCode === RoleTypeCode.CORPORATE && (
                // TODO: CORPORATE일때 back ground img가 되도록 처리
                <img
                  src={
                    accountInfo?.roleTypeCode === RoleTypeCode.CORPORATE
                      ? OFFICIAL_BADGE_URI
                      : ""
                  }
                  className="corp_badge_img"
                  alt=""
                />
              )}
              <FastImageWithFallback
                style={{ display: "flex" }}
                className="profile_image"
                source={{
                  uri: `${PUBLIC_BUCKET_URL}${
                    (profileUrl ? profileUrl : accountInfo?.profileUrl) || ""
                  }`,
                }}
              />
            </div>
            {isMy && (
              <div className="edit_icon_wrap">
                <PencilIcon />
              </div>
            )}
          </div>
          <div className="profile_info">
            <div className="profile_info_top">
              <div className="profile_text_1_wrapper">
                <p className="profile_text_1" aria-label="Nickname">
                  {nickname ? nickname : accountInfo?.nickname}
                </p>
              </div>
              <TouchableWithAuthCheck onPress={moveToBadge}>
                <div className="badge-list" aria-label="Badge list">
                  <div className="badge-item">
                    <img
                      className="profile_btn"
                      src={`${PUBLIC_BUCKET_URL}${
                        accountInfo?.levelInfo?.levelIconUrl || ""
                      }`}
                      alt={accountInfo?.levelInfo?.levelIconUrl}
                    />
                    {accountInfo?.obtainedBadgeList?.map((item) => {
                      return (
                        <div className="badge-item-inner" key={item.badgeId}>
                          <img
                            className="profile_btn"
                            src={`${PUBLIC_BUCKET_URL}${
                              item.badgeIconUrl || ""
                            }`}
                            alt={item.badgeIconUrl}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </TouchableWithAuthCheck>
            </div>
            <div className="profile_info_bottom">
              <div className="info_bottom_item" aria-label="Feed count">
                <p className="profile_text_2">
                  {t("screen.myHome.label.feed")}
                </p>
                {!isLoading && (
                  <p className="profile_text_3">
                    {countFollow(accountInfo?.createdFeedCount ?? 0) ?? "0"}
                  </p>
                )}
              </div>
              <TouchableWithAuthCheck onPress={moveToFollower}>
                <div className="info_bottom_item" aria-label="Follower count">
                  <p className="profile_text_2">
                    {t("screen.myHome.label.follower")}
                  </p>
                  {!isLoading && (
                    <p className="profile_text_3">
                      {countFollow(followerCount)}
                    </p>
                  )}
                </div>
              </TouchableWithAuthCheck>
              <TouchableWithAuthCheck onPress={moveToFollowing}>
                <div className="info_bottom_item" aria-label="Following count">
                  <p className="profile_text_2">
                    {t("screen.myHome.label.following")}
                  </p>
                  {!isLoading && (
                    <p className="profile_text_3">
                      {countFollow(accountInfo?.followingCount ?? 0) || "0"}
                    </p>
                  )}
                </div>
              </TouchableWithAuthCheck>
              <TouchableWithAuthCheck onPress={moveToMyAppliancesScreen}>
                <div
                  className="info_bottom_item"
                  aria-label="MyAppliances count"
                >
                  <p className="profile_text_2">
                    {t("screen.myHome.label.myproduct")}
                  </p>
                  {!isLoading && (
                    <p className="profile_text_3">
                      {countFollow(accountInfo?.productCount ?? 0) || "0"}
                    </p>
                  )}
                </div>
              </TouchableWithAuthCheck>
            </div>
          </div>
        </div>
        <div
          className={clsx({
            greeting_text: true,
            has_introduce_or_sns: accountInfo?.introduce || accountInfo?.snsUrl,
          })}
        >
          {accountInfo !== undefined &&
            accountInfo?.introduce !== undefined &&
            accountInfo?.introduce?.length > 0 && (
              <p aria-label="Introduce" className="profile_text_4">
                {accountInfo?.introduce}
              </p>
            )}
          {accountInfo !== undefined &&
            accountInfo?.snsUrl !== undefined &&
            accountInfo?.snsUrl?.length > 0 && (
              <div onClick={moveToSnsUrl} className="profile_text_5">
                <CustomParsedText
                  aria-label="Sns url"
                  urlStyle={{ color: "#fff" }}
                >
                  {accountInfo?.snsUrl}
                </CustomParsedText>
              </div>
            )}
        </div>
        <div className="tag-list-wrapper">
          {isLoading && (
            <div className="tag">
              <div className="tag_item" aria-label="Tag item">
                <p className="tag_text"> </p>
              </div>
            </div>
          )}
          {!isLoading &&
            accountInfo != null &&
            accountInfo != undefined &&
            accountInfo?.interestedTagList != undefined &&
            accountInfo?.interestedTagList?.length > 0 && (
              <>
                {accountInfo?.interestedTagList.map((item, index) =>
                  renderTag({ item, index })
                )}
              </>
            )}
        </div>
        {accountInfo.roleTypeCode == RoleTypeCode.CORPORATE && (
          <div className="bx_add_follow">
            {
              !isMy && renderBasicFollowButtonArea()
              // TODO
              // isCommunityFound()
              //   ? renderCorporateFollowButtonArea()
              //   : renderBasicFollowButtonArea()
            }
            {/* {isMy && isCommunityFound() && renderCorporateCommunityButtonArea()} */}
            {(similarFollowerList?.length || 0) > 0 && renderFollowerList()}
          </div>
        )}

        {/* TODO: 배경색이랑 맞게 변경 */}
        {/* 법인이 아닌 타 유저 일때만 보이기 */}
        {/* TODO: 브랜드 페이지 노출할 때 */}
        {
          !isMy && accountInfo.roleTypeCode != RoleTypeCode.CORPORATE && (
            // (RemoteConfigStore.show_brand_page === "true" ? (
            //   <>
            //     {renderBasicFollowButtonArea()}
            //     <div style={styles.bx_row_half}>
            //       <div style={[styles.bx_col, styles.bx_col_first]}>
            //         <div style={styles.bx_label}>
            //           <IconVolt />
            //           <p style={styles.txt_label}>
            //             {t("screen.volt.label.volt")}
            //           </p>
            //         </div>
            //         <div style={styles.bx_col_info}>
            //           <p style={styles.txt_info_value}>
            //             {formatVoltAmount(accountInfo.voltAmount)}
            //           </p>
            //         </div>
            //       </div>
            //       <div style={[styles.bx_col]}>
            //         <div style={styles.bx_label}>
            //           <IconCommunity />
            //           <p style={styles.txt_label}>
            //             {t("screen.myHome.label.community")}
            //           </p>
            //         </div>
            //         <div style={styles.bx_col_info}>
            //           <p style={styles.txt_info_value}>
            //             {accountInfo.communityCount || "0"}
            //           </p>
            //         </div>
            //       </div>
            //     </div>
            //   </>
            // ) : (
            <>
              <div className="bx_add_volt">
                <IconVolt />
                <p className="txt_add_volt">
                  {CommaAddInTextInput(
                    accountInfo.voltAmount?.toString() || "0"
                  )}
                  볼트를 모았어요.
                </p>
              </div>
              {renderBasicFollowButtonArea()}
            </>
          )
          // ))
        }
      </div>
    );
  }
);

export default AccountHeader;
