export enum ActHistoryTypeCode {
  POST_READ = "POST_READ",
  POST_EXPOSE = "POST_EXPOSE",
  QNA_READ = "QNA_READ",
  AB_READ = "AB_READ",
  MISSION_READ = "MISSION_READ",
  MAGAZINE_READ = "MAGAZINE_READ",
  ARTICLE_READ = "ARTICLE_READ",
  USER_FOLLOWING = "USER_FOLLOWING",
  SIMILAR_USER_CLICK = "SIMILAR_USER_CLICK",
  BOOKMARK = "BOOKMARK",
  LIKE = "LIKE",
  USER_CLICK = "USER_CLICK",
  SEARCH = "SEARCH",
  SEARCH_KEYWORD_CLICK = "SEARCH_KEYWORD_CLICK",
  TAG_CLICK = "TAG_CLICK",
  PRODUCT_READ = "PRODUCT_READ",
  TAG_FOLLOW = "TAG_FOLLOW",
  POST_SHARE = "POST_SHARE",
  CHECK_MISSION_WINNER = "CHECK_MISSION_WINNER",
  PAGE_CLICK = "PAGE_CLICK",
  ARTICLE_SHARE = "ARTICLE_SHARE",
  LIFECARE_SHARE = "LIFECARE_SHARE",
  MALL_PRODUCT_READ = "MALL_PRODUCT_READ",
  FEED_WRITE = "FEED_WRITE",
  PRODUCT_SPEC_READ = "PRODUCT_SPEC_READ",
  AUTO_LOGIN = "AUTO_LOGIN",
  PAGE_VIEW = "PAGE_VIEW",
}

export enum ActPageCode {
  MAIN = "MAIN",
  OTHER_USER_HOME = "OTHER_USER_HOME",
  MY_HOME = "MY_HOME",
  FOLLOWING_MANAGEMENT = "FOLLOWING_MANAGEMENT",
  BOOKMARK = "BOOKMARK",
  AB_DETAIL = "AB_DETAIL",
  SEARCH_TOTAL = "SEARCH_TOTAL",
  SEARCH_TAG = "SEARCH_TAG",
  SEARCH_POST = "SEARCH_POST",
  SEARCH_QNA = "SEARCH_QNA",
  SEARCH_PRODUCT = "SEARCH_PRODUCT",
  SEARCH = "SEARCH",
  PRODUCT_DETAIL = "PRODUCT_DETAIL",
  QNA = "QNA",
  POST = "POST",
  MAGAZINE = "MAGAZINE",
  QNA_TOTAL = "QNA_TOTAL",
  AB_TOTAL = "AB_TOTAL",
  QNA_DETAIL = "QNA_DETAIL",
  POST_DETAIL = "POST_DETAIL",
  LIFECARE = "LIFECARE",
  DETAIL_POST_LIST = "DETAIL_POST_LIST",
  DETAIL_COMMENT_LIST = "DETAIL_COMMENT_LIST",
  TAG = "TAG",
  TAG_PRODUCT = "TAG_PRODUCT",
  TAG_GENERAL = "TAG_GENERAL",
  RANKING = "RANKING",
  RELATED_PRODUCT = "RELATED_PRODUCT",
  NOTIFICATION = "NOTIFICATION",
  MAGAZINE_DETAIL = "MAGAZINE_DETAIL",
  MISSION = "MISSION",
  MISSION_DETAIL = "MISSION_DETAIL",
  SIDE_MENU = "SIDE_MENU",
  TOP_GNB = "TOP_GNB",
  BOTTOM_TAB = "BOTTOM_TAB",
  ARTICLE_DETAIL = "ARTICLE_DETAIL",
  MALL_MAIN = "MALL_MAIN",
  MALL_CATEGORY_DETAIL = "MALL_CATEGORY_DETAIL",
  POST_WRITE = "POST_WRITE",
  QNA_WRITE = "QNA_WRITE",
  AB_WRITE = "AB_WRITE",
  AUTO_LOGIN = "AUTO_LOGIN",
}

export enum ActSectionCode {
  BANNER = "BANNER",
  POST = "POST",
  POPULAR_POST = "POPULAR_POST",
  POPULAR_AB = "POPULAR_AB",
  POPULAR_QNA = "POPULAR_QNA",
  AB = "AB",
  SIMILAR_USER = "SIMILAR_USER",
  SEARCH_TOTAL = "SEARCH_TOTAL",
  SEARCH_POSTER = "SEARCH_POSTER",
  SEARCH_POST = "SEARCH_POST",
  RECOMMEND_TAG = "RECOMMEND_TAG",
  POPULAR_TAG = "POPULAR_TAG",
  SEARCH_TAG = "SEARCH_TAG",
  QNA = "QNA",
  MAGAZINE = "MAGAZINE",
  RECOMMEND_QNA = "RECOMMEND_QNA",
  RECENT_QNA = "RECENT_QNA",
  REAL_POST = "REAL_POST",
  USE_METHOD_POST = "USE_METHOD_POST",
  RELATED_PRODUCT = "RELATED_PRODUCT",
  RANKING_PRODUCT = "RANKING_PRODUCT",
  RANKING_USER = "RANKING_USER",
  PRODUCT_QNA = "PRODUCT_QNA",
  RECENT_POST = "RECENT_POST",
  FOLLOWING_POST = "FOLLOWING_POST",
  EXPIRED_AB = "EXPIRED_AB",
  COMMENT = "COMMENT",
  PRODUCT = "PRODUCT",
  BRAND_COMMUNITY = "BRAND_COMMUNITY",
  ARTICLE = "ARTICLE",
  TAG = "TAG",
  RELATED_MAGAZINE = "RELATED_MAGAZINE",
  MY_TAGS = "MY_TAGS",
  MISSION_IN_PROGRESS = "IN_PROGRESS",
  MISSION_END = "END",
  MISSION_PARTICIPATED = "PARTICIPATED",
  CATEGORY = "CATEGORY",
  CATEGORY_BEST = "CATEGORY_BEST",
  POPULAR_PRODUCT = "POPULAR_PRODUCT",
  RECOMMENDED_CATEGORY = "RECOMMENDED_CATEGORY",
  PICK_220S = "220S_PICK",
  QUICKMENU = "QUICKMENU",
  RANKING = "RANKING",
  PRODUCT_SPEC = "PRODUCT_SPEC",
}

export enum ActTypeCode {
  SHOW_MORE = "MORE",
  SHOW_DETAIL = "DETAIL",
}

export enum ActHistoryTargetId {
  MORE_POST = "MORE_POST",
  AI_RECOMMEND = "AI_RECOMMEND",
  PROFILE_IMAGE = "PROFILE_IMAGE",
  MY_HOME = "MY_HOME",
  POST = "POST",
  QNA = "QNA",
  MISSION = "MISSION",
  MAGAZINE = "MAGAZINE",
  RANKING = "RANKING",
  MAIN = "MAIN",
  SEARCH = "SEARCH",
  MALL_MAIN = "MALL_MAIN",
  LG_UP = "LG_UP",
  BRAND_COMMUNITY_MAIN = "BRAND_COMMUNITY_MAIN",
  ORDER_HISTORY = "ORDER_HISTORY",
  VOLT = "VOLT",
  COUPON = "COUPON",
  INVITE = "INVITE",
  MALL_SEARCH = "MALL_SEARCH",
  MALL_CATEGORY = "MALL_CATEGORY",
  MALL_CATEGORY_DETAIL = "MALL_CATEGORY_DETAIL",
  POST_WRITE = "POST_WRITE",
  QNA_WRITE = "QNA_WRITE",
  AB_WRITE = "AB_WRITE",
  AUTO_LOGIN = "AUTO_LOGIN",
  ATTENDANCE = "ATTENDANCE",
}
