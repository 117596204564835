import { CSSProperties, useCallback, useEffect, useState } from "react";
import {
  LazyLoadImage,
  LazyLoadImageProps,
} from "react-lazy-load-image-component";

export interface Source {
  uri: string;
}
export interface FastImageWithFallbackProps extends LazyLoadImageProps {
  source: number | Source;
  index?: number;
  carouselIdx?: number;
  fallbackImageUri?: string;
  renderFallbackNode?: (props: Omit<any, "onError">) => JSX.Element;
  wrapperStyle?: CSSProperties;
  onFallbackHandler?: () => void;
  onLoad2?: (index: number, event: any) => void;
}

const SUPPORTED_IMAGE_FILE_EXTENSIONS = ["JPEG", "JPG", "PNG", "GIF", "HEIC"];
const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);
const FALLBACK_IMAGE_URL = PUBLIC_BUCKET_URL + "/ASSETS/fall_back_image.png";

type FastImageResourceType =
  | "URI"
  | "FALLBACK_URI"
  | "FALLBACK_NODE"
  | "DEFAULT_URI";

const isBase64 = (uri: string) => uri.startsWith("data:image");

const isImageUri = (uri: string) => {
  const upperCasedUri = uri.toUpperCase();
  return SUPPORTED_IMAGE_FILE_EXTENSIONS.some((extension) =>
    upperCasedUri.endsWith(extension)
  );
};

const getEncodedUri = (uri: string) => {
  const encodedUri = encodeURI(uri);
  const alreadyEncoded = decodeURI(encodedUri) === uri;

  return alreadyEncoded || isBase64(uri) ? uri : encodedUri;
};

const getAccessibilityLabel = (index: number, carouselIdx: number): string => {
  let accessibilityLabel = "media content";
  if (index !== undefined) {
    accessibilityLabel += index.toString();
    if (carouselIdx !== undefined) {
      accessibilityLabel += `-${carouselIdx}`;
    }
  }
  return accessibilityLabel;
};

const FastImageWithFallback = ({
  fallbackImageUri,
  carouselIdx,
  renderFallbackNode,
  source,
  wrapperStyle,
  wrapperClassName,
  onFallbackHandler,
  onLoad2,
  ...props
}: FastImageWithFallbackProps) => {
  const { uri } = source as any;

  useEffect(() => {
    const resourceType = getInitialResourceType(
      source,
      fallbackImageUri,
      !!renderFallbackNode
    );
    setResourceType(resourceType);

    if (resourceType !== "URI") {
      onFallbackHandler && onFallbackHandler();
    }
  }, [uri, fallbackImageUri, onFallbackHandler]);

  const getInitialResourceType = useCallback(
    (
      source: number | Source,
      fallbackImageUri?: string,
      fallbackNode?: boolean
    ) => {
      if (
        typeof source === "object" &&
        source.uri &&
        (isImageUri(source.uri) || isBase64(source.uri))
      ) {
        return "URI";
      } else if (fallbackImageUri) {
        return "FALLBACK_URI";
      } else if (fallbackNode) {
        return "FALLBACK_NODE";
      } else {
        return "URI";
      }
    },
    []
  );
  const [resourceType, setResourceType] = useState<FastImageResourceType>(
    getInitialResourceType(source, fallbackImageUri, !!renderFallbackNode)
  );
  const [loadChild, setLoadchild] = useState(false);
  return (
    <div style={wrapperStyle} className={wrapperClassName}>
      {resourceType === "URI" && (
        <LazyLoadImage
          aria-label={getAccessibilityLabel(props.index || 0, carouselIdx || 0)}
          {...props}
          src={
            typeof source === "object" ? getEncodedUri(source.uri || "") : ""
            // typeof source === "object"
            //   ? getEncodedUri(source.uri || "")
            //   : source
          }
          onError={() => setResourceType("FALLBACK_URI")}
          onLoad={(event) => {
            onLoad2 && onLoad2(carouselIdx ? carouselIdx : 0, event);
            setLoadchild(true);
          }}
          visibleByDefault
        >
          {/* {props.children} */}
        </LazyLoadImage>
      )}
      {resourceType === "FALLBACK_URI" && (
        <LazyLoadImage
          aria-label={getAccessibilityLabel(props.index || 0, carouselIdx || 0)}
          {...props}
          src={getEncodedUri(fallbackImageUri || "")}
          onError={() =>
            renderFallbackNode
              ? setResourceType("FALLBACK_NODE")
              : setResourceType("DEFAULT_URI")
          }
          onLoad={(event) => {
            onLoad2 && onLoad2(carouselIdx ? carouselIdx : 0, event);
            setLoadchild(true);
          }}
        />
      )}
      {resourceType === "FALLBACK_NODE" &&
        renderFallbackNode!({ ...props, source })}
      {resourceType === "DEFAULT_URI" && (
        <LazyLoadImage
          aria-label={getAccessibilityLabel(props.index || 0, carouselIdx || 0)}
          {...props}
          src={FALLBACK_IMAGE_URL}
          onLoad={(event) => {
            onLoad2 && onLoad2(carouselIdx ? carouselIdx : 0, event);
            setLoadchild(true);
          }}
        >
          {/* {loadChild && <>{props.children}</>} */}
        </LazyLoadImage>
      )}
    </div>
  );
};

export default FastImageWithFallback;
