import { RefObject } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/close_purple_background.svg";
import { ReactComponent as AddIcon } from "../../../assets/icons/plus_purple.svg";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { observer } from "mobx-react";
import PostWriteStore from "../../../store/PostWriteStore";
import { FeedMode, FeedTypeCode } from "../../../constants/Feed";
import { ReactComponent as DimImage } from "../../../assets/icons/image_add_dim.svg";
import ProductSearchAddStore from "../../../store/ProductSearchAddStore";
import { showBottomToast } from "../../../utils/Toast";
import "./PostProduct.scss";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

const PostProduct = observer(
  ({ mode, parentRef }: { mode?: FeedMode; parentRef: RefObject<any> }) => {
    const { t } = useTranslation();
    const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);
    const { tempProductList, deleteProduct, setRepresentProduct } =
      PostWriteStore;

    const moveToProductSearch = () => {
      if (tempProductList.length < 5) {
        ProductSearchAddStore.setFeedMode(
          mode === FeedMode.MODIFY ? FeedMode.MODIFY : FeedMode.WRITE
        );
        goTo("/Product/Search", {
          state: {
            feedTypeCode: FeedTypeCode.POST,
            screenFrom: "post",
          },
        });
      } else showBottomToast(t("screen.feedProduct.message.max5Count"));
    };
    return (
      <div id="post_product">
        <div className="bar" />
        <div className="home_app_wrap">
          <div className="home_app_list_wrap">
            {tempProductList.map((item, index) => {
              const imgPath = item.product.originalFilePath || "";
              return (
                <>
                  <div className="home_app_item" key={`Product item ${index}`}>
                    {!item.product?.openApiFlag &&
                    item.product?.productId !== undefined &&
                    item.product?.productId > 0 ? (
                      <div className="info_wrap">
                        <FastImageWithFallback
                          className="home_app_image"
                          source={{
                            uri: `${PUBLIC_BUCKET_URL}${imgPath}`,
                          }}
                        />
                        <div className="info">
                          <span
                            className="info_text_1"
                            // numberOfLines={1}
                          >
                            [{item.product?.manufacturerName}]{" "}
                            {item.product?.productName}
                          </span>
                          <span className="info_text_2">
                            {item.product?.productModelName}
                          </span>
                          <button
                            className={
                              item.isRepresent
                                ? "rep_info_active rep_info"
                                : "rep_info_inactive rep_info"
                            }
                            onClick={() => setRepresentProduct(index)}
                          >
                            <span
                              className={
                                item.isRepresent
                                  ? "rep_info_active_text rep_info_text"
                                  : "rep_info_inactive_text rep_info_text"
                              }
                            >
                              {t("screen.feedProduct.button.represent")}
                            </span>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="info_wrap">
                        <FastImageWithFallback
                          className="home_app_image"
                          source={{
                            uri: imgPath?.includes("https:")
                              ? imgPath
                              : `${PUBLIC_BUCKET_URL}${imgPath}`,
                          }}
                          fallbackImageUri={imgPath}
                        >
                          <DimImage className="dim_image" />
                        </FastImageWithFallback>
                        <div className="info">
                          <span
                            className="info_text_1"
                            // numberOfLines={2}
                            // ellipsizeMode={"tail"}
                          >
                            [{item.product?.manufacturerName}]{" "}
                            {item.product?.productName}
                          </span>
                          <span className="info_text_2">
                            {item.product?.productModelName}
                          </span>
                          <span className="alert_text">
                            {t("screen.feedProduct.label.willApprove")}
                          </span>
                        </div>
                      </div>
                    )}
                    <div>
                      <button onClick={() => deleteProduct(index)}>
                        <DeleteIcon />
                      </button>
                    </div>
                  </div>
                </>
              );
            })}
          </div>

          <button className="add_home_app_button" onClick={moveToProductSearch}>
            <AddIcon />
            <span className="add_home_app_button_text">
              {t("screen.post.button.addHa")}
            </span>
          </button>
        </div>
      </div>
    );
  }
);

export default PostProduct;
