import { observer } from "mobx-react";
import React, { memo, RefObject, useCallback, useState } from "react";
import {
  MainItem,
  PopularQna,
  PopularRanking,
  PopularTag,
  Post,
} from "../../../api/feed/model";
import { AccountFeedTypeCode } from "../../../constants/Account";
import { FeedTypeCode } from "../../../constants/Feed";
import {
  MainFeedTypeCode,
  UserActTypeCode,
} from "../../../constants/FeedType.enum";
import { ReactComponent as MainProfileAI } from "../../../assets/icons/main/profile_title.svg";
import FeedDetailStore from "../../../store/FeedDetailStore";
import MainStore from "../../../store/MainStore";
import MyHomeStore from "../../../store/MyHomeStore";
import PostStore from "../../../store/PostStore";
import SearchStore from "../../../store/SearchStore";
import TagStore from "../../../store/TagStore";
import { FeedPost } from "../../feed/presenter/FeedPost";
import MainMagazine from "./MainMagazine";
import TodayProduct from "./TodayProduct";
import PopularQnaItem from "./PopularQnaItem";
import TodayBrand from "./TodayBrand";
import TodayArticle from "./TodayArticle";
import PopularTagItem from "./PopularTagItem";
import PopularRankItem from "./PopularRankItem";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import "./MainSectionItem.scss";

const MainSectionItem = memo(
  ({
    item,
    index,
    visible,
  }: {
    item: MainItem;
    index: number;
    visible: boolean;
  }) => {
    const PUBLIC_BUCKET_URL: string =
      process.env.REACT_APP_PUBLIC_BUCKET_URL || "";
    const ASSET_IMAGE_BG_ROUND = "/ASSETS/bg_section_top_round.png";

    const { t } = useTranslation();

    const mainListIndex = index;

    const handlePostUpdate = useCallback(
      (
        feedId: number,
        actionType: UserActTypeCode,
        sectionIndex: number,
        itemIndex: number
      ) => {
        MainStore.updateMainList(actionType, itemIndex, sectionIndex); //MainStore
        MainStore.updateMainPopularList(actionType, feedId); //MainStore
        PostStore.updatePostStoreList(actionType, feedId); // PostStore
        FeedDetailStore.updatePostInAllFeedListStore(actionType, feedId); // FeedDetailStore
        MyHomeStore.sync(feedId, AccountFeedTypeCode.POST, actionType);
        TagStore.sync(feedId, FeedTypeCode.POST, actionType);
        SearchStore.syncPost(feedId, actionType);
      },
      []
    );

    const backgroundImage = `${PUBLIC_BUCKET_URL}${ASSET_IMAGE_BG_ROUND}?${dayjs().format(
      "YYYYMMDDHHmmss"
    )}`;

    return (
      <div id="MainSectionItem">
        {item.sort === MainFeedTypeCode.MAIN_POST &&
          item.data &&
          item.data.length > 0 &&
          item.data?.map((post: Post, _index: number) => (
            <div key={"MAIN_POST" + post.feedId.toString()}>
              <FeedPost
                key={"main" + post.feedId.toString()}
                parentType={"MAIN_TAB_VERTICAL_INFINITE_POSTS"}
                post={post}
                sectionIndex={mainListIndex}
                itemIndex={_index}
                updatePost={handlePostUpdate}
                visible={visible}
                isMainSection={true}
              />
            </div>
          ))}
        {item.sort === MainFeedTypeCode.MAIN_POST_SINGLE &&
          item.data &&
          item.data.length === 1 && (
            <FeedPost
              key={"MAIN_POST_SINGLE" + item.data[0].feedId}
              parentType={"MAIN_TAB_VERTICAL_INFINITE_POSTS"}
              post={item.data[0] as Post}
              sectionIndex={mainListIndex}
              itemIndex={0}
              updatePost={handlePostUpdate}
              visible={visible}
              isMainSection={true}
            />
          )}

        {item.sort === MainFeedTypeCode.MAGAZINE &&
          item.data &&
          item.data.length > 0 && (
            <MainMagazine
              magazine={item.data[0]}
              // selfWidth={responsiveScreenWidth(100) - responsiveWidth(4.1) * 2}
              mainListIndex={mainListIndex}
              isMainSection={true}
            />
          )}

        {/* {item.sort === MainFeedTypeCode.TODAY_BRAND &&
          item.data &&
          item.data.length > 0 && <TodayBrand renderData={item.data} />}

        {item.sort === MainFeedTypeCode.TODAY_ARTICLE &&
          item.data &&
          item.data.length > 0 && (
            <TodayArticle disabledBrandName={false} renderData={item.data} />
          )} */}

        {item.sort === MainFeedTypeCode.TODAY_MALL_PRODUCT &&
          item.data &&
          item.data.length > 0 && (
            <TodayProduct
              renderData={item.data}
              searchTitle={false}
              isMainSection={true}
            />
          )}

        {/* {item.sort === MainFeedTypeCode.POPULAR_TAG &&
          item.data &&
          item.data.length > 0 && (
            <div className="popular_tag">
              <div className="title_wrap">
                <div>
                  <div className="popular_tag_title_text">
                    {t(`screen.main.label.popularTag`)}
                  </div>
                  <div className="title_background" />
                </div>
              </div>
              <div className="popular_tag_list">
                {item.data?.map((item: PopularTag, index: number) => (
                  <PopularTagItem
                    key={`popularTag_${index}_${item.tagId}`}
                    tag={item}
                    index={index}
                  />
                ))}
              </div>
            </div>
          )} */}

        {item.sort === MainFeedTypeCode.POPULAR_QNA &&
          item.data &&
          item.data.length > 0 && (
            <PopularQnaItem qnaList={item.data} isMainSection={true} />
          )}
      </div>
    );
  }
);

export default MainSectionItem;
MainSectionItem.displayName = "MainSectionItem";
