import { useEffect, useState } from 'react'
import { Arranges, Colors, FlexBoxs } from '../../../assets/styles'
import { TitleHeader } from '../../../components/common'
import { HeaderItem } from '../../../constants/Header'
import { Styles } from '../../../assets/types/Style'
import {
  KakaoLinkInfo,
  onNicknameKakaoShareLink,
  onNicknameShareLink,
} from '../../../utils/share'
import { observer } from 'mobx-react'
import AuthStore from '../../../store/AuthStore'
import { ShareType } from '../../../constants/common/ShareType'
import { MissionShareRequest } from '../../../api/mission/model'
import { NotificationTypeCode } from '../../../constants/Notification.enum'
import dayjs from 'dayjs'
import { useTracker } from '../../../hooks/tracker'
import FastImageWithFallback from '../../../components/common/FastImageWithFallback'
import { Trans, useTranslation } from 'react-i18next'
import { ReactComponent as ArrowRightBlackIcon } from '../../../assets/icons/arrow_right_black_friend.svg'
import { ReactComponent as ArrowRightWhiteIcon } from '../../../assets/icons/arrow_right_white_friend.svg'
import { ReactComponent as TenVoltIcon } from '../../../assets/icons/icon_friend_10volt.svg'
import { ReactComponent as CoffeeIcon } from '../../../assets/icons/icon_friend_coffee.svg'
import { ReactComponent as ElevenVoltIcon } from '../../../assets/icons/icon_friend_11volt.svg'
import { ReactComponent as NaverPayIcon } from '../../../assets/icons/icon_friend_naverpay.svg'

const FriendRecommendScreen = observer(() => {
  const [tracker] = useState(useTracker())
  const nickname = AuthStore.sessionUser?.nickname ?? ''
  const { t } = useTranslation()

  const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL)
  const prizeImg =
    PUBLIC_BUCKET_URL +
    '/RECOMMEND/friend_recommend_202411.png?' +
    dayjs().format('YYYYMMDDHH')
  const kakao_og_img = '/RECOMMEND/friend_og_202411.png'

  const shareRequest = {} as MissionShareRequest
  shareRequest.shareType = ''
  shareRequest.missionId = 0
  shareRequest.missionTypeCode = 'Friend_Recommend'

  const shareInfo: KakaoLinkInfo = {
    title: `추천인 : ${nickname}`,
    descriptionText: `추천한 친구 닉네임을 입력하고 가입하면, 친구도 나도 모두 선물 득템`,
    screen: NotificationTypeCode.MEMBER_JOIN, //메인화면으로 이동 처리를 위함
    imageUrl: kakao_og_img,
    buttonName: '신규 가입하고 선물받기',
  }

  useEffect(() => {
    tracker.track('view_friend_recommend')
  }, [tracker])

  const onKakaoShare = () => {
    shareRequest.shareType = ShareType.KAKAO
    void onNicknameKakaoShareLink(shareInfo, nickname, shareRequest)
  }

  const onLinkShare = () => {
    shareRequest.shareType = ShareType.URL
    void onNicknameShareLink(shareInfo, nickname, shareRequest)
  }

  return (
    <>
      <div style={styles.view_header} />
      <TitleHeader
        title={'친구 초대 챌린지'}
        rightItem={HeaderItem.CLOSE}
        isBack={false}
      />
      <div style={{ display: 'flex' }}>
        <FastImageWithFallback
          style={{ ...styles.prize_img }}
          source={{ uri: prizeImg }}
        />
      </div>

      <div style={styles.share_view}>
        <div
          onClick={onKakaoShare}
          style={{
            ...styles.share_button,
            width: '100%',
            backgroundColor: '#FDE006',
            marginTop: 20,
            marginBottom: 12,
          }}
        >
          <span style={{ ...styles.share_button_text, color: '#222222' }}>
            {t('screen.FriendRecommend.label.invite_kakao')}
          </span>
          <ArrowRightBlackIcon />
        </div>
        <div
          onClick={onLinkShare}
          style={{
            ...styles.share_button,
            backgroundColor: '#A350EA',
            marginBottom: 40,
            width: '100%',
          }}
        >
          <span style={{ ...styles.share_button_text, color: 'white' }}>
            {t('screen.FriendRecommend.label.invite_link')}
          </span>
          <ArrowRightWhiteIcon />
        </div>
      </div>

      <div
        style={{
          paddingLeft: 18,
          paddingRight: 18,
          paddingTop: 40,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <span
          style={{
            fontSize: 26,
            fontWeight: 700,
            color: '#222222',
            textAlign: 'center',
            letterSpacing: -0.52,
            // lineHeight: 33.8,
            wordWrap: 'break-word',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Trans>{t('screen.FriendRecommend.message.reward_title')}</Trans>
        </span>
        <div style={styles.reward_title_circle}>
          <span
            style={{
              color: 'white',
              fontWeight: 700,
              fontSize: 18,
              textAlign: 'center',
            }}
          >
            <Trans>{t('screen.FriendRecommend.label.reward1')}</Trans>
          </span>
        </div>
        <div style={styles.reward_detail_section}>
          <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <span style={styles.reward_title_text}>
              <Trans>{t('screen.FriendRecommend.label.reward1_1')}</Trans>
            </span>
            <div style={styles.reward_icon_section}>
              <TenVoltIcon width={110} height={110} />
              <div style={styles.reward_bottom}>
                <span style={styles.reward_bottom_text}>
                  <Trans>
                    {t('screen.FriendRecommend.label.reward1_1_bottom')}
                  </Trans>
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              width: 1,
              height: 190,
              backgroundColor: 'black',
              opacity: 0.1,
            }}
          />
          <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <span style={styles.reward_title_text}>
              <Trans>{t('screen.FriendRecommend.label.reward1_2')}</Trans>
            </span>
            <div style={styles.reward_icon_section}>
              <CoffeeIcon width={70.0465} height={123.67675} />
              <div style={styles.reward_bottom}>
                <span style={styles.reward_bottom_text}>
                  <Trans>
                    {t('screen.FriendRecommend.label.reward1_2_bottom')}
                  </Trans>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            alignItems: 'center',
            marginTop: 10,
            marginBottom: 33,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <span
            style={{
              fontSize: 14,
              letterSpacing: -0.28,
              // lineHeight: 20,
              textAlign: 'center',
            }}
          >
            <Trans>{t('screen.FriendRecommend.message.reward1_noti_1')}</Trans>
          </span>
          <span
            style={{
              fontSize: 14,
              letterSpacing: -0.28,
              // lineHeight: 20,
              textAlign: 'center',
            }}
          >
            <Trans
              components={{ strong: <span style={{ fontWeight: 'bold' }} /> }}
            >
              {t('screen.FriendRecommend.message.reward1_noti_2')}
            </Trans>
          </span>
        </div>

        <div style={styles.reward_title_circle}>
          <span
            style={{
              color: 'white',
              fontWeight: 700,
              fontSize: 18,
              textAlign: 'center',
            }}
          >
            <Trans>{t('screen.FriendRecommend.label.reward2')}</Trans>
          </span>
        </div>
        <div style={styles.reward_detail_section}>
          <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <span style={styles.reward_title_text}>
              <Trans>{t('screen.FriendRecommend.label.reward2_1')}</Trans>
            </span>
            <div style={styles.reward_icon_section}>
              <ElevenVoltIcon width={110} height={110} />
              <div style={styles.reward_bottom}>
                <span style={styles.reward_bottom_text}>
                  <Trans>
                    {t('screen.FriendRecommend.label.reward2_1_bottom')}
                  </Trans>
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              width: 1,
              height: 190,
              backgroundColor: 'black',
              opacity: 0.1,
            }}
          ></div>
          <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <span style={styles.reward_title_text}>
              <Trans>{t('screen.FriendRecommend.label.reward2_2')}</Trans>
            </span>

            <div style={{ ...styles.reward_icon_section, paddingBottom: 12 }}>
              <NaverPayIcon width={115.2} height={72} />
              <div style={styles.reward_bottom}>
                <span style={styles.reward_bottom_text}>
                  {t('screen.FriendRecommend.label.reward2_2_bottom')}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            alignItems: 'center',
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <span
            style={{
              fontSize: 14,
              letterSpacing: -0.28,
              // lineHeight: 20,
              textAlign: 'center',
            }}
          >
            <Trans
              components={{ strong: <span style={{ fontWeight: 'bold' }} /> }}
            >
              {t('screen.FriendRecommend.message.reward2_noti')}
            </Trans>
          </span>
        </div>
      </div>

      <div style={styles.noti_view}>
        <div style={{ marginTop: 10 }}>
          <span style={styles.noti_txt1}>
            {t('screen.FriendRecommend.label.noti_title')}
          </span>
        </div>
        <div style={{ marginTop: 10, marginLeft: 10 }}>
          <div style={styles.noti_view_item}>
            <span style={styles.noti_txt2}>{'• '}</span>
            <span style={styles.noti_txt2}>
              {t('screen.FriendRecommend.message.noti_text1')}
            </span>
          </div>
          <div style={styles.noti_view_item}>
            <span style={styles.noti_txt2}>{'• '}</span>
            <span>
              <span style={styles.noti_txt2}>
                <Trans>{t('screen.FriendRecommend.message.noti_text2')}</Trans>
              </span>
              <span style={styles.noti_txt2_bold}>
                <Trans>
                  {t('screen.FriendRecommend.message.noti_text2_1')}
                </Trans>
              </span>
              <span style={styles.noti_txt2}>
                <Trans>
                  {t('screen.FriendRecommend.message.noti_text2_2')}
                </Trans>
              </span>
              <span style={styles.noti_txt2_bold}>
                <Trans>
                  {t('screen.FriendRecommend.message.noti_text2_3')}
                </Trans>
              </span>
              <span style={styles.noti_txt2}>
                <Trans>
                  {t('screen.FriendRecommend.message.noti_text2_4')}
                </Trans>
              </span>
            </span>
          </div>
          <div style={styles.noti_view_item}>
            <span style={styles.noti_txt2}>{'• '}</span>
            <span>
              <span style={styles.noti_txt2}>
                {t('screen.FriendRecommend.message.noti_text3')}
              </span>
              <span style={styles.noti_txt2_bold}>
                {t('screen.FriendRecommend.message.noti_text3_1')}
              </span>
              <span style={styles.noti_txt2}>
                {t('screen.FriendRecommend.message.noti_text3_2')}
              </span>
            </span>
          </div>
          <div style={styles.noti_view_item}>
            <span style={styles.noti_txt2}>{'• '}</span>
            <span>
              <span style={styles.noti_txt2}>
                {t('screen.FriendRecommend.message.noti_text4')}
              </span>
            </span>
          </div>
        </div>
      </div>
    </>
  )
})

export default FriendRecommendScreen

const styles: Styles = {
  reward_title_circle: {
    width: 200,
    marginTop: 24,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: '#A350EA',
    borderRadius: 9999,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  reward_detail_section: {
    zIndex: -1,
    marginTop: -20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 40,
    paddingBottom: 40,
    backgroundColor: '#F4F4F4',
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    gap: 20,
  },
  reward_title_text: {
    marginHorizontal: -5,
    color: '#222',
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: -0.36,
    marginBottom: 12,
    // lineHeight: 23.4,
  },
  reward_icon_section: {
    width: '100%',
    aspectRatio: 1,
    borderRadius: 616,
    display: 'flex',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
  },
  reward_bottom: {
    height: 37,
    backgroundColor: '#222',
    opacity: 0.8,
    width: '100%',
    paddingTop: 5,
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  reward_bottom_text: {
    width: '100%',
    color: 'white',
    textAlign: 'center',
    fontSize: 15,
    fontWeight: 'bold',
    letterSpacing: -0.6,
  },
  share_button: {
    padding: 16,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  share_button_text: {
    fontWeight: 700,
    fontSize: 16,
    letterSpacing: -0.64,
  },
  view_header: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Colors.background_fff,
  },
  wrap: {
    ...FlexBoxs.flex_1,
  },
  header_view: {
    height: 92,
    letterSpacing: -1.28,
  },
  header_txt: {
    marginTop: 25,
    fontSize: 40,
    fontWeight: 'bold',
    fontStyle: 'normal',
    letterSpacing: -2.28,
    textAlign: 'center',
    color: '#6d26c0',
    fontFamily: 'Malgun Gothic',
  },
  guide_view: {
    marginTop: 40,
  },
  guide_txt: {
    fontSize: 16,
    fontWeight: 'bold',
    fontStyle: 'normal',
    letterSpacing: -0.64,
    textAlign: 'center',
    color: '#222222',
  },
  guide_txt2: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: -0.56,
    textAlign: 'center',
    color: '#666666',
  },
  guide_txt3: {
    marginTop: 2,
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: -0.56,
    textAlign: 'center',
    color: '#222222',
  },
  prize_img: {
    width: '100%',
    height: '100%',
  },
  date_txt1: {
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: -0.14,
    textAlign: 'center',
    color: '#6c2773',
  },
  date_txt2: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 'bold',
    letterSpacing: -0.14,
    textAlign: 'center',
    color: '#BE69C2',
  },
  date_sub_txt2: {
    marginTop: 10,
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: -0.48,
    paddingLeft: 75,
    color: '#000000',
  },
  share_view: {
    height: 188,
    backgroundColor: '#F4E8FF',
    paddingLeft: 16,
    paddingRight: 16,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...FlexBoxs.vertical,
  },
  noti_view: {
    marginTop: 30,
    paddingBottom: 40,
    paddingTop: 30,
    paddingLeft: 26,
    paddingRight: 26,
    backgroundColor: '#e9e9e9',
  },
  noti_txt1: {
    fontSize: 14,
    fontWeight: 'bold',
    fontStyle: 'normal',
    letterSpacing: -0.56,
    textAlign: 'left',
    color: '#666666',
  },
  noti_txt2: {
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: -0.48,
    textAlign: 'left',
    color: '#222222',
  },
}
