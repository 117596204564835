import { t } from 'i18next'
import { observer } from 'mobx-react'
import { TitleHeader } from '../../components/common'
import { TermsTypeCode } from '../../constants/Terms.enum'
import { ReactComponent as ArrowRightBlack } from '../../assets/icons/arrow_right_black.svg'
import { Item, Section } from './types'
import { goTo } from '../../hooks/navigate/NavigateFunction'
import classes from './TermsSettingScreen.module.scss'
import clsx from 'clsx'
import { GroupedVirtuoso } from 'react-virtuoso'
import { useCallback, useMemo, useState } from 'react'
import TermsOfUseModal, {
  TermsDetailProps,
} from '../../components/modals/TermsOfUseModal'

const TermsSettingScreen = observer(() => {
  const defaultTermsDetailProps = useMemo(
    () => ({
      show: false,
      termsId: '',
      termsTypeCode: '',
      onClose: () => {},
    }),
    []
  )

  const [termsDetailProps, setTermsDetailProps] = useState<TermsDetailProps>(
    defaultTermsDetailProps
  )

  const onCloseTermsOfUseModal = useMemo(() => {
    return () => setTermsDetailProps(defaultTermsDetailProps)
  }, [defaultTermsDetailProps])

  const MENUS: Section[] = useMemo(
    () => [
      {
        data: [
          {
            accessibilityLabel: 'MandatoryTerms',
            label: t('screen.setting.label.mandatoryterms'),
            touchDisabled: true,
            textStyle: {
              color: '#222222',
              fontSize: 18,
              letterSpacing: -0.72,
              fontWeight: 'bold',
            },
          },
          {
            accessibilityLabel: 'Privacy',
            onPress: () => {
              setTermsDetailProps({
                show: true,
                termsId: '',
                termsTypeCode: TermsTypeCode.PRIVACY_DETAIL,
                onClose: () => onCloseTermsOfUseModal(),
              })
            },
            label: t('screen.setting.label.privacy'),
          },
          {
            accessibilityLabel: 'Service',
            onPress: () => {
              setTermsDetailProps({
                show: true,
                termsId: '',
                termsTypeCode: TermsTypeCode.SERVICE,
                onClose: () => onCloseTermsOfUseModal(),
              })
            },
            label: t('screen.setting.label.service'),
          },
        ],
      },
      // TODO: 220몰 오픈시 활성화
      // {
      //   data: [
      //     {
      //       accessibilityLabel: 'ServiceTerms',
      //       label: t('screen.setting.label.additionalterms'),
      //       touchDisabled: true,
      //       textStyle: {
      //         color: '#222222',
      //         fontSize: 18,
      //         letterSpacing: -0.72,
      //         fontWeight: 'bold',
      //       },
      //     },
      //     {
      //       accessibilityLabel: '220MallTerms',
      //       screenName: 'ServiceTermsScreen',
      //       screenParam: { service: '220mall' },
      //       label: '220mall',
      //     },
      //   ],
      // },
    ],
    [onCloseTermsOfUseModal]
  )

  const groupCounts = useMemo(
    () => MENUS.flatMap((menu) => menu.data.length),
    [MENUS]
  )

  const renderSection = useCallback((index: number) => {
    if (index === 0) return <div></div>
    else return <div className={classes.line} />
  }, [])

  const getListItem = useCallback(
    (index: number, groupIndex: number): Item => {
      // index 가 그룹 내의 인덱스가 아니라 데이터 전체의 인덱스로 계산되는것 같음
      var reduceCount = 0
      MENUS.forEach((section, sectionIndex) => {
        if (sectionIndex < groupIndex) {
          reduceCount += section.data.length
        }
      })
      return MENUS[groupIndex].data[index - reduceCount]
    },
    [MENUS]
  )

  const renderItem = useCallback(
    (index: number, groupIndex: number) => {
      const item = getListItem(index, groupIndex)
      if (!item) return <div></div>

      const onClick = () => {
        if (item?.onPress) {
          item.onPress()
        } else {
          item.screenName && goTo(`/${item.screenName}`, item.screenParam)
        }
      }

      return (
        <div
          className={clsx(
            classes.option_box,
            !item.touchDisabled && classes.option_box_border
          )}
          aria-label={item.accessibilityLabel}
        >
          <button
            className={classes.option}
            onClick={onClick}
            disabled={!!item.touchDisabled}
          >
            <span style={item.textStyle} className={classes.option_txt}>
              {item.label}
            </span>
            {item.desc && (
              <span className={classes.version_info}>{item.desc}</span>
            )}
            {(item.screenName || item.onPress) && <ArrowRightBlack />}
          </button>
        </div>
      )
    },
    [getListItem]
  )

  return (
    <div className={classes.setting} aria-label="Setting">
      <TitleHeader title={'약관 및 정책'} isBack={true} />
      <GroupedVirtuoso
        groupCounts={groupCounts}
        groupContent={renderSection}
        itemContent={renderItem}
        useWindowScroll
        components={{ Footer: () => renderSection(1) }}
      />

      <TermsOfUseModal termsDetailProps={termsDetailProps} />
    </div>
  )
})
export default TermsSettingScreen
