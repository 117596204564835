import { useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  fetchAndActivate,
  getRemoteConfig,
  getString,
  getNumber,
} from "firebase/remote-config";
import MainStore from "../store/MainStore";
import RemoteConfigStore from "../store/RemoteConfigStore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: `cordncode-${process.env.REACT_APP_NODE_ENV}.firebaseapp.com`,
  projectId: `cordncode-${process.env.REACT_APP_NODE_ENV}`,
  storageBucket: `cordncode-${process.env.REACT_APP_NODE_ENV}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);

export const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 300000;

export const fetchAndSetRemoteConfig = new Promise((resolve) => {
  const fetchRemoteConfig = async () => {
    await fetchAndActivate(remoteConfig);
    RemoteConfigStore.setUseRemoteConfigs(remoteConfig);
  };
  fetchRemoteConfig().finally(() => {
    resolve(true);
  });
});

export const getStringRemoteConfig = (key: string) => {
  return getString(remoteConfig, key);
};

export const getNumberRemoteConfig = (key: string) => {
  return getNumber(remoteConfig, key);
};
