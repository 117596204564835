import { action, computed, makeObservable, observable } from "mobx";
import {
  FileUrlRequest,
  FileUrlResponse,
  PreSignedUrlRequestForAllPath,
  PreSignedUrlResponseForAllPath,
  UploadFile,
} from "../../api/file/model";
import { BucketTypeCode, FileType } from "../../constants/File";
import { FeedMode, FeedTypeCode, UploadTypeCode } from "../../constants/Feed";
import {
  getFilePreSignedUrl,
  getPreSignedUrlForAllPath,
} from "../../api/file/api";
import { PostModifyUIFile } from "../PostModifyStore";
import {
  UploadFileType,
  UploadStatus,
} from "../../constants/upload/UploadStatus";
import { CustomError } from "../../utils/customError";
import imageCompression from "browser-image-compression";
import axios from "axios";

class UploadStore {
  constructor() {
    makeObservable(this);
  }

  @observable _uploadIdMap: Map<string, boolean> = new Map<string, boolean>();
  @observable _isUpdate = false;
  @observable _type = "";
  @observable _status = UploadStatus.COMPLETE;
  @observable _title = "";
  @observable _abortController?: AbortController;

  @action start = (type: string, title: string, isUpdate?: boolean) => {
    this._isUpdate = isUpdate ?? false;
    this._type = type;
    this._title = title;
    this._status = UploadStatus.COMPRESS;
  };

  @action uploading = () => {
    this._status = UploadStatus.UPLOADING;
  };

  @action fail = () => {
    this.abortController?.abort();
    this.clearAbortController();
    this._uploadIdMap.clear();
    this._status = UploadStatus.FAIL;
  };

  @action complete = () => {
    this._uploadIdMap.clear();
    this._status = UploadStatus.COMPLETE;
  };

  @action stop = () => {
    this._type = "";
    this._title = "";
    this._status = UploadStatus.STOP;

    try {
      this._uploadIdMap.forEach((value, key) => {
        if (!value) {
          // void RNBackgroundUpload.cancelUpload(key)
        }
      });
    } catch (e) {
      //
    }
  };

  @action setTitle = (title: string) => {
    this._title = title;
  };

  @action setAbortController = (abortController: AbortController) => {
    this._abortController = abortController;
  };

  @action clearAbortController = () => {
    this._abortController = undefined;
  };

  @action clear = () => {
    this._type = "";
    this._title = "";
    this._status = UploadStatus.COMPLETE;
    this.clearAbortController();
    this.stop();
    this._uploadIdMap.clear();
  };

  @computed get isUploading() {
    return (
      this.status === UploadStatus.COMPRESS ||
      this.status === UploadStatus.UPLOADING
    );
  }

  @computed get status() {
    return this._status;
  }

  @computed get title() {
    return this._title;
  }

  @computed get type() {
    return this._type;
  }

  @computed get abortController() {
    return this._abortController;
  }

  @computed get isDefaultStatus() {
    return (
      this._status === UploadStatus.STOP ||
      this._status === UploadStatus.COMPLETE
    );
  }

  @computed get isProcessing() {
    return (
      this._status === UploadStatus.COMPRESS ||
      this._status === UploadStatus.UPLOADING
    );
  }

  @computed get isFail() {
    return this._status === UploadStatus.FAIL;
  }

  @computed get isStop() {
    return this._status === UploadStatus.STOP;
  }

  @computed get isUpdate() {
    return this._isUpdate;
  }

  @action uploadFileByLocalUri = async (
    file: UploadFile,
    preSignedUrl: string,
    uploadFileType: string
  ) => {
    const uploadType = file.fileType.toLocaleLowerCase();
    let contentType = `${uploadType}/${file.fileExtension}`;
    let filePath;

    if (uploadFileType === UploadFileType.VIDEO) {
      filePath = file.videoTempPath;
    }
    if (uploadFileType === UploadFileType.IMAGE) {
      filePath = file.imageTempPath;
      contentType = `${FileType.IMAGE.toLocaleLowerCase()}/jpg`;
    }
    if (uploadFileType === UploadFileType.THUMBNAIL) {
      filePath = file.thumbnailTempPath;
      contentType = `${FileType.IMAGE.toLocaleLowerCase()}/jpg`;
    }

    if (!filePath) {
      return { ok: false };
    }

    // if (Platform.OS == "android") {
    //   if (filePath.includes("file://")) {
    //     filePath = filePath.replace("file://", "");
    //   }
    // } else {
    //   if (!filePath.includes("file://")) {
    //     filePath = "file://" + filePath;
    //   }
    // }

    const blob = this.b64toBlob(
      filePath,
      `${file.fileType}/${file.fileExtension}`
    );
    try {
      fetch(preSignedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": `${contentType}; charset=utf-8`,
        },
        body: blob,
      });

      return { ok: true };
    } catch (ex) {
      // @ts-ignore
      if (ex.error == "취소됨" || ex.error == "User cancelled upload") {
        throw CustomError(
          UploadStatus.STOP,
          "file:UploadStore.ts,func:uploadFileByLocalUri STOP"
        );
      } else {
        throw CustomError(
          UploadStatus.FAIL,
          "file: UploadStore.ts,func:uploadFileByLocalUri FAIL"
        );
      }
    }
  };

  @action uploadThumbNailByBase64 = async (
    file: UploadFile,
    preSignedUrl: string
  ) => {
    const contentType = `${FileType.IMAGE.toLocaleLowerCase()}/jpg`;

    return fetch(preSignedUrl, {
      method: "PUT",
      headers: {
        "Content-Type": `${contentType}; charset=utf-8`,
      },
      body: file.thumbnailTempPath,
    });
  };

  @action getPreSignedUrl = async (urlRequest: FileUrlRequest) => {
    const res = await getFilePreSignedUrl(urlRequest);
    let returnData: FileUrlResponse = {
      presignedUrl: "",
      filePath: "",
      fileThumbnailPath: "",
    };
    if (res != null && res.length > 0) {
      returnData = {
        presignedUrl: res[0].presignedUrl,
        filePath: res[0].filePath,
        fileThumbnailPath: res[0].fileThumbnailPath,
      };
    }
    return returnData;
  };

  @action getPreSignedUrlForAllPath = async (
    urlRequest: PreSignedUrlRequestForAllPath
  ) => {
    const res = await getPreSignedUrlForAllPath(urlRequest);
    let returnData: PreSignedUrlResponseForAllPath = {
      originalPreSignedUrl: "",
      thumbnailPreSignedUrl: "",
      originalFilePath: "",
      thumbnailFilePath: "",
    };
    if (res != null) {
      returnData = {
        originalPreSignedUrl: res.originalPreSignedUrl ?? "",
        thumbnailPreSignedUrl: res.thumbnailPreSignedUrl ?? "",
        originalFilePath: res.originalFilePath ?? "",
        thumbnailFilePath: res.thumbnailFilePath ?? "",
      };
    }
    return returnData;
  };

  uploadFileToS3 = async (
    file: UploadFile | PostModifyUIFile,
    preSignedUrl: PreSignedUrlResponseForAllPath,
    uploadFileType: string,
    isThumbNail?: boolean
  ): Promise<{ ok: boolean }> => {
    if (
      preSignedUrl == null ||
      preSignedUrl.originalFilePath === "" ||
      preSignedUrl.thumbnailFilePath === ""
    ) {
      return { ok: false };
    }

    let uploadResult;
    if (isThumbNail) {
      uploadResult = await this.uploadFileByLocalUri(
        file,
        preSignedUrl.thumbnailPreSignedUrl,
        uploadFileType
      );
    } else {
      uploadResult = await this.uploadFileByLocalUri(
        file,
        preSignedUrl.originalPreSignedUrl,
        uploadFileType
      );
    }
    if (uploadResult?.ok) {
      return uploadResult;
    } else {
      return { ok: false };
    }
    // TODO YY 업로드 테스트
    // let formData = new FormData();

    // formData.append("file", this.b64toBlob(file.filePath));
    // formData.append("uploadFileType", uploadFileType);

    // if (isThumbNail) {
    //   formData.append("preSignedUrl", preSignedUrl.thumbnailPreSignedUrl);
    // } else {
    //   formData.append("preSignedUrl", preSignedUrl.originalPreSignedUrl);
    // }
    // uploadResult = await axios.post(
    //   `${process.env.REACT_APP_API_URL}/v1/file/fileUpload`,
    //   formData,
    //   { headers: { "Content-Type": "multipart/form-data" } }
    // );

    // if (uploadResult) {
    //   return { ok: true };
    // } else {
    //   return { ok: false };
    // }
  };

  createPreSignedUrlRequest = (
    originalFileName: string,
    thumbnailFileName: string,
    uploadTypeCode: string
  ) => {
    const urlRequest: PreSignedUrlRequestForAllPath = {
      bucketTypeCode: BucketTypeCode.PUBLIC,
      uploadTypeCode: uploadTypeCode,
      originalFileName: originalFileName,
      thumbnailFileName: thumbnailFileName,
    };
    return urlRequest;
  };

  compressByQueue = async (
    compressFiles: UploadFile[],
    mode?: FeedMode,
    size?: number
  ) => {
    if (!size) {
      size = 3;
    }
    if (!mode) {
      mode = FeedMode.WRITE;
    }

    const compressQueue = [];
    const resultQueue = [] as UploadFile[];
    for (let i = 0; i < compressFiles.length; i++) {
      compressQueue.push(this.compress(compressFiles[i], mode));
      if (i % size === size - 1) {
        const results = await Promise.all(compressQueue);
        resultQueue.push(...results);
        compressQueue.splice(0, compressQueue.length);
      }
    }
    if (compressQueue.length > 0) {
      const results = await Promise.all(compressQueue);
      resultQueue.push(...results);
    }
    return resultQueue;
  };

  compress = async (file: UploadFile, mode?: FeedMode) => {
    const abortController = this.abortController;

    if (!abortController) {
      return {} as UploadFile;
    }

    // asis
    // let needCompress = true;
    // if (mode && mode === FeedMode.MODIFY) {
    //   needCompress = file.modified ?? false;
    // }
    // if (!needCompress) {
    //   return file;
    // }
    // tobe
    if (
      mode &&
      mode === FeedMode.MODIFY &&
      String(file.thumbnailFilePath).indexOf("/THUMBNAIL/FEED/") > -1
    ) {
      // 포스트 수정 시 기존 파일의 경우 압축 하지 않음
      return file;
    }

    //이미지 처리
    if (file.fileType === "IMAGE" && !file.imageTempPath) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 720,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(
          await imageCompression.getFilefromDataUrl(
            file.filePath,
            file.originalFileName
          ),
          options
        );
        const b64 = await imageCompression.getDataUrlFromFile(compressedFile);
        file.imageTempPath = b64;
        file.fileExtension = compressedFile.type.split("/")[1];
        file.isCompressed = true;
      } catch (error) {
        return {} as UploadFile;
      }
    }

    //영상처리
    if (
      file.fileType === "VIDEO" &&
      !file.isCompressed &&
      !file.videoTempPath
    ) {
      file.videoTempPath = file.filePath;
      file.isCompressed = true;
    }
    return file;
  };

  // 안드로이드의 경우 백그라운드 업로드 진행시 알림 처리가 필수
  // 현재 백그라운드 업로드 라이브러리가 1건당 1개의 노티를 하는 구조여서 동시 다중 업로드시 노티가 건수 만큼 발생.
  // 위 이슈로 안드로이드의 경우 동시처리 진행하지 않고 순차 업로드 진행으로 변경함.
  putFileToS3ByQueue = async (
    uploadFiles: UploadFile[],
    uploadTypeCode: UploadTypeCode,
    mode?: FeedMode,
    size?: number // 비동기 동시 처리 업로드 최대 사이즈
  ) => {
    // if (Platform.OS === 'android') {
    //   size = 1
    // }
    if (!size) {
      size = 4;
    }
    if (!mode) {
      mode = FeedMode.WRITE;
    }

    const uploadQueue = [];
    const resultQueue = [] as UploadFile[];

    for (let i = 0; i < uploadFiles.length; i++) {
      uploadQueue.push(
        this.getFilePathByPutS3(uploadFiles[i], uploadTypeCode, mode)
      );
      if (i % size === size - 1) {
        const results = await Promise.all(uploadQueue);
        results && resultQueue.push(...results);
        uploadQueue.splice(0, uploadQueue.length);
      }
    }
    if (uploadQueue.length > 0) {
      const results = await Promise.all(uploadQueue);
      resultQueue.push(...results);
    }
    return resultQueue;
  };

  putFilesToS3 = (
    files: UploadFile[],
    uploadTypeCode: string,
    mode?: FeedMode
  ) => {
    return files.map(async (file, fileIndex) =>
      this.putFileToS3(file, uploadTypeCode, mode)
    );
  };

  //비동기 업로드시 네트워크 상황이 좋지 않을 경우 안정성이 떨어져 동기 업로드로 변경함.
  putFileToS3 = async (
    file: UploadFile,
    uploadTypeCode: string,
    mode?: FeedMode
  ) => {
    // 수정일 경우 체크
    let needUpload = true;
    if (mode && mode === FeedMode.MODIFY) {
      needUpload = file.modified ?? false;
    }
    if (!needUpload) {
      return true;
    }

    if (file.filePath.trim().length <= 0 || file.fileSize <= 0) {
      return false;
    }

    const isVideo = file.fileType.toUpperCase() === FileType.VIDEO;
    const isImage = file.fileType.toUpperCase() === FileType.IMAGE;

    let originalFileName = file.fileName;
    if (isVideo) {
      originalFileName = file.fileName;
    }
    if (isImage) {
      originalFileName = file.fileName + "." + file.fileExtension;
    }

    const thumbnailFileName = originalFileName.replace(
      "." + file.fileExtension,
      "_thumb.jpg"
    );

    const request = this.createPreSignedUrlRequest(
      originalFileName,
      thumbnailFileName,
      uploadTypeCode
    );
    const response = await this.getPreSignedUrlForAllPath(request);

    // 1.이미지 업로드 case
    if (isImage) {
      const [imageResponse, thumbNailResponse] = await Promise.all([
        this.uploadFileToS3(file, response, UploadFileType.IMAGE),
        this.uploadFileToS3(file, response, UploadFileType.THUMBNAIL, true),
      ]);

      if (!imageResponse.ok || !thumbNailResponse.ok) {
        return false;
      }

      file.filePath = response.originalFilePath;
      file.thumbnailFilePath = response.thumbnailFilePath;
    }

    // 2.영상 업로드 case
    // 썸네일 이미지와 영상 파일을 각각 업로드함.
    if (isVideo) {
      const [videoResponse, thumbNailResponse] = await Promise.all([
        this.uploadFileToS3(file, response, UploadFileType.VIDEO), // video upload
        this.uploadFileToS3(file, response, UploadFileType.THUMBNAIL, true), //video thumbnail upload
      ]);

      if (!thumbNailResponse.ok || !videoResponse.ok) {
        return false;
      }

      file.filePath = response.originalFilePath;
      file.thumbnailFilePath = response.thumbnailFilePath;
    }

    return true;
  };

  getFilePathByPutS3 = async (
    file: UploadFile,
    uploadTypeCode: string,
    mode?: FeedMode
  ): Promise<UploadFile> => {
    // 수정일 경우 체크
    // asis
    // let needUpload = true;
    // if (mode && mode === FeedMode.MODIFY) {
    //   needUpload = file.modified ?? false;
    // }
    // if (!needUpload) {
    //   return file;
    // }
    // tobe
    if (
      mode &&
      mode === FeedMode.MODIFY &&
      String(file.thumbnailFilePath).indexOf("/THUMBNAIL/FEED/") > -1
    ) {
      // 포스트 수정 시 기존 파일의 경우 압축 하지 않음
      return file;
    }

    if (file.filePath.trim().length <= 0 || file.fileSize <= 0) {
      return {} as UploadFile;
    }

    const isVideo = file.fileType.toUpperCase() === FileType.VIDEO;
    const isImage = file.fileType.toUpperCase() === FileType.IMAGE;

    let originalFileName = file.fileName;
    // if (isVideo) {
    //   originalFileName = file.fileName;
    // }
    // if (isImage) {
    //   originalFileName = file.fileName + "." + file.fileExtension;
    // }
    originalFileName = file.fileName + "." + file.fileExtension;

    const thumbnailFileName = originalFileName.replace(
      "." + file.fileExtension,
      "_thumb.jpg"
    );

    const request = this.createPreSignedUrlRequest(
      originalFileName,
      thumbnailFileName,
      uploadTypeCode
    );
    const response = await this.getPreSignedUrlForAllPath(request);

    let fileType;

    if (isImage) fileType = UploadFileType.IMAGE;
    if (isVideo) fileType = UploadFileType.VIDEO;

    if (!fileType) {
      return {} as UploadFile;
    }

    let uploadResult;
    // if (isAndroid) {
    //   uploadResult = await this.sequentialUploadFileToS3(file, response, fileType).then((result) => result);
    // } else {
    //   uploadResult = await this.asyncUploadFileToS3(file, response, fileType).then((result) => result);
    // }
    uploadResult = await this.asyncUploadFileToS3(
      file,
      response,
      fileType
    ).then((result) => result);

    if (!uploadResult.ok) {
      return {} as UploadFile;
    }

    file.filePath = response.originalFilePath;
    file.thumbnailFilePath = response.thumbnailFilePath;

    return file;
  };

  sequentialUploadFileToS3 = async (
    file: UploadFile | PostModifyUIFile,
    preSignedUrl: PreSignedUrlResponseForAllPath,
    fileType: UploadFileType
  ) => {
    const response = await this.uploadFileToS3(file, preSignedUrl, fileType);
    if (!response.ok) {
      return { ok: false };
    }

    const thumbNailResponse = await this.uploadFileToS3(
      file,
      preSignedUrl,
      UploadFileType.THUMBNAIL,
      true
    );
    if (!thumbNailResponse.ok) {
      return { ok: false };
    }

    return { ok: true };
  };

  asyncUploadFileToS3 = async (
    file: UploadFile | PostModifyUIFile,
    preSignedUrl: PreSignedUrlResponseForAllPath,
    fileType: UploadFileType
  ) => {
    const [response, thumbNailResponse] = await Promise.all([
      this.uploadFileToS3(file, preSignedUrl, fileType), // video upload
      this.uploadFileToS3(file, preSignedUrl, UploadFileType.THUMBNAIL, true), //video thumbnail upload
    ]);

    if (!thumbNailResponse.ok || !response.ok) {
      return { ok: false };
    }

    return { ok: true };
  };

  validateQueue = (resultQueue: UploadFile[]) => {
    resultQueue.forEach((u) => {
      if (!u.fileName) {
        throw CustomError(
          UploadStatus.FAIL,
          "file:UploadStore.ts,func:validateQueue !u.fileName"
        );
      }

      if (u.fileName && u.fileName === "") {
        throw CustomError(
          UploadStatus.FAIL,
          "file:UploadStore.ts, unc:validateQueue"
        );
      }
    });
  };

  resolveUpload = (uploadId: string) => {
    return new Promise((resolve, reject) => {
      // const error = RNBackgroundUpload.addListener(
      //   'error',
      //   uploadId,
      //   (data: ErrorData) => {
      //     reject(data)
      //     cleanup()
      //   }
      // )
      // const cancel = RNBackgroundUpload.addListener(
      //   'cancelled',
      //   uploadId,
      //   (data: EventData) => {
      //     reject(data)
      //     cleanup()
      //   }
      // )
      // const complete = RNBackgroundUpload.addListener(
      //   'completed',
      //   uploadId,
      //   (data: CompletedData) => {
      //     resolve(data)
      //     this._uploadIdMap.set(uploadId, true)
      //     cleanup()
      //   }
      // )
      // RNBackgroundUpload.addListener(
      //   'progress',
      //   uploadId,
      //   (data: ProgressData) => {
      //     //console.log(data.progress);
      //   }
      // )
      // const cleanup = () => {
      //   error.remove()
      //   cancel.remove()
      //   complete.remove()
      // }
    });
  };

  getNotificationText = () => {
    if (this.type === FeedTypeCode.POST) {
      return "포스트";
    }
    if (this.type === FeedTypeCode.AB) {
      return "A/B";
    }
    if (this.type === FeedTypeCode.QNA) {
      return "Q&A";
    }
  };

  b64toBlob = (b64Data: string, contentType = "") => {
    const image_data = atob(b64Data.split(",")[1]); // data:image/gif;base64 필요없으니 떼주고, base64 인코딩을 풀어준다
    const arraybuffer = new ArrayBuffer(image_data.length);
    const view = new Uint8Array(arraybuffer);

    for (let i = 0; i < image_data.length; i++) {
      view[i] = image_data.charCodeAt(i) & 0xff;
      // charCodeAt() 메서드는 주어진 인덱스에 대한 UTF-16 코드를 나타내는 0부터 65535 사이의 정수를 반환
      // 비트연산자 & 와 0xff(255) 값은 숫자를 양수로 표현하기 위한 설정
    }

    return new Blob([arraybuffer], { type: contentType });
  };

  checkFileExtension = (fileList: FileList): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      [].forEach.call(fileList, (file: File) => {
        if (
          file.type.split("/")[0].toLocaleUpperCase() !== FileType.IMAGE &&
          file.type.split("/")[0].toLocaleUpperCase() !== FileType.VIDEO
        ) {
          throw new Error("checkFileExtension");
        }
      });
      resolve(true);
    });
  };

  checkFileSize = (fileList: FileList) => {
    const imageMaxSize = 1024 * 1024 * 10;
    const videoMaxSize = 1024 * 1024 * 100;

    return new Promise((resolve, reject) => {
      [].forEach.call(fileList, (file: File) => {
        if (
          (file.type.split("/")[0].toLocaleUpperCase() === FileType.IMAGE &&
            file.size > imageMaxSize) ||
          (file.type.split("/")[0].toLocaleUpperCase() === FileType.VIDEO &&
            file.size > videoMaxSize)
        ) {
          throw new Error("checkFileSize");
        }
      });
      resolve(true);
    });
  };
}

export default new UploadStore();
