import { useState, useEffect, useCallback, useRef } from 'react'
import {
  getTermsbyTermsId,
  getTermsVersions,
  getLastTermsDetail,
} from '../../api/terms/api'
import { TermsResult } from '../../api/terms/model'
import { useTranslation } from 'react-i18next'
import { TermsTypeCode } from '../../constants/Terms.enum'
import { ReactComponent as TopIcon } from '../../assets/icons/top_btn.svg'
// import HeaderLinearGradient from "../headers/HeaderLinearGradient";
import CustomActivityIndicator from '../common/CustomActivityIndicator'
import { HeaderItem } from '../../constants/Header'
import TitleHeader from '../common/TitleHeader'
import { TOP_OFFSET_Y } from '../../constants/CommonCode.enum'
// import { useBackHandler } from "@react-native-community/hooks";
import Modal from 'react-modal'
import classes from './TermsOfUseModal.module.scss'

export interface TermsDetailProps {
  show: boolean
  termsId: string
  termsTypeCode: string
  termsTitle?: string
  callback?: () => void
  onClose: () => void
}

const TermsOfUseModal = ({
  termsDetailProps,
}: {
  termsDetailProps: TermsDetailProps
}) => {
  const {
    show,
    termsId,
    termsTypeCode,
    termsTitle,
    callback,
    onClose,
  }: TermsDetailProps = termsDetailProps

  const { t } = useTranslation()
  const webViewRef = useRef<any>(null)
  const [isHeaderLinearGradientVisible, setIsHeaderLinearGradientVisible] =
    useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [versionHtml, setVersionHtml] = useState('')
  const [termsDetailContents, setTermsDetailContents] = useState<any>({
    html: ``,
  })
  const [showTopIcon, setShowTopIcon] = useState(false)

  const getTerms = useCallback(
    (termsId: string, versionHtml: string, termsTypeCode: string) => {
      const start = `
      <!DOCTYPE html>
      <html lang="en-US">
        <style>
        .link_history {display: inline-block;font-size: 12px;text-decoration: none;}
        .link_text {display: inline-block;margin-top: auto;margin-bottom: auto;}
        .ic_link {vertical-align:middle;margin-left: 4px;padding-bottom: 3px;}
        .space_40 {padding-top: 20px;padding-bottom: 20px;}
        </style>
        `
      const end = `</html>`

      if (termsId) {
        getTermsbyTermsId(termsId)
          .then((res) => {
            setDetail(res)
          })
          .catch(() => {
            setTermsDetailContents({ html: `` })
          })
      } else if (
        (!termsId || termsId.length === 0) &&
        termsTypeCode === TermsTypeCode.SERVICE
      ) {
        getLastTermsDetail(TermsTypeCode.SERVICE)
          .then((res) => {
            setDetail(res)
          })
          .catch(() => {
            setTermsDetailContents({ html: `` })
          })
      } else if (!termsId && termsTypeCode === TermsTypeCode.PRIVACY) {
        getLastTermsDetail(TermsTypeCode.PRIVACY)
          .then((res) => {
            setDetail(res)
          })
          .catch(() => {
            setTermsDetailContents({ html: `` })
          })
      } else if (
        (!termsId || termsId.length === 0) &&
        termsTypeCode === TermsTypeCode.PRIVACY_DETAIL
      ) {
        getLastTermsDetail(TermsTypeCode.PRIVACY_DETAIL)
          .then((res) => {
            setDetail(res)
          })
          .catch(() => {
            setTermsDetailContents({ html: `` })
          })
      }
      setIsLoading(false)
      const setDetail = (res: TermsResult) => {
        if (res != null) {
          setTermsDetailContents({
            html: `${start} ${res.termsDetailContents} ${versionHtml} ${end}`,
          })
        }
      }
    },
    []
  )

  useEffect(() => {
    setIsLoading(true)
    if (termsTypeCode !== TermsTypeCode.PRIVACY) {
      getTermsVersions(termsTypeCode)
        .then((res) => {
          let versions = `<footer>
                          <div class="g0">
                            <div class="b0">`
          if (termsTypeCode === TermsTypeCode.SERVICE) {
            versions = `
                <h3 class="t1">
                  ${t('screen.termsOfUseModal.label.termsHistoryTitle')}
                </h3>`
          } else if (termsTypeCode === TermsTypeCode.PRIVACY) {
            versions = `
                <h3 class="t1">
                  ${t('screen.personalInfoAgreeModal.label.termsHistoryTitle')}
                </h3>`
          } else if (termsTypeCode === TermsTypeCode.PRIVACY_DETAIL) {
            versions = `
                <h3 class="t1">
                  ${t('screen.personalInfoModal.label.termsHistoryTitle')}
                </h3>`
          }

          if (!termsTypeCode.startsWith(TermsTypeCode.PROMOTION)) {
            var title = t('screen.termsOfUseModal.label.termsHistoryDate')
            if (termsTypeCode.startsWith(TermsTypeCode.PRIVACY)) {
              title = t('screen.termsOfUseModal.label.privacyHistoryDate')
            }

            res.forEach((item) => {
              versions += `
            <div class="link_history"
            onclick="sendTermsId(${item.termsId})" ><p class="link_text">${title}${item.termsStartDate}</p>
              <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg" loading="lazy" class="ic_link">
              <path d="M3 6L8 11L3 16" stroke="#222222" stroke-opacity="0.85" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>`
            })
          }
          versions += `
            </div>
            <div class="space_40"></div>
          </div>
        </footer>`

          setVersionHtml(versions)
          getTerms(termsId, versions, termsTypeCode)
        })
        .catch(() => {
          getTerms(termsId, '', termsTypeCode)
        })
    } else {
      getTerms(termsId, '', termsTypeCode)
    }
  }, [termsId, termsTypeCode, t, getTerms])

  const appData = useCallback(
    (event: { data: string }) => {
      if (typeof event.data === 'string' && event.data.includes('type')) {
        const type: string = JSON.parse(event.data).type.toString()
        const payload: string = JSON.parse(event.data).payload.toString()
        switch (type) {
          case 'TERMS_ID':
            getTerms(payload, versionHtml, termsTypeCode)
            break
          case 'Y_OFFSET':
            if (!isHeaderLinearGradientVisible && Number(payload) > 0) {
              setIsHeaderLinearGradientVisible(true)
            } else if (isHeaderLinearGradientVisible && Number(payload) <= 0) {
              setIsHeaderLinearGradientVisible(false)
            }
            if (Number(payload) >= Number(TOP_OFFSET_Y)) {
              setShowTopIcon(true)
            } else {
              setShowTopIcon(false)
            }
            break
          default:
            break
        }
      }
    },
    [getTerms, isHeaderLinearGradientVisible, termsTypeCode, versionHtml]
  )

  useEffect(() => {
    const script = document.createElement('script')
    script.innerHTML = `function sendTermsId(termsId){
      window.postMessage(JSON.stringify({ type:'TERMS_ID', payload: termsId }));
    };
    document.addEventListener('scroll', function() {
      window.postMessage(JSON.stringify({ type:'Y_OFFSET', payload: pageYOffset }));
    });`
    script.type = 'text/javascript'
    script.async = true
    document.head.appendChild(script)

    window.onmessage = (event) => {
      appData(event)
    }
  }, [appData])

  function getTitle() {
    switch (termsTypeCode) {
      case TermsTypeCode.SERVICE:
        return t('screen.termsOfUseModal.label.title')
      case TermsTypeCode.PRIVACY:
        return t('screen.personalInfoAgreeModal.label.title')
      case TermsTypeCode.PROMOTION_SMS:
      case TermsTypeCode.PROMOTION_EMAIL:
        return t('screen.personalInfoMarketingAgreeModal.label.title')
      default:
        return t('screen.personalInfoModal.label.title')
    }
  }

  function close() {
    callback?.()
    onClose && onClose()
  }

  return (
    <Modal
      isOpen={show}
      style={{
        content: {
          padding: 0,
          border: 'none',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000,
          left: 'clamp(0px, calc(50% - 225px), 50vw)',
          maxWidth: 450,
        },
      }}
    >
      <div className={classes.modal_wrap}>
        <div className={classes.header_wrapper}>
          <TitleHeader
            title={termsTitle ?? getTitle()}
            isBack={false}
            rightItem={HeaderItem.CLOSE}
            onClickClose={close}
          />
        </div>
        {/* <HeaderLinearGradient top={5} visible={isHeaderLinearGradientVisible} /> */}
        <div
          ref={webViewRef}
          dangerouslySetInnerHTML={{ __html: termsDetailContents.html }}
          className={classes.web_view}
        />
        {isLoading && <CustomActivityIndicator isDarkSpinner={true} />}
        {showTopIcon && (
          <div
            className={classes.top_btn}
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
            }}
          >
            <TopIcon />
          </div>
        )}
      </div>
    </Modal>
  )
}

export default TermsOfUseModal
