import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Arranges,
  Colors,
  FlexBoxs,
  Positions,
  Spacings,
  Texts,
} from "../../../assets/styles";
// import { MaxLengthTextInput, TitleHeader } from "../../../components/common";
import { TitleHeader, MaxLengthTextInput } from "../../../components/common";
import "./QnaWrite.scss";

import CommonStore from "../../../store/CommonStore";
import QnaWriteStore, { QnaModifyModel } from "../../../store/QnaWriteStore";

import { FeedMode, POST_BLANK_FILE, QNA_BLANK } from "../../../constants/Feed";
import { observer } from "mobx-react";
import { QnaWriteTag, QnaWriteProduct, QnaWriteUsers } from "./index";
import { HeaderItem } from "../../../constants/Header";

import { ReactComponent as QnaPhoto } from "../../../assets/icons/qna_photo.svg";
import { ReactComponent as DeleteIcon32 } from "../../../assets/icons/close_purple_background_32.svg";
import { ReactComponent as TooltipArrow } from "../../../assets/icons/tooltip_arrow_left.svg";
import { ReactComponent as IconClose } from "../../../assets/icons/ic_tooltip_close.svg";

import { NotificationModal } from "../../../components/modals";
import { toHeight, UploadFile } from "../../../api/file/model";
import { useTracker } from "../../../hooks/tracker";
import NotificationModalWithHideCallback from "../../../components/modals/NotificationModalWithHideCallback";
import UploadStore from "../../../store/upload/UploadStore";
import {
  bottomOffset,
  getRelativeToParentPosition,
} from "../../../utils/common";
import { showBottomToast } from "../../../utils/Toast";
import { FileType } from "../../../constants/File";
import { goBack, goTo } from "../../../hooks/navigate/NavigateFunction";
import { LazyLoadImage } from "react-lazy-load-image-component";
import clsx from "clsx";
import ProductSearchScreen from "../../product/ProductSearchScreen";

const QnaWrite = observer(({ mode }: { mode: FeedMode }) => {
  const { t } = useTranslation();
  const titleInputRef = useRef<HTMLTextAreaElement>(null);
  const {
    wholeData,
    tempUploadFile,
    isValid,
    fileList,
    fileUpdated,
    isVisibleUserList,
    isVisibleTagList,
    tagList,
    userList,
    hasError,
    errorMessage,
    toastMessage,
    getUploadUrl,
    setError,
    clear,
    setTitle,
    setContent,
    saveQna,
    showNativeAlbum,
    deleteFile,
    setVisibleTagList,
    setVisibleUserList,
    showValidInfo,
  } = QnaWriteStore;
  const [scrolling, setScrolling] = useState(false);
  const [showConfirmPopup, setConfirmPopup] = useState(false);
  const [showSavePopup, setSavePopup] = useState(false);
  const [yPosition, setYPosition] = useState(0);
  const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;

  const scrollRef = useRef<Object[]>(null);
  const fileInputRef = useRef<any>(null);

  const bottomHeight = 56;
  const [tagInputY, setTagInputY] = useState(0);
  const [qnaUserInputY, setQnaUserInputY] = useState(0);
  const [qnaProductInputY, setQnaProductInputY] = useState(0);
  const [registering, setRegistering] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(true);

  // todo : keyboard 움직임
  // const showKeyboardActionName: KeyboardEventName = Platform.select({
  //   android: "keyboardDidShow",
  //   default: "keyboardWillShow",
  // });
  // const hideKeyboardActionName: KeyboardEventName = Platform.select({
  //   android: "keyboardDidHide",
  //   default: "keyboardWillHide",
  // });

  // const showKeyboardSubscription = Keyboard.addListener(
  //   showKeyboardActionName,
  //   () => {
  //     setBottomInset(0);
  //   }
  // );
  // const hideKeyboardSubscription = Keyboard.addListener(
  //   hideKeyboardActionName,
  //   () => {
  //     setBottomInset(insets.bottom / PixelRatio.get());
  //   }
  // );

  // const componentDidUnmount = useCallback(() => {
  //   showKeyboardSubscription.remove();
  //   hideKeyboardSubscription.remove();
  // }, [showKeyboardSubscription, hideKeyboardSubscription]);

  const { track } = useTracker();

  const checkTempContents = () => {
    if (
      JSON.stringify(wholeData) !== JSON.stringify(QNA_BLANK) ||
      fileList.length > 1 ||
      tagList.length > 0 ||
      userList.length > 0
    )
      setConfirmPopup(true);
    else goBack();
    return true;
  };

  // useBackHandler은 앱 하드웨어 뒤로가기 처리용
  // useBackHandler(checkTempContents);

  const toHeightContent = useCallback((item: QnaModifyModel<UploadFile>) => {
    let h = 160;

    if (item.contents.fileName.length > 0) {
      const f = item.contents;
      h = toHeight(f.fileResolution ?? "", h);
    }
    return h;
  }, []);

  const [contentHeight, setContentHeight] = useState(
    fileList.length == 0 ? 160 : toHeightContent(fileList[0])
  );
  const handleHeightInput = (ref: RefObject<HTMLTextAreaElement>) => {
    const element = ref.current;
    if (element) {
      element.style.height = "auto";
      element.style.height = element.scrollHeight + "px";
    }
  };

  useEffect(() => {
    if (titleInputRef.current) {
      handleHeightInput(titleInputRef);
    }
  }, [titleInputRef, wholeData.title]);

  useEffect(() => {
    const h = toHeightContent(fileList[0]);
    if (fileList.length > 0) setContentHeight(h);
  }, [fileList, fileUpdated, toHeightContent]);

  const fileRender = (item: QnaModifyModel<UploadFile>, index: number) => {
    if (index == fileList?.length - 1 && item.contents.fileSize < 0) {
      // blank
      return (
        <div className="upload_wrap">
          {/** 이미지 등록 전 **/}
          <button
            className="upload"
            onClick={() => {
              setShowTooltip(false);
              fileInputRef.current.click();
            }}
            aria-label="Select image or video"
          >
            <div className="qna_photo_wrap">
              <QnaPhoto />
            </div>
            <p className="upload_txt">{`${index}/10`}</p>
          </button>
          <input
            onChange={(e) => {
              const inputFiles = e.target.files;
              if (inputFiles && inputFiles.length > 0) {
                CommonStore.setLoading(false);
                UploadStore.checkFileExtension(inputFiles)
                  .then(() => UploadStore.checkFileSize(inputFiles))
                  .then(() => {
                    const inputFileLength = inputFiles.length;
                    if (inputFileLength > 10) {
                      throw new Error("checkFileCount", {
                        cause: { maxImageCount: 10, maxVideoCount: 10 },
                      });
                    }
                  })
                  .then(() => QnaWriteStore.dataSetting(inputFiles))
                  .then((files) => QnaWriteStore.resizeThumbImg(files))
                  .then((files) => QnaWriteStore.contentsTempDataSet(files))
                  .then(() => CommonStore.setLoading(false))
                  .catch((e: Error) => {
                    CommonStore.setLoading(false);
                    if (e.message === "checkFileExtension") {
                      CommonStore.setToastOption({
                        show: true,
                        message1: t("common.message.checkFileExtension"),
                      });
                    } else if (e.message === "checkFileSize") {
                      CommonStore.setToastOption({
                        show: true,
                        message1: t("common.message.checkFileSize"),
                      });
                    } else if (e.message === "checkFileCount") {
                      const { maxImageCount, maxVideoCount } = e.cause as {
                        maxImageCount: number;
                        maxVideoCount: number;
                      };
                      CommonStore.setToastOption({
                        show: true,
                        message1: t("common.message.checkFileCount", {
                          maxImageCount,
                          maxVideoCount,
                        }),
                      });
                    }
                  });
              }
            }}
            ref={fileInputRef}
            type="file"
            id="file"
            accept="image/*, video/*"
            style={{ display: "none" }}
            multiple
          />
          {showTooltip && (
            <div
              // style={[
              //   styles.tooltip_info_wrap,
              //   mode === FeedMode.WRITE && showTooltip
              //     ? { display: "flex" }
              //     : { display: "none" },
              // ]}
              className="tooltip_info_wrap"
              onClick={() => setShowTooltip(false)}
            >
              <div className="tooltip_bg">
                <TooltipArrow className="tooltip_arrow" />
                <p className="tooltip_bx">
                  {t("screen.qnaWrite.message.tooltip")}
                </p>
                <div className="btn_tooltip_close">
                  <IconClose />
                </div>
              </div>
            </div>
          )}
          {/** 이미지 등록 전 **/}
        </div>
      );
    } else {
      const modified = item?.modified !== undefined && item?.modified === true;
      const isVideo = item.contents.fileType === FileType.VIDEO;
      const localFilePath = `file://${item.contents.thumbnailTempPath}`;
      const remoteThumbFilePath = `${PUBLIC_BUCKET_URL}${
        item?.contents?.thumbnailFilePath || ""
      }`;
      const remoteImageFilePath = `${PUBLIC_BUCKET_URL}${
        item?.contents?.filePath || ""
      }`;
      const filePath =
        String(item.contents.thumbnailFilePath).indexOf("/THUMBNAIL/FEED/") > -1
          ? `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${
              (item.contents.thumbnailFilePath as string) || ""
            }`
          : item.contents.thumbnailTempPath;
      return (
        <>
          {/** 이미지 등록 후 **/}
          <div className="img_wrap">
            <LazyLoadImage
              className="images"
              src={filePath}
              aria-label="Selected file"
            />
            <div
              aria-label={`Delete file${index}`}
              className="delete_btn"
              onClick={() => {
                deleteFile(index);
              }}
            >
              <DeleteIcon32 />
            </div>
          </div>
          {/** 이미지 등록 후 **/}
        </>
      );
    }
  };

  useEffect(() => {
    if (toastMessage !== undefined && toastMessage.trim().length > 0) {
      CommonStore.setToastOption({
        show: true,
        message1: toastMessage,
      });
      QnaWriteStore.setToast("");
    }
  }, [toastMessage]);

  // useEffect(() => {
  //   return componentDidUnmount;
  // }, [componentDidUnmount]);

  const [curScrollVal, setCurScrollVal] = useState(0);
  const tagVwRef = useRef<HTMLDivElement>(null);

  const onIsVisibleTagList = useCallback(
    async (b: boolean) => {
      if (b) {
        const aa = tagVwRef as RefObject<any>;
        const bb = scrollRef as RefObject<any>;
        // todo : ref로 구현
        // const p1 = findNodeHandle(aa.current as unknown as number);
        // const c1 = findNodeHandle(bb.current as unknown as number);

        // if (p1 && c1) {
        //   const result = await getRelativeToParentPosition(c1, p1);
        //   if (result.y > 20) {
        //     //            console.log("onIsVisibleTagList " + result.y + ",curScrollVal:" + curScrollVal );
        //     scrollRef &&
        //       scrollRef.current &&
        //       scrollRef.current.scrollToOffset({
        //         offset: curScrollVal + result.y,
        //         animated: true,
        //       });
        //   }
        // }
      }
    },
    [scrollRef, tagVwRef, curScrollVal]
  );

  const userVwRef = useRef<HTMLDivElement>(null);

  const onIsVisibleUserList = useCallback(
    async (b: boolean) => {
      if (b) {
        const aa = userVwRef as RefObject<any>;
        const bb = scrollRef as RefObject<any>;
        // todo : ref로 구현
        // const p1 = findNodeHandle(aa.current as unknown as number);
        // const c1 = findNodeHandle(bb.current as unknown as number);

        // if (p1 && c1) {
        //   const result = await getRelativeToParentPosition(c1, p1);
        //   if (result.y > 20) {
        //     //            console.log("onIsVisibleTagList " + result.y + ",curScrollVal:" + curScrollVal );
        //     scrollRef &&
        //       scrollRef.current &&
        //       scrollRef.current.scrollToOffset({
        //         offset: curScrollVal + result.y,
        //         animated: true,
        //       });
        //   }
        // }
      }
    },
    [scrollRef, userVwRef, curScrollVal]
  );

  return (
    <>
      {/* KeyboardAvoidingView -> div로 변경함 */}
      <div
        // keyboardVerticalOffset={Platform.OS === "ios" ? 0 : statusBarHeight}
        id="qna_write"
        className="wrap"
        // behavior={Platform.OS == "ios" ? "padding" : undefined}
      >
        <TitleHeader
          title={
            mode === FeedMode.WRITE
              ? t("screen.qnaWrite.title.write")
              : t("screen.qnaWrite.title.modify")
          }
          rightItem={HeaderItem.NONE}
          showBorder={scrolling}
          onClickBack={() => checkTempContents()}
        />
        <div className="contents">
          <div aria-label="Qna write form" className="write_content_wrap">
            <>
              <MaxLengthTextInput
                ref={titleInputRef}
                aria-label="title"
                className="qna_title"
                placeholder={t("screen.qnaWrite.placeHolder.title")}
                placeholderTextColor={"#999"}
                multiline={true}
                maxLength={50}
                value={wholeData.title}
                onFocus={() => setShowTooltip(false)}
                onChangeText={(text: string) => setTitle(text)}
                type="textarea"
                rows={1}
              />
              <div className="line" />
              <MaxLengthTextInput
                aria-label="Content"
                type="textarea"
                className="qna_content"
                placeholder={t("screen.qnaWrite.placeHolder.inputQna")}
                placeholderTextColor={"#999"}
                multiline={true}
                maxLength={200}
                value={wholeData.content}
                onChangeText={(text: string) => {
                  if (text.length > 200)
                    showBottomToast(
                      t("screen.qnaWrite.message.maxContentes"),
                      bottomOffset()
                    );
                  setContent(text);
                }}
                onFocus={() => setShowTooltip(false)}
              />
              <div aria-label="File list" className="container">
                {fileList.map((item, index) => fileRender(item, index))}
              </div>
              <p className="warning_private">
                {t("screen.qnaWrite.label.notice")}
              </p>
              <div className="line_thick" />
              <div
              // todo : onLayout은 컴포넌트의 레이아웃이 변경되었을때 호출되는 함수임.. 웹에서는?
              // onLayout={(event) => {
              //   const layout = event.nativeEvent.layout;
              //   setQnaProductInputY(layout.y);
              // }}
              >
                <QnaWriteProduct
                  onProductInputFocus={() => {
                    window.scrollTo(0, qnaProductInputY);
                  }}
                  mode={mode}
                />
              </div>
              <div className="line_thick" />
              <div ref={tagVwRef}>
                <QnaWriteTag
                  mode={mode}
                  onIsVisibleTagList={(b) => void onIsVisibleTagList(b)}
                  onTagInputFocus={() => {
                    // scrollRef.current?.scrollToOffset({
                    //   offset: tagInputY,
                    //   animated: true,
                    // });
                  }}
                />
              </div>
              <div ref={userVwRef}>
                <QnaWriteUsers
                  onIsVisibleTagList={(b) => void onIsVisibleUserList(b)}
                  onTagInputFocus={() => {
                    // scrollRef.current?.scrollToOffset({
                    //   offset: qnaUserInputY,
                    //   animated: true,
                    // });
                  }}
                />
              </div>
            </>
          </div>
          <div className="spacer"></div>
          <div
            aria-label="Register button"
            className={clsx(
              isValid ? "register_button_active" : "register_button_inactive",
              "btn_register"
            )}
            onClick={() => {
              if (!UploadStore.isDefaultStatus) {
                setRegistering(true);
                return;
              }
              if (isValid) {
                setSavePopup(true);
              } else {
                showValidInfo();
              }
            }}
          >
            <p>
              {mode === FeedMode.WRITE
                ? t("screen.qnaWrite.button.register")
                : t("screen.qnaWrite.button.modify")}
            </p>
          </div>
        </div>
      </div>

      {hasError && (
        <NotificationModal
          isVisible={hasError}
          contents1={errorMessage!}
          onRequestClose={() => {
            setError("");
          }}
        />
      )}

      {showSavePopup && (
        <NotificationModal
          isVisible={showSavePopup}
          contents1={
            mode === FeedMode.WRITE
              ? t("screen.qnaWrite.message.register")
              : t("screen.qnaWrite.message.modify")
          }
          useTwoButton={true}
          isBlack={false}
          defaultButtonText={t("screen.notificationModal.button.OK")}
          extraButtonText={t("screen.notificationModal.button.cancel")}
          onClickDefaultButton={() => {
            // Keyboard.dismiss();
            setSavePopup(false);
            goTo("/main");
            QnaWriteStore.asyncCreateQna(mode);

            const fileTypes = fileList.map((f) => f.contents.fileType);

            track("upload_qna", {
              tag: tagList && tagList.map((t) => t.contents),
              image_count: fileTypes.filter((t) => t === "IMAGE").length,
              video_count: fileTypes.filter((t) => t === "VIDEO").length,
              review_product_count: wholeData.products.length,
              answerer_request_count: userList.length,
            });
          }}
          onClickExtraButton={() => setSavePopup(false)}
          onRequestClose={() => setSavePopup(false)}
        />
      )}

      {showConfirmPopup && (
        <NotificationModal
          isVisible={showConfirmPopup}
          contents1={
            mode === FeedMode.WRITE
              ? t("screen.qnaWrite.message.leave")
              : t("screen.qnaWrite.message.modifing")
          }
          useTwoButton={true}
          isBlack={false}
          defaultButtonText={t("screen.notificationModal.button.OK")}
          extraButtonText={t("screen.notificationModal.button.cancel")}
          onClickDefaultButton={() => {
            clear();
            setConfirmPopup(false);
            goBack();
          }}
          onClickExtraButton={() => setConfirmPopup(false)}
          onRequestClose={() => setConfirmPopup(false)}
        />
      )}

      {registering && (
        <NotificationModalWithHideCallback
          isVisible={registering}
          contents1={t("screen.post.message.registering")}
          useTwoButton={false}
          isBlack={false}
          defaultButtonText={t("screen.notificationModal.button.confirm")}
          onClickDefaultButton={() => setRegistering(false)}
          onClickExtraButton={() => setRegistering(false)}
          onRequestClose={() => setRegistering(false)}
          modalCloseCallback={() => setRegistering(false)}
          textAlign={"center"}
        />
      )}
    </>
  );
});

export default QnaWrite;
