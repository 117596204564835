import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CommentStore from "../../../store/CommentStore";
import { ReactComponent as HeartOffIcon } from "../../../assets/icons/heart_off.svg";
import { ReactComponent as HeartOnIcon } from "../../../assets/icons/heart_on.svg";
import { ReactComponent as ReplyIcon } from "../../../assets/icons/reply_icon.svg";
import { CommentStatusCode } from "../../../constants/Comment";
import { observer } from "mobx-react";
import {
  Arranges,
  Colors,
  FlexBoxs,
  Spacings,
  Texts,
} from "../../../assets/styles";
import "./SubCommentItem.scss";
import { getDateStr } from "../../../utils/datetime";
import CommentOptionBox from "./CommentOptionBox";
import { createTagInfoStr } from "../../../utils/comment";
import { CommentItemProps } from "./CommentItem";
import { OptionType } from "../../../components/common/ContentsPopupMenu";
import CustomParsedText from "../../../components/common/CustomParsedText";
import ReportStore from "../../../store/ReportStore";
import { AllowTypeCode } from "../../../constants/CommonCode.enum";
import { ReportTargetTypeCode } from "../../../constants/Report";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import { LikeTypeCode } from "../../../constants/Like";
import { runInAction } from "mobx";
import clsx from "clsx";
import { Styles } from "../../../assets/types/Style";
import { ProductForWrite } from "../../../store/ProductSearchAddStore";
import CommentBlock from "./CommentBlock";
import CommentItemProductList from "./CommentItemProductList";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

export type SubCommentItemProps = CommentItemProps & { isLast?: boolean };

const SubCommentItem = observer(
  ({
    index,
    comment,
    onPressAddReplyBtn,
    onAddReplyBtn,
    onModifyReplyBtn,
    onDeleteReplyBtn,
    isLast,
    showReply,
  }: SubCommentItemProps) => {
    const {
      cancelLikeComment,
      likeComment,
      setDeleteTargetCommentInfo,
      setCommentInEdit,
      setChildLike,
      syncCommentStatusAfterReporting,
    } = CommentStore;

    const { openReportBottomSheet } = ReportStore;

    const [commentStatusCode, setCommentStatusCode] =
      useState<CommentStatusCode>(
        comment.commentStatusCode as CommentStatusCode
      );

    const [reportYn, setReportYn] = useState<AllowTypeCode>(
      comment.reportYn as AllowTypeCode
    );

    const [memberReportYn, setMemberReportYn] = useState<AllowTypeCode>(
      comment.memberReportYn as AllowTypeCode
    );

    useEffect(() => {
      comment.reportYn && setReportYn(comment.reportYn as AllowTypeCode);
    }, [comment.reportYn]);

    useEffect(() => {
      comment.memberReportYn &&
        setMemberReportYn(comment.memberReportYn as AllowTypeCode);
    }, [comment.memberReportYn]);

    const { t } = useTranslation();
    const setDoILikeAsync = async (like: boolean) => {
      const toggleSucceed = like
        ? await likeComment(comment?.commentId)
        : await cancelLikeComment(comment?.commentId);
      if (toggleSucceed) {
        setChildLike(comment.parentCommentId, comment.commentId, like);
      }
    };

    const handleMenuAction = (type: OptionType) => {
      switch (type) {
        case "DELETE":
          onDeleteReplyBtn && onDeleteReplyBtn();
          setDeleteTargetCommentInfo({
            commentId: comment.commentId,
            parentCommentId: comment.parentCommentId,
          });

          break;

        case "MODIFY":
          onModifyReplyBtn && onModifyReplyBtn();

          onPressAddReplyBtn &&
            onPressAddReplyBtn({
              // contents: comment.contents || "",
              contents: `@${comment.nickname} ${
                comment.contents?.split("]")[1]
              }`,
              parentCommentId: comment.parentCommentId,
              commentId: comment.commentId,
              originalMentionList: comment.mentionList,
            });

          if (comment.productList && comment.productList.length > 0) {
            runInAction(() => {
              const productList: ProductForWrite[] = [];
              comment.productList?.forEach((x) => {
                productList.push({
                  isRepresent: false,
                  product: {
                    ...x,
                    manufacturerName: x.manufacturerNameKor,
                    originalFilePath: x.productFiles[0].filePath,
                  },
                });
              });
              CommentStore.tempProductList = productList;
            });
          }

          break;
        case "REPORT":
          openReportBottomSheet(
            {
              reportTargetId: String(comment.commentId),
              parentCommentId: comment.parentCommentId,
              reportTargetTypeCode: ReportTargetTypeCode.COMMENT,
            },
            () => {
              setMemberReportYn(AllowTypeCode.Y);
              void syncCommentStatusAfterReporting({
                reportTargetId: String(comment.commentId),
                parentCommentId: comment.parentCommentId,
                reportTargetTypeCode: ReportTargetTypeCode.COMMENT,
              });
            }
          );
          break;
      }
    };

    const onLikeListButtonPress = () => {
      goTo("/LikeScreen", {
        state: {
          feedId: comment?.commentId,
          feedType: LikeTypeCode.COMMENT,
        },
      });
    };

    const toUserHome = () => {
      goTo(`/user/${comment.nickname}`, {
        state: {
          targetUserId: comment.memberUuid,
          nickname: comment.nickname,
          profileUrl: comment.profileUrl,
          profileBackgroundColor: comment.profileBackgroundColor,
        },
      });
    };

    return (
      <>
        {(commentStatusCode === CommentStatusCode.ADMIN_DELETE ||
          reportYn === AllowTypeCode.Y ||
          memberReportYn === AllowTypeCode.Y) && (
          <CommentBlock
            statusCode={commentStatusCode}
            reportYn={reportYn}
            memberReportYn={memberReportYn}
          />
        )}

        {commentStatusCode === CommentStatusCode.REGISTERED &&
          reportYn !== AllowTypeCode.Y &&
          memberReportYn !== AllowTypeCode.Y && (
            <>
              <div
                style={styles.reply_item}
                aria-label="reply container"
                className="reply_container"
              >
                <div style={styles.header} className="header">
                  <div style={styles.left_wrap} className="left_wrap">
                    <ReplyIcon className="reply_icon" />
                    <div style={styles.left} className="left">
                      <TouchableWithAuthCheck
                        onPress={toUserHome}
                        className="button_image"
                      >
                        <div style={styles.user_image} className="user_image">
                          <FastImageWithFallback
                            aria-label="Profile Image"
                            source={{
                              uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${
                                comment.profileUrl || ""
                              }`,
                            }}
                            style={styles.image}
                            className="image"
                          />
                        </div>
                      </TouchableWithAuthCheck>
                      <div style={styles.info} className="info">
                        <TouchableWithAuthCheck onPress={toUserHome}>
                          <span
                            style={styles.id_text}
                            className="id_text"
                            aria-label="Nickname"
                          >
                            {comment.nickname}
                          </span>
                        </TouchableWithAuthCheck>
                      </div>
                    </div>
                  </div>
                  <div style={styles.right} className="right">
                    <TouchableWithAuthCheck
                      aria-label="Like icon"
                      onPress={() => {
                        void setDoILikeAsync(comment.likeYn !== "Y");
                      }}
                    >
                      {comment.likeYn === "Y" ? (
                        <HeartOnIcon />
                      ) : (
                        <HeartOffIcon />
                      )}
                    </TouchableWithAuthCheck>
                    <CommentOptionBox
                      comment={comment}
                      onAction={handleMenuAction}
                    />
                  </div>
                </div>
                <div
                  className={clsx(
                    styles.comment_body_wrap,
                    isLast && styles.last_comment_body_wrap,
                    "reply_comment_body_wrap"
                  )}
                >
                  <div style={styles.body} className="body">
                    <div style={styles.comment_wrap} className="comment_wrap">
                      {comment && (
                        <CustomParsedText
                          pressEnabled={true}
                          aria-label={`Reply comment${index}`}
                        >
                          {comment?.contents || ""}
                        </CustomParsedText>
                      )}
                    </div>
                  </div>
                  <CommentItemProductList comment={comment} />
                  <div style={styles.reply_footer} className="reply_footer">
                    <div style={styles.footer_left} className="footer_left">
                      <div style={styles.inner_left} className="inner_left">
                        <span
                          style={styles.info_text}
                          className="info_text"
                          aria-label="Datetime"
                        >
                          {getDateStr(comment?.updatedDatetime)}
                        </span>
                        <button
                          onClick={() => onLikeListButtonPress()}
                          style={styles.count_wrap}
                        >
                          <span style={styles.info_text} aria-label="Like">
                            {t("screen.comment.label.like")}
                          </span>
                          <span
                            style={styles.count_text}
                            aria-label="Like count"
                          >
                            {comment?.likeCount.toString() || "0"}
                          </span>
                        </button>
                      </div>
                    </div>
                    {showReply !== undefined && !showReply ? (
                      <></>
                    ) : (
                      <div style={styles.right}>
                        <TouchableWithAuthCheck
                          onPress={() => {
                            onAddReplyBtn && onAddReplyBtn();
                            setCommentInEdit({
                              // contents:
                              //   createTagInfoStr(
                              //     comment.nickname,
                              //     comment.memberUuid
                              //   ) + " ",
                              contents: `@${comment.nickname} `,
                              parentCommentId: comment?.parentCommentId,
                            });
                          }}
                          aria-label="Reply"
                        >
                          <span style={styles.action_text}>
                            {t("screen.comment.button.reply")}
                          </span>
                        </TouchableWithAuthCheck>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
      </>
    );
  }
);

const styles: Styles = {
  header: {
    ...FlexBoxs.horizon,
    ...Arranges.between,
  },
  count_text: {
    ...Texts.contents_text_7,
    ...Spacings.margin_left_2,
  },
  left_wrap: {
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
  },
  left: {
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
    ...Spacings.margin_left_4,
  },
  right: {
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
  },
  info: {
    ...FlexBoxs.vertical,
    ...Arranges.center_v,
  },
  image: {
    width: 34,
    height: 34,
    ...Spacings.border_radius_100,
  },
  user_image: {
    ...Spacings.margin_right_6,
  },
  id_text: {
    ...Texts.contents_text_3,
    ...Texts.font_weight_bold,
  },
  info_text_wrap: {
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
  },
  info_text: {
    ...Texts.contents_text_5,
  },
  reply_item: {
    ...Colors.background_fcfafc,
    ...Spacings.padding_left_right_20_responsive,
    ...Spacings.padding_top_24,
    borderColor: "rgba(211, 186, 214, 0.2)",
    borderTopWidth: 1,
  },
  reply_footer: {
    ...Spacings.margin_top_10,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Arranges.center_h,
  },
  body: {
    ...Spacings.margin_top_10,
    ...Spacings.margin_bottom_10,
  },
  comment_wrap: {
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  comment: {
    ...Texts.contents_text_8,
  },
  target: {
    ...Texts.contents_text_10,
  },
  reply_text: {
    ...Texts.contents_text_9,
  },
  reply_count: {
    ...Texts.contents_text_7,
  },
  action_text: {
    ...Texts.btn_text_1,
    ...Colors.font_be69c3,
  },
  circle: {
    height: 34,
    width: 34,
    borderRadius: 17,
    backgroundColor: "#ffffff",
  },
  comment_body_wrap: {
    ...Spacings.padding_left_56,
    ...FlexBoxs.vertical,
    ...Spacings.padding_bottom_24,
  },
  last_comment_body_wrap: {
    borderColor: "rgba(211, 186, 214, 0.2)",
    borderBottomWidth: 1,
  },
  count_wrap: {
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Spacings.margin_left_10,
  },
  inner_left: {
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
    ...Spacings.margin_right_12,
  },
  footer_left: {
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
  },
};

export default SubCommentItem;
